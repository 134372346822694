@import '../../platformui/Teaser/styles.scss';
@import './list-view-toggler.scss';
@import './teaser-linklist.scss';

@include Teaser($headingLinkColor: $berry,
  $headingMargin: 0 0 14px 0,
  $headingPadding: 0 0 14px 0,
  $componentBorderRadius: (25px,
    10px,
    10px,
  ),
  $componentPageListBorderBottomHover: none,
  $componentPageListBorderBottom: none,
  $componentBoxShadow: 0 0 2px 0 rgba(0, 0, 0, 0.3),
  $translationColor: $muted) {
  $eqHeight: '.c-teaser--eq-height';
  $hasImg: '.c-teaser--has-img';
  //.c-teaser
  text-decoration: none;

  &__wrapper {
    position: relative;

    #{$hasImg} & {
      &:after {
        top: 336px;
      }
    }
  }

  &-outer {
    &:last-child:not(:first-child) {
      float: left;
    }
  }

  &--eq-height {
    .c-teaser__img-container {
      &>div {
        background-color: transparent;
      }
    }

    @include focus-handler($color: $muted, $offset: 8px);

    &#{$hasImg} {
      height: auto;
    }
  }

  &__img-container {
    display: none;

    @include breakpoint(medium) {
      border-top: 5px solid $lipstick;
      display: block;

      .c-image {
        margin: 18px 18px 0;
        width: auto;
      }

      .c-teaser__img>div,
      >div {
        background-color: transparent;
      }

      +.c-teaser__content {
        border-top: none;
      }
    }

    @include breakpoint(medium) {
      display: block;
    }
  }

  &__heading {
    @include secondary-font-family();
    font-size: rem-calc(20);
    line-height: 1.25;
    letter-spacing: -0.3px;
    margin: 0;
    padding: 0;

    @include breakpoint(medium) {
      border-bottom: 1px solid rgba($lipstick, 0.4);
      margin: 0 0 14px 0;
      padding: 0 0 14px 0;
    }

    a {
      display: block;
      @include focus-handler();
    }

    &__link {
      @include icon('f105');
      text-decoration: none;


      &:before {
        color: $berry;
        position: absolute;
        top: 1px;
      }

      span {
        padding-left: rem-calc(20);
        display: inline-block;

        [dir='rtl'] & {
          padding-left: 0;
          padding-right: rem-calc(20);
        }
      }
    }
  }

  &__content {
    @include breakpoint(medium) {
      border-top: 5px solid $lipstick;
    }

    #{$hasImg} & {
      @include breakpoint(medium) {
        border-top: 0;
      }
    }

    padding: 10px 20px;

    @include breakpoint(medium) {
      padding: 20px 20px 12px;
    }

    #{$eqHeight} & {
      padding: 10px 20px;

      @include breakpoint(medium) {
        padding: 20px 20px 0;
      }
    }
  }

  &__text {
    display: none;
    color: $black;

    @include breakpoint(medium) {
      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 75px;
      }

      display: block;
    }
  }

  &__translation {
    font-family: $secondary-font;
  }
}
