@import '../../platformui/Share/styles';

@include Share($componentMargin: 45px 0 0 0,
  $iconBgColor: transparent,
  $iconBgHoverColor: $sky-dark,
  $iconSocialColor: $sky-base,
  $headingTextTransform: uppercase) {
  .c-share {
    &__heading {
      color: $muted;
      font-size: rem-calc(20);
    }

    &__list__item {

      &__share-btn,
      .c-printsidebar-toggle-button {
        padding: 0;
        @include focus-handler();
      }
    }
  }
}
