@import '../../platformui/Alert/styles';

@include Alert(
  $borderRadius: 10px,
  $borderStyle: 1px solid,
  $warningIcon: '../../framework/images/alert.svg',
  $warningBorderColor: c('grey-300'),
  $warningBackgroundColor: $off-white,
  // PlatformUI component require these to be set to existing images. If used in future, add correct images and update here.
  $checkIcon: '../../framework/images/alert.svg',
  $infoIcon: '../../framework/images/alert.svg'
) {
  .c-alert {
    align-items: unset;
    margin-top: 30px;
    padding: 20px 0;
    opacity: 1;
    transition: opacity 0.3s ease;
    @include focus-handler();

    &__icon {
      background-size: 23px;
      height: 23px;
      margin: 0;
      width: 23px;

      @include breakpoint(large) {
        margin: 0 24px;
        background-size: 50px;
        height: 50px;
        width: 50px;
      }
    }

    &__inner {
      @include breakpoint(small only) {
        display: block;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    &__icon-container {
      align-items: unset;
      left: 0;
      margin-left: 20px;
      margin-right: 12px;
      position: relative;
      top: 2px;

      @include breakpoint(small only) {
        float: left;
        margin-left: 0px;
      }

      @include breakpoint(medium) {
        top: -5px;
      }

      @include breakpoint(large) {
        align-items: center;
        left: unset;
        top: unset;

        .contact-alert & {
          position: static;
        }
      }
    }

    p {
      font-size: rem-calc(18);
      margin-right: 20px;
      padding: 0;
    }

    @include breakpoint(large) {
      align-items: center;
    }
    .contact-alert & {
      margin-bottom: 20px;
      &__icon-container {
        top: 12px;

        @include breakpoint(medium) {
          top: 5px;
        }

        @include breakpoint(large) {
          margin: 0;
        }
      }
    }

    &--small {
      p {
        font-size: rem-calc(16);
      }
    }

    .is-loading & {
      opacity: 0.5;
    }
  }
}
