@import '../../platformui/Breadcrumb/styles.scss';

@include Breadcrumb($textColor: $black, $itemPadding: 0 4px 0 0) {
  font-size: rem-calc(16);
  line-height: 1.5;

  @media print {
    display: none;
  }

  ol {
    padding-top: 6px;
  }

  a {
    @include focus-handler($muted, $offset: 2px);
  }
}
