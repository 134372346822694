@import '../../platformui/FindCare/findcare-search-list-item/findcare-search-list-item.scss';

@include FindCare-Searchlistitem(
  $className: 'findcare-search-list-item',
  $cta: $denim,
  $black: $black,
  $borderColor: $cloudy-blue,
  $linkHover: $dusk,
  $muted: $muted
) {
  .findcare-search-list-item {
    &__info a {
      display: block;
    }
  }
}
