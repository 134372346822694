@import '../../platformui/Modal/tooltip-modal.scss';
@import '../../platformui/Modal/fullscreen-modal.scss';

@include TooltipModal(
  $className: 'findcare-tooltip',
  $pseudoIcon: after,
  $buttonFloat: none,
  $containerWidth: (
    100%,
    400px,
  ),
  $modalPosition: (
    -24px,
    10px,
    176px,
  ),
  $modalPositionTop: 14px,
  $modalCloseBtnColor: $denim,
  $modalBtnColorOpen: $denim,
  $scrollbarColor: $denim,
  $conditions: $muted,
  $focusColor: $muted,
  $scrollbarBg: $pale-grey
) {
  &__modal-container {
    h1 {
      color: $black;
      font-size: rem-calc(16);
      font-weight: bold;
    }

    p {
      color: $black;
    }

    a {
      cursor: pointer;

      &:hover {
        color: $dusk;
      }
    }
  }

  &__modal-open {
    position: absolute;

    span {
      position: absolute;
      top: -3px;
    }
  }

  &__modal-panel {
    width: 100%;
    height: 250px;
  }
}

@include FullscreenModal(
  $className: 'findcare-fullscreen',
  $open: '.findcare-fullscreen__modal-open--open',
  $cta: $denim,
  $muted: $muted,
  $linkHover: $dusk,
  $white: $white,
  $black: $black
) {
  &__modal-open {
    padding: 0;
    top: -3px;
    text-decoration: underline;

    &:hover {
      color: $dusk;
    }

    color: $denim;
    @include icon('E850');

    &:before {
      font-size: 25px;
      left: -6px;
      margin-right: 0;
      position: relative;
      width: 22px;
      text-decoration: none;

      @include breakpoint(large) {
        left: 0;
        margin-right: 5px;
        top: 2px;
        width: 25px;
      }
    }
  }
}
