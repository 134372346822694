@import '../../platformui/AOListBlock/styles.scss';

@include AOList(
  $backgroundColor: (
    $white,
    $pale-grey,
  ),
  $backgroundColorActive: (
    $denim,
    $denim,
  ),
  $backgroundColorInactive: (
    $white,
    $white,
  ),
  $backgroundColorSelected: (
    $pale-grey,
    $white,
  ),
  $border: solid 1px $cloudy-blue,
  $color: $denim,
  $colorInactive: $muted,
  $colorSelected: (
    $black,
    $denim,
  ),
  $colorActive: (
    $white,
    $white,
  ),
  $fontSize: (
    rem-calc(10),
    rem-calc(20),
  ),
  $fontSizeSelected: (
    rem-calc(16),
    rem-calc(20),
  ),
  $fontWeight: (
    normal,
    normal,
  ),
  $fontWeightSelected: (
    bold,
    normal,
  ),
  $heightItem: 50px,
  $margin: (
    0,
    0 auto,
  ),
  $textAlign: left,
  $transformScale: 1.1,
  $width: (
    40px,
    calc(100% + 10px),
  ),
  $widthItem: 50px
) {
  position: absolute;
  right: 0;

  @include breakpoint(medium) {
    position: relative;
  }

  &--is-fixed {
    position: fixed;
    top: 0;

    @include breakpoint(medium) {
      position: relative;
    }
  }

  &--is-bottom {
    position: absolute;
    bottom: 0;

    @include breakpoint(medium) {
      position: relative;
    }
  }

  a {
    &:hover,
    &:focus,
    &:active {
      outline: none;
      text-decoration: none;
    }
  }
}

@include breakpoint(small only) {
  .c-anchorlinks {
    position: static;
    right: auto;
  }
}

.ao-list-track-container {
  margin-bottom: 100px;
  position: relative;
  @include breakpoint(small only) {
    // negate the column and row margin/padding.
    margin-right: -1.25rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    height: 100%;
    justify-content: space-between;
  }
}

.ao-list {
  &__container {
    bottom: 0;
    position: absolute;
    right: -20px;
    top: 0;

    @include breakpoint(small only) {
      top: unset;
      right: unset;
      bottom: 100px;
      position: sticky;
      align-self: flex-end;
    }

    @include breakpoint(medium) {
      border-bottom: solid 1px rgba($black, 0.2);
      border-top: solid 1px rgba($black, 0.2);
      margin-bottom: 10px;
      margin-left: calc(-16.66667% - 10px);
      margin-right: calc(-16.66667% - 10px);
      padding-bottom: 20px;
      padding-top: 30px;
      position: relative;
      right: 0;
    }

    @include breakpoint(large) {
      margin-left: calc(-33.33334% - 10px);
      margin-right: 0;
    }

    & > div {
      height: 100%;
      @include breakpoint(small only) {
        height: auto;
      }
    }
  }

  &__wrapper {
    @include breakpoint(medium) {
      position: relative;
    }

    &__letter {
      display: none;

      @include breakpoint(medium) {
        border: solid 2px $pale-grey;
        border-radius: 50%;
        color: $muted;
        display: block;
        height: 84px;
        font-size: rem-calc(40);
        font-weight: normal;
        margin: 0;
        padding: 14px;
        position: absolute;
        right: 0;
        text-align: center;
        text-transform: uppercase;
        top: 30px;
        width: 84px;
      }
    }

    &__list {
      border-top: solid 1px rgba($black, 0.1);
      list-style: none;
      margin: 0;
      padding: 30px 0 25px;

      @include breakpoint(medium) {
        min-height: 170px;
        padding: 30px 100px 25px 0;
        width: 100%;
      }

      li {
        margin-bottom: 15px;
      }

      a {
        @include focus-handler($color: $denim, $offset: 1px);
      }
    }

    &__back {
      bottom: 0;
      display: inline-block;
      padding: 0;
      position: absolute;
      overflow: hidden;
      right: 0;
      height: 0;
      width: 0;

      &:focus {
        border: solid 1px rgba($black, 0.1);
        border-bottom: none;
        height: auto;
        opacity: 1;
        outline: none;
        overflow: visible;
        padding: 6px;
        width: auto;

        span {
          display: inline-block;
          padding: 4px;
          outline: 1px dotted $denim;
          outline-offset: 1px;
        }
      }
    }
  }

  &__drugs {
    border-top: solid 1px rgba($black, 0.1);
    margin-left: -7px;
    margin-right: -7px;
    padding-left: 7px;
    padding-right: 7px;

    @include breakpoint(medium) {
      border-top: none;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }

    & > div {
      &:first-child {
        .ao-list__wrapper__list {
          border-top: none;
        }
      }
    }
  }
}
