@import '../../platformui/FindCare/findcare-form/styles.scss';
@import '../../platformui/FindCare/findcare-searchfield/autocomplete.scss';

.form-field {
  width: 100%;

  @include breakpoint(large) {
    width: 30%;
    margin-right: 10px;

    &.findcare-searchfield__location-wrapper {
      width: 32%;
    }
  }
}

@include FindCare-Form(
  $className: 'findcare-form',
  $borderColor: $denim,
  $buttonBg: $denim,
  $lightGrey: $warm-grey,
  $muted: $muted,
  $white: $white
) {
  @media screen and #{breakpoint(768)} and #{breakpoint(large down)} {
    .findcare-form__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;
      .form-field {
        width: unset;
      }
      .form-field.findcare-searchfield__location-wrapper {
        .findcare-searchfield {
          width: 300px;
          margin-right: 20px;
        }
      }
      .form-field.findcare-searchfield__caretype-wrapper {
        .findcare-searchfield {
          width: 300px;
        }
      }

      .form-field.findcare-searchfield__unit-wrapper {
        .findcare-searchfield {
          width: 512px;
          margin-right: 20px;
        }
      }
    }
  }

  .findcare-form__button {
    margin-top: 20px;

    @include breakpoint(medium only) {
      margin-top: 20px;
      width: 100%;
    }
    @include focus-handler();
  }
}

.findcare-searchfield {
  position: relative;

  &__unit-wrapper {
    .findcare-searchfield__clear-field {
      margin-right: 15px;
    }
  }
  &__toggle-suggestion,
  &__clear-field {
    height: 50px;
    top: 28px;
    bottom: 0;
    right: 0;
    position: absolute;
    transition: opacity 0.1s ease-in-out;
    @include breakpoint(medium) {
      top: auto;
      height: 55px;
    }
  }
  &__clear-field {
    top: 46px;
    @include breakpoint(medium) {
      top: 51px;
    }
  }

  &--is-loading &__toggle-suggestion,
  &--is-loading &__clear-field {
    opacity: 0;
  }

  .c-spinner {
    display: none;
    left: auto;
    opacity: 0;
    right: -1000px;
  }

  &--is-loading .c-spinner {
    display: block;
    opacity: 1;
    top: 47px;
    left: auto;
    right: 0;
    -webkit-transform: none;
    transform: none;
    width: 50px;

    span {
      background-color: $denim;
      width: 10px;
      height: 10px;
    }
  }
}

.findcare-autocomplete__sublabel {
  color: $muted;
  margin-top: 4px;
  display: block;
  font-size: rem-calc(14);
  font-style: italic;
}

.findcare-searchfield__autocomplete {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  background-color: $white;
  min-width: 100%;

  @include breakpoint(medium) {
    min-width: 300px;
  }

  &--is-open {
    height: auto;
    opacity: 1;
  }
}

.findcare-searchfield {
  input.region + .findcare-searchfield__autocomplete {
    background-image: url('../../framework/images/powered-by-google.png');
    background-repeat: no-repeat;
    background-position: left 20px bottom 15px;
    background-size: 144px 18px;

    ul {
      margin-bottom: 35px;
    }
  }

  &__clear-field {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='9' cy='9' r='9' fill='%23808285'/%3E%3Cg fill='%23FFF'%3E%3Cpath d='M.45 4.2h8.1c.249 0 .45.201.45.45s-.201.45-.45.45H.45C.201 5.1 0 4.899 0 4.65s.201-.45.45-.45z' transform='rotate(45 1.68 12.182)'/%3E%3Cpath d='M4.95.6v8.1c0 .249-.201.45-.45.45s-.45-.201-.45-.45V.6c0-.249.201-.45.45-.45s.45.201.45.45z' transform='rotate(45 1.68 12.182)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-color: transparent;
    height: 18px;
    width: 18px;
  }

  &__clear-field--toggle-sibling {
    right: 52px;
  }

  @include Chevron($className: 'findcare-searchfield__toggle-suggestion') {
    position: absolute;
    background-color: transparent;
    height: 48px;
    width: 46px;

    &:before {
      background-color: $denim;
    }
    &:after {
      background-color: $denim;
    }

    @include breakpoint(medium) {
      height: 58px;
    }
  }

  input.nearby.region {
    padding-left: 25px;
    margin-left: 12px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='26' height='24' viewBox='0 0 26 24'%3e%3cpath fill='%23396291' d='M19.337 7.942l-8.118 11.705c-.308.443-.916.553-1.359.245-.324-.224-.48-.622-.397-1.007l.76-3.524c.117-.54-.227-1.072-.766-1.188-.082-.018-.166-.025-.25-.022l-3.601.137c-.539.02-.992-.4-1.013-.938-.015-.394.209-.758.567-.924l12.936-5.962c.502-.231 1.096-.012 1.327.49.148.321.116.697-.086.988z'/%3e%3c/svg%3e ");
    background-repeat: no-repeat;
    background-position: center left;
  }
  input::-ms-clear {
    display: none;
  }
}
