.product-list-container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &--heading {
    color: c('main');
    font-size: rem-calc(24);
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: 1px solid c('stone-clear');
    margin: 0;
  }

  &__top-content {
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    flex-flow: row wrap;
    gap: 20px;

    .filter-info {
      max-width: 720px;
      width: 100%;
      margin-bottom: 0;
    }

    .sort-options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;

      &__sort-text {
        font-weight: bold;
        margin: 0;
      }

      &__sort-button {
        font-size: rem-calc(14);
        color: c('sky-base');
        border: none;
        background-color: transparent;
        cursor: pointer;
        text-decoration: underline;
        display: flex;
        gap: 5px;
        @include focus-handler();
        padding-inline: 0;
        padding-bottom: 10px;

        &:hover {
          color: c('sky-dark');
        }

        &:after {
          content: "";
          background-image: url('../../framework/images/sort-icon.svg');
          width: 17px;
          height: 17px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

      }

      .ascending::after {
        transform: rotate(180deg);
      }
    }
  }

  &__product-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    .product {
      padding-block: 8px;
      border-top: 1px solid c('stone-line');
      width: 100%;
      min-height: 150px;
      display: flex;
      @include focus-handler();
      text-decoration: none;
      align-items: center;

      @include breakpoint(large) {
        align-items: start;
      }

      &:last-child {
        border-bottom: 1px solid c('stone-line');
        margin-bottom: 40px;
      }

      &:hover {
        cursor: pointer;

        .product__product-info {
          .product__product-info__product-name {
            color: c('sky-dark');
            text-decoration: underline;
          }
        }
      }

      &__fallback-image {
        max-width: 100%;
        height: 5.25rem;
        width: 5.25rem;
        background-color: #f9f9f9;
        background-image: url("./fallback-img-small.svg");
        background-position: center;
        background-repeat: no-repeat;
        margin: 0;

        @include breakpoint(large) {
          height: 9.375rem;
          width: 9.375rem;
          background-image: url("./fallback-img.svg");
        }
      }

      &__product-image {
        height: 5.25rem;
        width: 100%;
        max-width: 5.25rem;
        overflow: visible;
        color: c('grey-500');

        @include breakpoint(large) {
          max-width: 9.375rem;
          height: auto;
        }

        img {
          object-fit: scale-down;
          height: 5.25rem;
          width: 5.25rem;

          @include breakpoint(large) {
            height: 9.375rem;
            width: 9.375rem;
          }
        }
      }

      &__product-info {
        width: 100%;
        max-width: 720px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-inline: rem-calc(14);
        padding-block: rem-calc(20);
        height: 100%;

        &__product-name {
          margin: 0;
          font-size: rem-calc(16);
          font-weight: bold;
          color: c('sky-base');
          text-decoration: none;
          cursor: pointer;
          @include secondary-font-family();
          @include focus-handler();
          flex-shrink: 1;

          @include breakpoint(large) {
            font-size: rem-calc(20);
          }
        }

        &__product-text {
          margin: 0;
          font-size: rem-calc(16);
          color: c('stone-dark');
          flex-grow: 1;

          @include breakpoint(large) {
            font-size: rem-calc(16);
            height: 100%;
          }

          @include breakpoint(small) {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 3em;
          }

        }
      }
    }
  }

  &__show-more {
    padding: 14px 30px;
    font-size: rem-calc(18);
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: c('sky-base');
    background-color: c('white');
    border: 1px solid c('sky-base');
    border-radius: 30px;
    cursor: pointer;
    align-self: center;
    width: 100%;
    @include focus-handler();

    @include breakpoint(large) {
      width: fit-content;
      font-size: rem-calc(16);
    }

    &:hover {
      background-color: c('sky-dark');
      color: c('white');
    }
  }
}
