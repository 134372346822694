@import '../../../platformui/Forms/label.scss';

.pregnancy-form {
  border-radius: 10px;
  display: block;
  margin-bottom: 45px;

  @include breakpoint(medium) {
    box-shadow: 0 0 6px 0 rgba($black, 0.3);
    display: inline-block;
    width: 100%;
  }

  &__top-border {
    border-radius: 10px 10px 0 0;
    border-top: 5px solid $denim;
    display: none;
    overflow: hidden;

    @include breakpoint(medium) {
      display: block;
    }
  }

  &__header {
    margin-bottom: 20px;
    position: relative;

    @include breakpoint(medium) {
      padding: 30px 30px 0;
    }

    &__container {
      align-items: center;
      border-bottom: 1px solid rgba($black, 0.3);
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
    }

    &__heading {
      color: $muted;
      font-weight: 400;
      margin: 0;
      text-transform: uppercase;
    }
  }

  &__form {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 30px 20px;

      &--week-is-shown {
        padding-bottom: 0;
      }
    }

    @include breakpoint(large) {
      align-items: center;
      justify-content: flex-start;

      & > * {
        margin-bottom: 20px;
      }
    }

    fieldset {
      display: block;
      padding: 0;
      margin: 0;
    }
    @include breakpoint(medium) {
      flex-direction: row;
      fieldset {
        display: flex;
        padding: 0;
        margin: 0;
      }
    }
  }

  &__radios-list {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding: 0;
    @include breakpoint(medium) {
      flex-direction: row;
    }

    &__item {
      list-style: none;
      position: relative;

      @include breakpoint(medium) {
        margin-right: 40px;
      }
    }
  }

  &__radio {
    opacity: 0;
    position: absolute;
    //visibility: hidden;

    &:checked + .pregnancy-form__label {
      &:before {
        background: $denim;
        border: 3px solid $pale-grey;
        box-shadow: 0 0 0 1px $denim;
      }
    }

    &:focus + .pregnancy-form__label {
      outline: 2px solid $black;
      outline-offset: 4px;
    }
  }

  &__label {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: rem-calc(16);
    padding: 8px 0;
    transition: all 0.15s linear;

    &:before {
      background-color: $pale-grey;
      border: 1px solid $denim;
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: 30px;
      margin-right: 10px;
      transition: all 0.25s linear;
      width: 30px;
    }
  }

  &__button {
    color: $white;
    cursor: pointer;
    background-color: $denim;
    border-radius: 30px;
    font-size: rem-calc(20);
    font-weight: 700;
    height: 50px;
    letter-spacing: -0.4px;
    margin: 0 0 0 0;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    @include secondary-font-family();
    @include focus-handler();

    @include breakpoint(medium) {
      align-self: center;
      margin: 0;
      width: 50%;
    }

    @include breakpoint(large) {
      align-self: unset;
      margin: 0 0 25px 0;
      width: 270px;
    }
  }
}
