@import '../../platformui/Forms/styles';

@include Forms();

@include Label($margin: 0 0 10px 0, $disabled: $black);

@include Checkbox();

@include Input(
  $bg: $pale-grey,
  $color: $black,
  $border: solid 1px $denim,
  $borderRadius: 10px,
  $focusBorder: solid 1px $denim,
  $focusShadow: inset 2px 6px 6px 0 rgba($muted, 0.2),
  $disabledBg: $white,
  $disabledBorder: solid 1px $warm-grey
) {
  input {
    font-size: rem-calc(16);

    &::-ms-clear {
      display: none;
    }

    @media print {
      border-color: black;
    }
  }
}

// *** Textarea
@include Textarea(
  $bg: $pale-grey,
  $border: 1px solid $denim,
  $borderRadius: 10px,
  $color: $black,
  $disabledBg: $white,
  $disabledBorder: solid 1px $warm-grey,
  $focusBorder: 1px solid $denim,
  $focusShadow: inset 0 5px 10px 0 rgba($muted, 0.2),
  $scrollBarBg: $denim,
  $scrollBarWidth: 32px,
  $scrollBarShadowWidth: 6px,
  $padding: 12px 5px 12px 20px
) {
  textarea {
    font-family: $base-font;
    font-size: rem-calc(16);

    &::placeholder {
      @include base-font-family();
    }
    @media print {
      width: 95%;
      border-color: black;
    }
  }
}
// *** Select
@include Select(
  $bg: $pale-grey,
  $border: 1px solid $denim,
  $borderRadius: 10px,
  $color: $black,
  $disabledBg: $white,
  $disabledBorder: solid 1px $warm-grey,
  $fadeGradient: none,
  $fadeGradientError: none,
  $focusBorder: solid 1px $denim,
  $focusShadow: inset 2px 6px 6px 0 rgba($muted, 0.15),
  $optionHoverBg: $denim,
  $optionHoverBgDisabled: #def0f7,
  $scrollBarBg: $denim
) {
  select {
    cursor: pointer;
    font-size: rem-calc(16);
    font-weight: bold;

    @media print {
      border-color: black;
    }
  }
  &:before {
    color: $denim;
    font-weight: bold;
  }
}

@include Error(
  $color: $lipstick,
  $errorBg: $faded-pink,
  $errorBorder: dashed 1px $lipstick,
  $errorFocusBorder: dashed 1px $lipstick,
  $errorFocusShadow: inset 2px 6px 6px 0 rgba($lipstick, 0.2)
);

@import './episerver-forms';

@media print {
  .Form__Element input {
    width: 95%;

    border-color: black;
  }
}
