@import '../../platformui/InfoBox/styles.scss';

@include InfoBox($className: 'info-box') {
  margin-bottom: 60px;

  @include breakpoint(medium) {
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    padding: 20px 40px 30px;
  }

  &__heading {
    margin-bottom: 40px;
    position: relative;
    h2 {
      color: $muted;
      font-size: rem-calc(14);
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 1.4px;
      text-transform: uppercase;
    }

    &:after {
      background: rgba($muted, 0.4);
      bottom: -19px;
      content: '';
      height: 1px;
      position: absolute;
      width: 87px;
    }
  }
}
