.pregnancy-form__date-picker {
  margin: 9px 0 0 0;
  width: 100%;

  @include breakpoint(large) {
    margin: 0 20px 0 7px;
    width: 270px;
  }

  &__container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;

    @include breakpoint(medium) {
      margin-right: 10px;
      width: 50%;
    }

    @include breakpoint(large) {
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
      width: 400px;
    }

    span {
      line-height: 20px;
    }
  }

  .react-date-picker {
    margin-right: 0;

    &__wrapper {
      background-color: $pale-grey;
      border: 1px solid $denim;
      border-radius: 10px;
      height: 50px;
      margin-bottom: 5px;
      padding: 0 15px 0 20px;
      width: 100%;

      @include breakpoint(large) {
        width: 270px;
      }

      input {
        @include focus-handler();
      }
    }

    &__calendar {
      background-color: $white;
      box-shadow: 2px 2px 10px 0px rgba($black, 0.3);
      border-radius: 10px;
      height: 300px;
      margin-left: -10px;
      padding-top: 10px;
      width: 100%;
      z-index: 22;
      width: calc(100% + 20px);

      //This is to override the inline styling of the datepicker element.
      top: 60px !important;

      &:before {
        content: '';
        filter: drop-shadow(4px -2px 2px rgba($black, 0.1));
        position: absolute;
        top: -23px;
        right: 27px;
        z-index: 15;
        margin-left: -5px;
        border-width: 12px;
        border-style: solid;
        border-color: transparent transparent #fff transparent;

        @include breakpoint(medium) {
          right: 17px;
        }
      }

      @include breakpoint(medium) {
        margin-left: 0;
        width: 100%;
      }

      .react-calendar {
        background-color: $white;

        button.react-calendar__tile--active {
          background-color: $denim;
          color: white;
          @include focus-handler($offset: 2px);
        }
        button {
          background-color: $white;
          cursor: pointer;
          @include focus-handler($offset: -2px);
          &:disabled {
            cursor: unset;
            background-color: unset;
          }
        }

        &__tile {
          margin: 0;
        }

        &__navigation {
          padding: 0 10px 20px;

          &__arrow {
            color: transparent;
            padding: 0;
            position: relative;
            width: 20px;
          }

          &__prev-button {
            cursor: pointer;
            margin-left: 15px;
            @include nav-arrows($rotation: 180deg);
          }

          &__next-button {
            cursor: pointer;
            margin-right: 15px;
            @include nav-arrows();
          }
          &__prev2-button {
            display: none;
            @include nav-arrows($rotation: 180deg, $double: true);
          }

          &__next2-button {
            display: none;
            @include nav-arrows($double: true);
          }

          &__label {
            color: $muted;
            font-size: rem-calc(14);
            line-height: 1.43;
            padding: 0 10px;
            text-transform: uppercase;
          }
        }

        &__month-view__weekdays {
          color: $warm-grey;
          font-size: rem-calc(14);
          line-height: 1.43;
          padding: 0 10px 5px;
          text-transform: uppercase;

          &__weekday {
            text-align: center;
          }
        }

        &__month-view__days {
          height: 210px;
          padding: 0 10px;
          button {
            color: $denim;
            font-size: rem-calc(14);
            line-height: 1.43;
            cursor: pointer;
            &:hover {
              background-color: $denim;
              color: white;
            }
            &:disabled {
              &:hover {
                color: $pale-grey;
              }
              color: $pale-grey;
            }
          }
        }

        &__century-view,
        &__year-view,
        &__decade-view {
          &__months,
          &__years,
          &__decades {
            display: flex;
            flex-wrap: wrap;

            &__month,
            &__year,
            &__decade {
              padding: 1.5em 0.5em;
              &:hover {
                background-color: $denim;
                color: white;
              }
            }
            &__decade {
              padding: 2em 0.2em;
            }
          }
        }
      }
    }

    &__calendar-button {
      height: 25px;
      position: relative;
      top: 12px;
      width: 28px;
      @include focus-handler();

      &:before {
        content: '';
        background-image: url('../../../framework/images/calendar-icon.svg');
        height: 25px;
        position: absolute;
        right: 0;
        top: 0;
        width: 28px;
      }

      svg {
        display: none;
      }
    }
  }
}
