@mixin Typeahead(
  $focusColor: #000,
  $containerMaxWidth: rem-calc(760),
  $inputMaxWidth: rem-calc(760),
  $labelFontFamily: inherit,
  $categoryFontFamily: inherit,
  $categoryItemFontSize: rem-calc(14),
  $categoryUnderlineColor: black,
  $itemFontFamily: inherit,
  $itemFontSize: rem-calc(16),
  $itemHoverColor: pink,
  $dropdownBgColor: white,
  $dropdownBorderColor: orange,
  $dropdownBorderRadious: rem-calc(10),
  $dropdownMaxHeight: rem-calc(200),
  $dropdownTextColor: black,
  $inputOutlineColor: hotpink,
  $inputBgColor: white,
  $inputTextColor: #353535,
  $scrollbarColor: black,
  $scrollbarBg: #ccc,
  $chevronColor: black,
  $tooltipCloseButtonClr: black,
  $tooltipOpenButtonClr: black,
  $tooltipPanelWidth: rem-calc(400),
  $tooltipLinkColor: black,
  $tooltipLinkFontFamily: inherit,
  $tooltipHeadingFontFamily: inherit,
  $tooltipHeadingSize: rem-calc(16)
) {
  .c-typeahead {
    position: relative;
    max-width: $containerMaxWidth;

    &__input-container {
      position: relative;
      max-width: $inputMaxWidth;

      input {
        @include focus-handler($focusColor);
        width: 100%;
        height: rem-calc(50);
        padding-block: rem-calc(8);
        padding-inline: rem-calc(20);
        box-sizing: border-box;
        border: 1px solid $inputOutlineColor;
        background-color: $inputBgColor;
        border-radius: rem-calc(10);
        color: $inputTextColor;
        font-size: rem-calc(16);
        font-style: normal;
        font-weight: 400;
        text-overflow: ellipsis;

        &::placeholder {
          max-width: 85%;
          color: $inputTextColor;
          opacity: 1;
        }

        &:placeholder-shown {
          text-overflow: ellipsis;
        }

        &:focus {
          box-shadow: inset 0px 5px 10px rgba(99, 100, 102, 0.2);
        }

        @include breakpoint(small only) {
          padding-right: rem-calc(50);
        }
      }
    }

    &__input-toggle {
      position: absolute;
      right: 0;
      top: 0;
      height: rem-calc(50);
      width: rem-calc(75);
      z-index: 4;
      cursor: pointer;
    }

    &__label {
      font-family: $labelFontFamily;
      display: flex;
      align-items: center;
      position: relative;

      margin-bottom: rem-calc(5);
    }

    &__dropdown {
      position: absolute;
      z-index: 5;
      font-weight: 400;
      top: 100%;
      left: 0;
      right: 0;
      color: $dropdownTextColor;
      padding-right: rem-calc(4);
      background: $dropdownBgColor;
      border: 1px solid $dropdownBorderColor;
      border-radius: $dropdownBorderRadious;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);

      @include breakpoint(large) {
        padding-right: rem-calc(25);
      }

      &__content {
        width: 100%;
        max-height: $dropdownMaxHeight;
        overflow-y: auto;
        padding-block: rem-calc(10);
        list-style: none;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: rem-calc(20);
          height: rem-calc(14);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: rem-calc(12);
          border: rem-calc(3) solid transparent;
          background: $scrollbarColor;
          background-clip: content-box;
        }

        &::-webkit-scrollbar-track {
          background: $scrollbarBg;
          border-radius: rem-calc(10);
          margin-block: rem-calc(12);

          @include breakpoint(large) {
            margin-block: rem-calc(33);
          }
        }
      }
    }

    &__category {
      &__label {
        padding-block: rem-calc(15);
        padding-inline: rem-calc(20);
        display: list-item;
        text-transform: uppercase;
        font-family: $categoryFontFamily;
        font-size: $categoryItemFontSize;
        position: relative;

        &:after {
          bottom: 0;
          left: rem-calc(15);
          position: absolute;
          content: '';
          height: 1px;
          width: rem-calc(178);
          background: $categoryUnderlineColor;
        }
      }
    }

    &__list {
      padding: 0;
      margin: 0;
    }

    &__item {
      display: flex;
      padding-block: rem-calc(10);
      padding-inline: rem-calc(20);
      list-style: none;
      font-family: $itemFontFamily;
      font-size: $itemFontSize;
      cursor: pointer;

      &--highlighted {
        background-color: $itemHoverColor;
      }

      &:hover {
        background-color: $itemHoverColor;
      }
    }

    @include Chevron(
      $className: 'c-typeahead__chevron',
      $height: rem-calc(10),
      $width: rem-calc(10),
      $lineLength: 11px
    ) {
      position: absolute;
      top: 0;
      right: rem-calc(10);
      transform: translateY(-25%);
      background-color: transparent;

      &:before,
      &:after {
        background-color: $chevronColor;
      }
    }

    @include TooltipModal(
      $className: 'c-typeahead__tooltip',
      $buttonFloat: none,
      $useFadeOut: false,
      $modalPositionTop: rem-calc(42),
      $modalPanelHeight: rem-calc(250),
      $modalCloseBtnColor: $tooltipCloseButtonClr,
      $modalBtnColorOpen: $tooltipOpenButtonClr,
      $focusColor: $focusColor,
      $scrollbarColor: $scrollbarColor,
      $scrollbarBg: $scrollbarBg,
      $ctaColor: $ctaColor,
      $modalPanelLMaxWidth--large: $tooltipPanelWidth
    ) {
      &__modal-open {
        position: relative;
        display: flex;
        align-items: center;
        max-width: $tooltipPanelWidth;

        &__link {
          display: none;
          font-family: $tooltipLinkFontFamily;
          color: $tooltipLinkColor;
          text-decoration: underline;
          margin-bottom: 0;
          font-size: rem-calc(16);

          @include breakpoint(medium) {
            display: block;
          }
        }
        &--open {
          &::after {
            left: rem-calc(12);
            right: auto;
          }
        }
      }

      &__modal-panel {
        max-width: $tooltipPanelWidth;
        left: 50%;
        transform: translateX(-50%);

        &--right-aligned {
          left: 75%;
        }

        &__description {
          padding-bottom: 0;
        }

        &__content {
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-family: $tooltipHeadingFontFamily;
            font-size: $tooltipHeadingSize;
          }
        }
      }
    }
    @content;
  }
}
