.regional-articlefooter {
  border-bottom: 1px solid rgba($warm-grey, 0.7);
  margin-bottom: 15px;
  &__heading {
    padding: 0 0 14px 0;
    margin: 0;
    font-size: rem-calc(16);
    font-weight: normal;
    line-height: 1.5;
    @include base-font-family();
  }
}
