@import '../../platformui/MediaGallery/styles';

@include MediaGallery($iconFontSize: rem-calc(16),
  $iconRightPosition: 19px,
  $iconMarginTop: -25px,
  $paginationDotBgActive: $denim,
  $paginationDotBg: #e5e5e5,
  $headingFontColor: $black,
  $headingColorStandard: $lipstick,
  $headingColorArticlePage: $lipstick,
  $headingFontFamily: $secondary-font,
  $captionUnderlineColor: $black,
  $paginationNextPrevBgColor: transparent,
  $paginationNextPrevSvgColor: $denim,
  $standardHeadingAlignment: left,
  $mainLinkInlinePadding: rem-calc(102),
  $articlePagePrevButtonIconBottomPositioning: 211px,
  $standardHeadingInlinePadding: rem-calc(80),
  $focusColor: $muted,
  $idsThemeVariant: '1177'

) {
  .c-linklist__link {
    color: $denim;
    text-decoration: none;

    &:after {
      padding-left: rem-calc(15);
      font-family: 'fontello';
      content: '\e809';
    }

    &[href*='.1177.se'],
    &[href*='.1177.local'],
    &[href*='localhost'],
    &[href^='/'],
    &[href^='/link/'],
    &[href^='#'] {
      &:after {
        display: none;
        content: '';
      }
    }
  }

  .c-media-gallery__link {
    @include LinklistLink();
  }

  .c-media-gallery__nextprev {
    background-color: transparent;
  }

  .c-video {
    margin-bottom: 0 !important;
  }
}
