@import '../../platformui/ImageBlock/styles';

@include ImageBlock(
  $textBg: $lipstick,
  $hoverBg: rgba($black, 0.6),
  $marginBottom: (
    40px,
    40px,
    50px,
  ),
  $zoomBg: rgba($black, 0.3),
  $zoomRight: 10px,
  $zoomCloseBg: rgba($black, 0.3),
  $zoomCloseColor: $white,
  $zoomCloseFontSize: 23px,
  $printMargin: 0
) {
  .c-imageblock {
    @media print {
      margin: 0 !important;
      break-inside: avoid;
    }
    &__zoom {
      &__trigger {
        &:before {
          right: 16px;
          top: 17px;

          [dir='rtl'] & {
            left: 14px;
            transform: scaleX(-1);
            right: auto;
          }
        }

        &:after {
          width: rem-calc(30);
          height: rem-calc(30);

          [dir='rtl'] & {
            /*TODO kolla om det  här kan lösas i plattformen */
            left: 10px;
            right: auto;
          }
        }

        &:focus {
          outline: none;

          &:after {
            outline: 2px solid $muted;
            outline-offset: 2px;
          }
        }
      }

      &__image__close {
        @include focus-handler($color: $muted, $offset: 2px);

        [dir='rtl'] & {
          /*TODO kolla om det  här kan lösas i plattformen */
          left: 10px;
          right: auto;
        }
      }
    }

    &__image {
      .c-imageblock--is-gallery & {
        @include breakpoint(medium) {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      @media print {
        img {
          width: 100% !important;
          max-height: unset !important;
        }
      }
    }

    &__text {
      font-size: rem-calc(14);
      color: $muted;
      margin: 8px 0;
      line-height: 1.43;

      @media print {
        margin-bottom: 30px !important;
        color: #353535 !important;
        &:after {
          background-color: $black;
          bottom: -10px;
        }
      }
    }

    &--main-image {
      .c-imageblock__text {
        margin: 8px 0 36px;
      }
    }

    &--large {
      .c-chapter & {
        margin-bottom: 40px;
      }
    }
  }
}
