@import '../../../platformui/Modal/tooltip-modal.scss';

@include TooltipModal(
  $className: 'pregnancy-form',
  $pseudoIcon: before,
  $buttonFloat: right,
  $containerWidth: (
    calc(100% - 20px),
    calc(50% - 20px),
  ),
  $modalPosition: (
    15px,
    0,
    0,
  ),
  $modalPositionTop: 42px,
  $modalCloseBtnColor: $denim,
  $modalBtnColorOpen: $denim,
  $scrollbarColor: $denim,
  $conditions: $muted,
  $focusColor: $muted,
  $scrollbarBg: $pale-grey
) {
  &__modal-open {
    height: 40px;
    position: relative;
    width: 210px;
    @include focus-handler();
    span {
      &:before {
        font-size: rem-calc(30);
        left: calc(100% - 30px);
        position: absolute;
        top: 3px;
      }

      &:first-child {
        display: none;
        color: $denim;
        font-size: rem-calc(16);
        line-height: 1.5;
        margin-right: 25px;
        text-decoration: underline;

        &:hover {
          color: $dusk;
        }

        &:before {
          content: none;
        }

        @include breakpoint(medium) {
          display: inline;
        }
      }
    }

    &--open {
      &:after {
        right: 2px;
        top: 21px;
      }
    }
  }

  &__modal-panel {
    height: 270px;
    max-width: 300px;
    right: -5px;
    top: 45px;

    @include breakpoint(medium) {
      right: 10px;
      top: 75px;
    }

    &__content {
      h4 {
        border-bottom: 1px solid rgba($black, 0.2);
        color: $muted;
        font-size: rem-calc(14);
        font-weight: 400;
        padding-bottom: 9px;
        text-transform: uppercase;
      }
    }

    &__description {
      p {
        color: $muted;
        font-size: rem-calc(14);
        line-height: 1.43;
      }

      a {
        color: $denim;
        font-size: rem-calc(14);
        padding-left: 22px;
        position: relative;
        text-decoration: none;
        @include icon('e820');

        &:before {
          font-size: rem-calc(9);
          left: 0;
          position: absolute;
          top: 5px;
        }

        span {
          text-decoration: underline;

          &:hover {
            color: $dusk;
          }
        }
      }
    }
  }
}
