@import '../../platformui/Section/styles';

@include Section(
  $alternatingBgColor: $white,
  $headingMaxWidth: 100%,
  $sectionPadding: (
    30px 0 20px,
    30px 0 20px,
    50px 0 30px,
  ),
  $sectionIntroPadding: (
    25px 0 0,
    20px 0,
  )
) {
  @media print {
    display: block;
    margin-top: 30px;

    .columns {
      padding: 0;
    }
  }

  &--intro {
    .c-section__inner {
      border-top: none;
    }
  }

  &__inner {
    border-top: solid 1px rgba($warm-grey, 0.7);
  }

  &__heading {
    @include secondary-font-family();
    color: $muted;
    font-size: rem-calc(16);
    font-weight: normal;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(medium) {
      font-size: rem-calc(20);
      line-height: 1.2;
    }

    span {
      border-bottom: solid 1px rgba($warm-grey, 0.7);
      display: inline-block;

      &[data-epi-property-name='Heading'] {
        border: none;
        padding: 0;
      }

      @include breakpoint(medium) {
        padding-bottom: 10px;
      }
    }

    .c-section--navigation & {
      text-align: left;
      span {
        border-bottom: none;
      }
    }
  }
}
.subject-intro + .c-section {
  .c-section__inner {
    border-top: none;
  }
}
