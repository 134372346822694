@import '../../platformui/Mobilenav/styles';
@import '../../platformui/Framework/platform-mixins/Chevron.scss';

@include MobileNavWrapper($bgColor: $lipstick); // main list background color

@include MobileNav($isSelectedBorderLeft: $lipstick, $fontSize: rem-calc(16)) {
  font-weight: 700;
  @include secondary-font-family();
  @include Chevron($className: chevron--mobile, $chevronColor: $white) {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    @include focus-handler($color: $white, $offset: -8px);
  }

  .c-mobilenav__list__item__container {
    a {
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .c-mobilenav__list__item {
    box-shadow: inset 0 2px 4px 0 rgba($black, 0.2);

    a {
      @include focus-handler($color: $white, $offset: -2px);
    }
  }
}

@include MobileNavSubLevel(
  $listItemBorderLeftColor: $light-pink,
  $hasBorder: false,
  $isOpenBg: $faded-pink,
  $isOpenBorderLeft: $faded-pink,
  $borderOnHoverColor: $lipstick,
  $textColor: $berry,
  $fontSize: rem-calc(14),
  $sublevelBoxShadow: inset 0 2px 0px 0 rgba($black, 0.2)
) {
  font-weight: normal;

  .c-mobilenav__list__item {
    box-shadow: inset 0 2px 4px 0 rgba($black, 0.2);

    a {
      @include focus-handler($offset: -2px);
    }
  }

  @include Chevron($className: chevron--mobile, $chevronColor: $berry) {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    @include focus-handler($offset: -8px);
  }
  @include SubLevelIndent($textIndent: 30px) {
    // sublevel 2

    @include SubLevelIndent($textIndent: 60px) {
      // sublevel 3
      // prettier-ignore
      @include SubLevelIndent($textIndent: 80px) // sublevel 4
    }
  }
}
