@import '../Framework/platform-mixins/Chevron.scss';

@include Chevron(
  $className: chevron--contact-panel,
  $chevronColor: $denim,
  $lineWidth: 3px,
  $lineLength: 14px,
  $borderRadius: 0 0 20px 20px,
  $leftLinePosition: calc(100% - 29px),
  $top: 18px,
  $height: 50px
) {
  left: 14px;
  @media print {
    opacity: 0;
  }
}
