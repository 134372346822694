@import '../../platformui/Buttons/styles.scss';
@import '../../platformui/CollapsibleText/styles.scss';
@import './components/styles.scss';
@import './contact-card-panel/styles.scss';
@import './contact-alert/styles.scss';
@import './contact-header/styles.scss';
@import './contact-subheading/styles.scss';
@import './e-services/styles.scss';
@import './find-us/styles.scss';
@import './offering/styles.scss';
@import './read-more/styles.scss';
@import './related-units/styles.scss';
@import './reviews/styles.scss';
@import './current-info/styles.scss';
@import './unit/styles.scss';
@import './your-visit/styles.scss';
@import './waiting-times/styles.scss';
@import './phone/styles.scss';
@import './business-hours/styles.scss';
@import './extended-info/styles.scss';
@import './info-visitor/styles.scss';
@import './practical-information/styles.scss';
@import './unit-quality-indicators/styles.scss';
@import './listing-information/styles.scss';

.contact-card__content__inner .find-us .c-expandable-button,
.contact-card__content__inner .your-visit .c-expandable-button {
  display: block;
  width: 100%;
  text-align: left;
  min-height: 50px;

  @include breakpoint(medium) {
    border-top: 1px solid c('stone-line');
    min-height: 70px;
    font-size: rem-calc(20);
  }

  // Button Text
  > span {
    text-decoration: none;
    font-size: inherit;
    margin-left: 0;
    font-weight: 700;
    line-height: 30px;

    @include breakpoint(small only) {
      font-size: rem-calc(20);
      margin-left: 0;
    }
  }

  // Button Icon
  > i {
    float: right;
    top: 0;
    position: relative;
    width: 30px;
    height: 30px;
    &:before {
      height: 16px;
      width: 2px;
      margin-top: -8px;
      margin-left: -1px;
    }
    &:after {
      height: 2px;
      width: 16px;
      margin-left: -8px;
      margin-top: -1px;
    }
    @include breakpoint(small only) {
      margin-right: 0;
    }
  }
}

.contact-card__content__inner .your-visit .c-expandable-button {
  border-top: none;
  border-bottom: 1px solid c('stone-line');
}

.contact-card__content__inner {
  .find-care-how,
  .info-visitor,
  .patient-information {
    padding-top: 30px;
  }

  .your-visit {
    padding-bottom: 30px;
  }

  .find-care-how {
    p {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
