@import '../../platformui/Linklist/styles.scss';

@include Linklist($className: 'find-care-links') {
  .find-care-links {
    margin-bottom: 60px;

    &__list {
      overflow: visible;

      li {
        padding: 0;

        &[dir='rtl'] {
          text-align: right;
        }

        &:before {
          content: none;
        }

        // remove padding in chapter block
        .c-chapter & {
          padding: 0;
        }

        @media print {
          a,
          span {
            font-size: 16pt !important ;
            text-decoration: none !important;
            line-height: 1.8 !important;
            color: black !important;
          }
          span {
            padding-left: 30px !important;
          }
        }
      }
      li:last-child {
        margin-bottom: 10px;
      }
      &:after {
        content: '';
        position: absolute;
        background-color: $warm-grey;
        height: 1px;
        width: 43px;

        [dir='rtl'] & {
          left: auto;
          right: 0;
        }
      }
    }

    &__header {
      font-weight: normal;
      text-transform: uppercase;
      color: $muted;
      line-height: 1.2;
      font-size: rem-calc(20);
      position: relative;
      margin-top: 0;
      margin-bottom: 30px;

      @media print {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 14pt !important ;
        color: black !important;
      }

      &:after {
        content: '';
        position: absolute;
        background-color: $warm-grey;
        height: 1px;
        width: 43px;
        left: 0;
        bottom: -12px;

        [dir='rtl'] & {
          left: auto;
          right: 0;
        }
      }

      // override due to chapter styles for heading.
      .c-chapter & {
        color: $muted;
      }
    }

    &__search {
      padding-left: 30px;
      position: relative;
      top: 10px;
      @include icon('E820');

      &:hover {
        color: $dusk;
      }

      @media print {
        span {
          font-size: 16pt !important;
          color: black !important;
        }
      }

      &:before {
        font-size: rem-calc(10);
        left: 0;
        position: absolute;
        text-decoration: none;
        top: 7px;

        @media print {
          top: 3px;
          text-decoration: none;
          color: black !important;
        }
      }
    }

    @include Linklist--block($marginBottom: 20px) {
      margin: 40px 0 40px;

      .toggle-chapter & {
        margin-top: unset;
      }
    }

    @include LinklistLink(
      $className: 'find-care-links',
      $icon: 'E850',
      $printColor: $denim
    ) {
      &:before {
        font-size: rem-calc(24);
        text-decoration: none;
      }
      &[href^="/"],
      &[href$="/"],
      &[href*="/#"],
      &[href*="http://"],
      &[href*="https://"]
      {
        &:before {
          font-size: rem-calc(24);
          top: 0;
        }
      }

      @media print {
        top: 2px !important;
      }

      span {
        @media print {
          padding-left: 20px;
          color: black !important;
        }
      }
    }
  }
}
