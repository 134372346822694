@import '../../platformui/Localnav/styles';

@include Localnav(
  $borderColor: rgba($lipstick, 0.3),
  $componentMargin: 0 0 60px,
  $itemColor: $berry,
  $borderMarkerWidth: 5px,
  $borderMarkerColor: $lipstick,
  $selectedItemFontWeight: normal,
  $selectedItemColor: $wine-red,
  $hasChildrenIconColor: $warm-grey
) {
  &__heading {
    font-size: rem-calc(20);

    a {
      color: $black;
    }
  }

  &__list {
    &__secondary {
      .c-localnav__list__item {
        a {
          padding-left: 40px;

          &:hover {
            padding-left: 40px;
          }
        }
      }
    }
  }
}
