@mixin YourVisit(
  $focusColor: hotpink,
  $textColor: hotpink,
  $buttonTextHover: hotpink,
  $linkHoverColor: hotpink,
  $borderColor: #d8d8d8,
  $listMargin: 0 0 10px
) {
  .your-visit {
    padding: 30px 7px 0 7px;
    & > h3 {
      margin: 0;
    }

    .your-visit {
      padding: 0;
      display: flex;
      flex-direction: column;
      border-top: none;

      @include breakpoint(large) {
        flex-direction: row;
      }

      @media print {
        flex-direction: column;
        margin: 0;
      }

      &__info-container {
        display: flex;
        flex-basis: auto;
        flex-direction: column;

        @include breakpoint(large) {
          flex-basis: 50%;
        }
      }
    }

    &__heading {
      font-size: rem-calc(14);
      font-weight: 400;
      line-height: 24px;
      color: $muted;
      text-transform: uppercase;
      margin: 10px 0 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #d8d8d8;
      letter-spacing: 0.03em;

      @media print {
        display: none;
      }
    }

    &__content {
      display: none;
      height: 0;
      flex-basis: auto;
      flex-direction: column;
      margin-bottom: 0;

      @media print {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &.visible {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 20px;
      }

      @include breakpoint(large) {
        flex-direction: row;
      }
    }

    &__toggle-button {
      position: relative;
      cursor: pointer;
      background: none;
      z-index: 8;
      padding-left: 0;
      padding-right: 0;
      @include focus-handler($color: $focusColor, $offset: -1px);

      @media print {
        display: none;
      }

      &__text {
        display: inline-block;
        margin-left: 24px;
        font-size: rem-calc(16);
        color: $textColor;
        white-space: nowrap;
        text-decoration: underline;

        &:hover {
          color: $buttonTextHover;
        }
      }
    }

    &__dropdown {
      .contact-subheading {
        margin-bottom: 0px;
      }
    }

    @media print {
      padding: 0;
      border: none;
    }
  }
  @content;
}
