@import '../Mobilenav/expand-button/styles';

@include ExpandButton(
  $className: 'plus--extended-info',
  $topPosition: 3px,
  $bgColorBtn: $denim,
  $btnHeight: 17px,
  $btnWidth: 17px,
  $btnBorderRadius: 100%,
  $lineThickness: 1px,
  $lineSize: 9px,
  $marginCentered: -5px
) {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    left: 5px;
  }
  right: auto;
}
