.error500 {
  min-height: calc(100vh - 241px);
  padding: 0;

  @include breakpoint(medium) {
    min-height: calc(100vh - 236px);
    padding: 120px 0;
  }

  @include breakpoint(large) {
    min-height: calc(100vh - 291px);
    padding: 50px 0 0;
  }

  /*.u-text-center {
    @include breakpoint(large) {
      position: absolute;
    }
  }*/

  .c-footer {
    top: 0;

    @include breakpoint(large) {
      top: 260px;
    }
  }

  .c-articleheader__heading {
    &--alt {
      font-size: rem-calc(25);
    }
  }

  .preamble {
    &--alt {
      font-size: rem-calc(25);
    }
  }

  .row.first-row {
    position: relative;
  }
}
