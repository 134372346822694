@mixin ContactAlert() {
  .contact-alert {
    &--content {
      padding-bottom: 10px;
      padding-top: 10px;
    }

    p {
      font-size: rem-calc(18);
      line-height: rem-calc(28);

      &:first-child {
        text-indent: 30px;

        @include breakpoint(medium) {
          text-indent: 0;
        }
      }
    }

    @media print {
      .c-alert {
        display: flex !important;
      }
    }
  }
}
