@mixin WaitingTimes($itemBorder: red, $headingBorder: green) {
  .waiting-times {
    padding: 0 6px;

    &__list-section {
      h3 {
        border-bottom: 1px solid $headingBorder;
        padding-bottom: 10px;
      }

      &__heading {
        font-weight: bold;
      }
      &__item {
        margin: 0 0 20px 0;
      }
    }

    &__table {
      margin-top: 20px;
      th,
      td {
        padding: 12px 0;
        border-right: none;
        background: none;
        border: none;
      }

      thead {
        display: block;
        border-bottom: 1px solid $headingBorder;
        tr {
          th {
            display: inline-block;
            padding: 0 0 20px 0;
            width: 260px;
          }
        }
      }
      tbody {
        tr {
          display: flex;
          border-bottom: 1px solid $itemBorder;
        }
        td {
          display: inline-block;
          width: 260px;
          line-height: 24px;
        }
      }
    }
    &__description {
      margin: 20px 0 30px 0;
      @include breakpoint(medium) {
        margin: 30px 0 50px 0;
        width: 620px;
      }
    }
  }
  @content;
}
