.contact-card-page {
  float: left;
  margin-bottom: 50px;
  position: relative;
  width: 100%;

  @include breakpoint(large) {
    display: flex;
  }

  @media print {
    .large-9,
    .large-6 {
      padding: 0;
      width: 100%;
    }

    .large-3 {
      display: none;
    }
  }
}
