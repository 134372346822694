@import '../../../platformui/Contact-card/unit/styles.scss';
@include Unit() {
  margin-bottom: 30px;

  @include breakpoint(medium) {
    margin-bottom: 0;
  }

  &__contact-card {
    border-top: 1px solid $very-light-pink;
    border-bottom: 1px solid $very-light-pink;

    &.subheading {
      border-top: 1px solid $very-light-pink;
      border-bottom: 1px solid $very-light-pink;

      @include breakpoint(large) {
        border-top: 1px solid $warm-grey;
        border-bottom: none;
      }
    }

    &.subheading .contact-card__content__inner.animation,
    &.subheading .contact-card__button-container {
      @include breakpoint(large) {
        border-bottom: none;
      }
    }
  }

  .offering {
    &__general-text {
      padding-bottom: 30px;
      border-bottom: none;
    }

    &__list {
      li:last-of-type {
        .contact-card.subheading {
          border-bottom: 1px solid $very-light-pink;

          @include breakpoint(medium) {
            border-bottom: none;
          }
        }
      }
      .contact-card {
        &__button-container {
          .plus--subheading {
            background: transparent;

            &:before,
            &:after {
              background: c('sky-base');
            }
          }
        }

        &.subheading {
          .contact-card__content__inner.animation {
            @include breakpoint(large) {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .contact-card {
    .contact-card__content__inner {
      margin: 0;
    }

    &--sublevel {
      border-top: 1px solid $very-light-pink;
      border-bottom: none;

      .contact-card {
        &__button {
          &-container {
            @include breakpoint(large) {
              height: fit-content;
              padding: 20px 0;
            }
          }
        }
        &__heading {
          font-size: 1rem;
        }
        &__content {
          &__inner {
            margin: 0 7px;
            padding-bottom: 20px;
          }
        }
      }
    }

    &--open {
      &.subheading {
        border-bottom: none;
      }
    }
  }

  .managements {
    &__item {
      &__text {
        font-weight: bold;

        &__name {
          font-weight: normal;
        }
      }
    }
  }
}
