@import '../../../platformui/SearchBlock/list.scss';
@import '../../../platformui/SearchBlock/listitem.scss';

@include search-list();

@include search-list-item(
  $containerPadding: 20px,
  $itemBgColor: $white,
  $itemAlternatingBgColor: $pale-grey,
  $itemBorderRadius: 10px
) {
  &__item {
    text-decoration: none;

    @include focus-handler($color: $muted, $offset: -10px);

    &__heading {
      margin: 0 0 5px;

      @include breakpoint(large) {
        margin: 0 0 10px;
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        &:focus {
          @include focus-handler();
        }
      }
    }

    &__text {
      color: $black;
      max-width: none;
    }
  }
}
