@import '../../Framework/platform-mixins/Icon';
@mixin Findus(
  $focusColor: blue,
  $linkIconColor: blue,
  $toggleBtnTextColor: blue,
  $hoverColor: red,
  $addressFont: 'Open Sans'
) {
  .find-us {
    margin-top: 30px;
    padding: 0 7px 20px;

    @media print {
      margin-top: 13px;
      padding: 0;
    }

    &__map-wrapper {
      margin-bottom: 16px;
      position: relative;

      @media print {
        margin-top: 10px;
      }
    }

    &__map-planner {
      background: $white;
      border-radius: 5px;
      top: 4px;
      left: 4px;
      padding: 3px 30px 3px 5px;
      position: absolute;
      z-index: 2;

      @media print {
        display: none;
      }

      @include breakpoint(medium) {
        left: 10px;
        top: 10px;
      }

      @include breakpoint(large) {
        left: auto;
        right: 10px;
        top: 10px;
      }

      &__link {
        font-weight: 400;
        font-size: rem-calc(14);
        line-height: 1.5;
        text-decoration: none;

        @include focus-handler($color: $focusColor, $offset: 2px);

        @include breakpoint(medium) {
          font-size: rem-calc(16);
        }

        &:hover {
          text-decoration: none;
        }

        &:before {
          background: $linkIconColor;
          border-radius: 100px;
          content: '';
          display: block;
          height: 20px;
          position: absolute;
          right: 5px;
          top: 5px;
          width: 20px;
          z-index: 1;
        }

        @include icon('e80e', $pseudo: after);

        &:after {
          color: $white;
          font-size: 12px;
          position: absolute;
          right: 9px;
          top: 9px;
          z-index: 2;
        }

        span {
          text-decoration: underline;

          &:hover {
            color: $hoverColor;
          }
        }
      }
    }

    &__map {
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      width: 100%;
      z-index: 1;

      @media print {
        max-width: 100% !important;
        max-height: 100% !important;
      }

      /*& > div {
        height: 100%;
        width: 100%;
      }*/
    }

    &__address {
      float: left;
      margin: 0;
      padding: 0;
      width: 100%;

      @media print {
        width: 400px;
      }

      dt {
        clear: both;
        font-family: $addressFont;
        font-weight: bold;
        display: inline-block;
        line-height: 1.5;
        padding: 0;
        width: 100%;

        @include breakpoint(large) {
          float: left;
          margin: 0 0 10px;
          width: 32%;
        }

        @media print {
          font-size: 8px;
        }
      }

      dd {
        display: inline-block;
        line-height: 1.5;
        margin: 0 0 20px;
        padding: 0;
        width: 100%;

        @include breakpoint(large) {
          float: right;
          width: 66%;
        }

        @media print {
          font-size: 8px;
          margin: 0 0 10px;
        }
      }
    }

    &__webpage {
      display: block;
      text-decoration: none;
      @include focus-handler($color: $focusColor, $offset: 4px);

      @media print {
        font-size: 8px;
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: underline;
        display: inline-block;
        max-width: 100%;

        &:hover {
          color: $hoverColor;
        }

        @include breakpoint(large) {
          display: block;
          white-space: nowrap;
        }
      }
    }

    &__toggle-button {
      position: relative;
      background: none;
      z-index: 8;
      padding-left: 0;
      @include focus-handler($color: $focusColor);

      @media print {
        display: none;
      }

      &__text {
        display: inline-block;
        margin-left: 24px;
        font-size: rem-calc(16);
        color: $toggleBtnTextColor;
        white-space: nowrap;
        text-decoration: underline;

        &:hover {
          color: $hoverColor;
        }
      }
    }

    @media print {
      .row {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    @content;
  }
}
