@import '../../Linklist/styles.scss';

@mixin Offering($borderColor: blue) {
  .offering {
    margin-top: 30px;

    @media print {
      margin: 20px 0;
    }

    &__general-text {
      margin: 0 7px;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba($borderColor, 0.7);

      @media print {
        border: none;
        padding: 0;
        margin: 0;
      }
    }

    &__list {
      list-style-position: inside; // fix for IE11, hides bullets.
      padding: 0;
      margin: 0;
      margin-bottom: 10px;
    }
    li > h3 {
      margin: 0;
    }
    .c-linklist__list {
      @include LinklistLink($icon: 'E82B') {
        &[href$="/"],
        &[href*="/#"],
        &[href*="http://"],
        &[href*="https://"]
        {
          &:before {
            font-size: 20px;
            top: 3px;

            @media print {
              top: -2px !important;
            }
          }
        }

        span {
          @media print {
            padding-left: 30px;
          }
        }
      }
    }
    @content;
  }
}
