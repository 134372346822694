@import '../../platformui/Linklist/styles.scss';

.c-linklist {
  &.c-linklist--site-footer {
    padding-top: rem-calc(20);
    font-size: rem-calc(14);
    row-gap: 30px;

    @include breakpoint(medium) {
      li,
      a {
        display: inline-flex;
      }
    }

    @include breakpoint(large) {
      padding: 5px;
      text-align: right;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }

    .c-linklist {
      align-items: center;
      justify-content: flex-end;
    }

    .c-linklist__link {
      color: c('white');
      padding-left: 0;
      text-decoration: underline;

      &:focus {
        @include focus-handler($color: $white);
      }

      span {
        color: inherit;
      }
    }
  }
  &.footer-linklist {
    li {
      padding: 0 0 10px 0;
      a {
        color: $berry;
        @include focus-handler($color: $berry);

        span {
          color: $berry;
          text-decoration: underline;
        }
      }
    }

    @include LinklistLink($icon: 'f105', $printColor: $denim) {
      &:before {
        color: $lipstick;
      }

      &[href$="/"],
        &[href*="http://"],
        &[href*="https://"]
      {
        &:before {
          font-size: rem-calc(19);
          top: 3px;
        }
      }

      span {
        color: $berry;
        border-bottom: none;

        &:hover {
          color: $berry;
          border-bottom: none;
        }
      }
    }
  }

  // Will not show arrow icon in mobile view
  &.c-linklist--no-icons {
    button,
    a {
      padding-left: 0;

      &:before {
        display: none;
        content: none;
      }

      span {
        color: $berry;
        text-decoration: none;
      }
    }
  }
}
