@import '../../../platformui/Contact-card/find-us/styles.scss';
@include Findus(
  $focusColor: $muted,
  $linkIconColor: $denim,
  $toggleBtnTextColor: $denim,
  $hoverColor: $dusk,
  $addressFont: $secondary-font
) {
  padding-bottom: 0;
}
