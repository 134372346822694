@import '../../Modal/fullscreen-modal.scss';

@mixin LetterLink(
  $color: #000,
  $bgColor: #ccc,
  $hover: true,
  $active: false,
  $linkHover: #000
) {
  align-items: center;
  background-color: $bgColor;
  border: 1px solid rgba($black, 0.2);
  border-radius: 100%;
  color: $color;
  display: flex;
  height: 36px;
  justify-content: center;
  text-decoration: none;
  width: 36px;

  @if $hover == true {
    &:hover,
    &:focus {
      background-color: $linkHover;
      color: $white;
      outline: none;
    }
  }
  @include focus-handler($offset: 2px);

  @if $active == true {
    background-color: $linkHover;
    color: $white;
  }
}

@mixin FindCare-Categorieslist(
  $cta: #000,
  $lightGrey: #ccc,
  $linkHover: #000,
  $black: black,
  $white: white,
  $muted: #000
) {
  @include FullscreenModal($className: 'category-lists-modal') {
    &__modal-panel {
      &__content {
        height: 100%;
        margin: 10px 0;
        max-height: calc(100% - 80px);
        position: fixed;
        transform: translate(-50%, -50%);
        top: calc(50% - 10px);
        left: 50%;
        padding: 20px 0 20px 20px;

        @include breakpoint(large) {
          margin: 0;
          padding: 40px;
        }
      }
    }

    &__modal-close {
      left: calc(100% - 35px);
      top: -7px;

      &:before {
        font-size: 27px;
        left: 7px;
        top: 7px;
      }

      @include breakpoint(large) {
        left: calc(100% - 30px);
        top: -10px;
      }
    }
  }

  .categories-lists {
    &__header {
      border-bottom: 1px solid rgba($black, 0.2);
      margin-bottom: 20px;
      padding-bottom: 10px;

      @include breakpoint(large) {
        margin-bottom: 35px;
        padding-bottom: 25px;
      }
    }

    &__heading {
      color: $muted;
      font-size: rem-calc(14);
      font-weight: normal;
      margin-bottom: 0;
      text-transform: uppercase;

      @include breakpoint(large) {
        font-size: rem-calc(20);
      }
    }

    &__list-wrapper {
      display: flex;
      height: calc(100% - 47px);
      flex-direction: row-reverse;

      @include breakpoint(large) {
        flex-direction: row;
        height: calc(100% - 85px);
      }
    }

    &__topics {
      height: 100%;
      overflow: auto;
      position: relative;
      width: 100%;
      -webkit-overflow-scrolling: touch;

      & > div {
        position: relative;
      }

      @include breakpoint(large) {
        margin-left: 120px;
      }

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0;

        @include breakpoint(large) {
          width: 30px;
          height: 14px;
        }
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 6px solid transparent;
        background: $cta;
        background-clip: content-box;
        height: 70px;
      }

      &::-webkit-scrollbar-track {
        background: $lightGrey;
        border-radius: 20px;
        margin: 0 10px 0;

        @include breakpoint(large) {
          margin: 0 0 5px;
        }
      }
    }
  }

  .categories-alphabetical-list {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    @include breakpoint(large) {
      display: flex;
      flex-wrap: wrap;
      width: 120px;
      position: absolute;
      height: calc(100% - 165px);
      max-height: 600px;
      overflow: auto;
    }

    &__item {
      cursor: pointer;
      height: 22px;
      list-style: none;

      a {
        color: $cta;
        display: inline-block;
        font-size: rem-calc(10);
        height: 27px;
        padding-left: 9px;
        text-align: center;
        text-decoration: none;
        transition: all 0.2s ease-in;
        width: 40px;
      }

      &--is-active {
        a {
          background-color: #e9e9e9;
          border-radius: 100px 0 0 100px;
          color: $black;
          font-size: rem-calc(16);
          font-weight: bold;
          padding-top: 2px;
          @include focus-handler($offset: 2px);
        }
      }

      @include breakpoint(large) {
        height: auto;

        margin: 4px 10px 14px 4px;

        a {
          font-size: rem-calc(20);
          padding-left: 0;
          @include LetterLink($linkHover: $linkHover);
        }

        &--is-active {
          a {
            font-weight: 200;
            @include LetterLink($active: true);
          }
        }
      }
    }
  }

  .categories-topic-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 35px;
    padding: 0;
    width: 100%;

    @include breakpoint(large) {
      justify-content: space-between;
      min-width: 660px;
      width: 90%;
    }

    &__wrapper {
      &:last-of-type {
        margin-bottom: 320px;
      }
    }

    &__heading {
      display: none;

      @include breakpoint(large) {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        min-width: 660px;
        width: 90%;
      }

      &:before {
        border-bottom: 1px solid rgba($black, 0.3);
        bottom: 20px;
        content: '';
        margin-right: 20px;
        position: relative;
        width: 50%;
      }

      &:after {
        border-bottom: 1px solid rgba($black, 0.3);
        bottom: 20px;
        content: '';
        margin-left: 20px;
        position: relative;
        width: 50%;
      }

      h3 {
        font-weight: normal;
        font-size: rem-calc(20);
        margin: 0;
        @include LetterLink(
          $color: $muted,
          $bgColor: transparent,
          $hover: false
        );
      }
    }

    &__item {
      list-style: none;
      display: inline-block;
      overflow: hidden;
      position: relative;
      width: 100%;

      @include breakpoint(large) {
        padding: 8px 20px;
        width: calc(50% - 10px);

        &:nth-child(4n + 1),
        &:nth-child(4n + 2) {
          background-color: $lightGrey;
          border-radius: 20px;
        }
      }

      &--focus-only {
        background-color: transparent;
        height: 0;
        overflow: visible;

        @include breakpoint(large) {
          padding: 8px 20px 0;

          &:nth-child(4n + 1),
          &:nth-child(4n + 2) {
            background-color: transparent;
          }
        }
      }

      a {
        &:hover {
          color: $linkHover;
        }
      }
    }

    &__item-label {
      color: $cta;
      cursor: pointer;
      text-decoration: underline;
      @include focus-handler();

      input {
        position: absolute;
        left: -1000px;
      }

      &--focus-only {
        opacity: 0;
        height: 0;

        &:focus {
          opacity: 1;
          height: 100%;
        }
      }
    }
  }
  @content;
}
