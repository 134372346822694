@mixin UnitQualityIndicators(
  $className: 'quality-indicators',
  $blockMargin: 30px,
  $sliderBackgroundColor: $very-light-pink,
  $sliderFillColor: $muted,
  $buttonColor: $denim,
  $focusColor: $muted,
  $hoverColor: $dusk,
  $textContainerMaxWidth: 620px
) {
  .#{$className} {
    margin-block: $blockMargin;

    h4,
    p {
      margin-block: $blockMargin;
      max-width: $textContainerMaxWidth;
    }

    &__indicator-container {
      max-width: 780px;
      p {
        margin-bottom: 0;

        @include breakpoint(medium) {
          margin-block: $blockMargin;
        }
      }
    }

    &__indicator-info-container {
      margin-block: $blockMargin;
    }

    &__indicator-info-divider {
      padding-block: 10px;
      border-top: 2px solid transparent;
      background-image: linear-gradient(
        to right,
        $sliderBackgroundColor 50%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: top;
      background-size: 16px 1px;
      background-repeat: repeat-x;
    }

    &__indicator-info-content {
      max-width: $textContainerMaxWidth;
      margin-block: 10px;

      h5 {
        @include secondary-font-family();
        font-size: rem-calc(14);
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: rem-calc(1.4);
        line-height: 20px;
        margin-top: 10px;
      }
    }

    &__indicator-info-footer {
      margin-top: 30px;

      p {
        margin-block: 0px;
      }
    }

    &__slider-container {
      max-width: 650px;
    }

    &__slider,
    &__slider-progress {
      height: 5px;
      border-radius: 59px;
    }

    &__slider {
      position: relative;
      width: 100%;
      margin-block: 10px;
      background-color: $sliderBackgroundColor;
    }

    &__slider-progress {
      position: absolute;
      height: 100%;
      background-color: $sliderFillColor;
    }

    &__slider-stop {
      position: absolute;
      bottom: -25px;
      top: auto;
      transform: translateX(-50%);
      font-weight: bold;

      &.right-offset-50 {
        right: -50px;
      }

      &.right-offset-75 {
        right: -75px;
      }

      @include breakpoint(medium) {
        top: -25px;
        bottom: auto;
        right: auto;
      }
    }

    &__toggle-button {
      position: relative;
      background: none;
      padding-left: 0;
      @include focus-handler($color: $focusColor);

      @media print {
        display: none;
      }

      &__text {
        margin-left: 24px;
        font-size: rem-calc(16);
        color: $buttonColor;
        text-decoration: underline;

        &:hover {
          color: $hoverColor;
          cursor: pointer;
        }
      }
    }

    @content;
  }
}
