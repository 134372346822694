@import '../../platformui/CollapsibleText/styles.scss';

@include CollapsibleText($color: $denim, $focusColor: $berry) {
  margin: 10px 0 40px 0;

  @media print {
    .contact-card-page & {
      margin: 10px 0 0 0;
    }
  }

  @include breakpoint(large) {
    margin: 10px 7px 40px 7px;
  }

  &__content {
    height: 65px;
    margin-bottom: 5px;
    overflow: hidden;
    position: relative;

    @include breakpoint(large) {
      height: auto;
      margin-bottom: 5px;
      min-height: 66px;
    }

    @media print {
      overflow: visible;
      height: auto;
      min-height: 0 !important;
    }

    p {
      font-size: rem-calc(16);
    }

    a {
      @media print {
        display: none;

        .contact-card-page & {
          display: inline-block;
        }
      }
    }

    .c-collapsible-text--expanded & {
      height: auto;
      margin-bottom: 5px;
      min-height: 66px;
    }

    &:after {
      background-image: linear-gradient(to bottom, rgba(white, 0), $white);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      @include breakpoint(large) {
        content: none;
      }

      .c-collapsible-text--expanded & {
        display: none;
      }
    }
  }

  &__button {
    color: $denim;
    cursor: pointer;
    display: block;
    background: none;
    float: left;
    padding: 4px 0 4px 30px;
    position: relative;
    @include focus-handler($berry);

    @include breakpoint(large) {
      display: none;
    }

    &__inner {
      font-size: rem-calc(16);
      border-bottom: 1px solid $denim;
      &:before {
        background: $white;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        transition: all 0.2s ease;
        left: 9px;
        top: 8px;
        height: 10px;
        width: 2px;
        z-index: 2;

        .c-collapsible-text--expanded & {
          transform: rotate(90deg);
        }
      }

      &:after {
        background: $white;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        transition: all 0.2s ease;
        left: 5px;
        top: 12px;
        height: 2px;
        width: 10px;
        z-index: 2;

        .c-collapsible-text--expanded & {
          transform: rotate(180deg);
          opacity: 0;
        }
      }
    }

    &:after {
      border-radius: 100px;
      background-color: $denim;
      content: '';
      display: block;
      height: 20px;
      left: 0;
      position: absolute;
      top: 3px;
      width: 20px;
      z-index: 1;
    }
  }
}
