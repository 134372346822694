@import '../../Mobilenav/expand-button/styles';
@mixin ExtendedInfo(
  $headingColor: hotpink,
  $textColor: hotpink,
  $buttonTextHover: hotpink,
  $linkHoverColor: hotpink,
  $borderColor: #d8d8d8,
  $padding: 0,
  $listMargin: 0 0 10px
) {
  .extended-info {
    padding: $padding;
    border-top: 1px solid $borderColor;

    a[href^='tel'] {
      font-variant-numeric: tabular-nums;
    }
    & > h3 {
      margin: 0;
    }
    .extended-info {
      padding: 0;
      display: flex;
      flex-direction: column;
      border-top: none;

      @include breakpoint(large) {
        flex-direction: row;
      }

      @media print {
        flex-direction: column;
        margin: 0;
      }

      &__info-container {
        display: flex;
        flex-basis: auto;
        flex-direction: column;

        @include breakpoint(large) {
          flex-basis: 50%;
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0 0 15px 0;
        }
      }
      &__item:first-child {
        h4 {
          margin-top: 0;
        }
      }
      &__item {
        span {
          display: inline-block;
          font-variant-numeric: tabular-nums;
        }

        &__day-part {
          width: 80px;
        }
      }
    }

    &__content {
      display: none;
      flex-direction: column;
      height: 100%;
      margin-top: 20px;
      flex-basis: auto;
      margin-bottom: 0;

      @media print {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &.visible {
        display: flex;
      }
    }

    &__heading {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 24px;
      color: $headingColor;
      text-transform: uppercase;
      margin: 0 0 20px;
      padding-bottom: 5px;
      border-bottom: 1px solid #d8d8d8;
      letter-spacing: 0.03em;

      @media print {
        display: none;
      }
    }

    &__container {
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      margin-bottom: 0;

      h4 {
        @include breakpoint(large) {
          width: 200px;
        }

        @media print {
          width: 140px;
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: block;
          margin: $listMargin;

          @media print {
            margin: 0 0 10px;
          }

          @include breakpoint(large) {
            display: flex;
          }

          a {
            display: inline-block;
            font-size: 1rem;

            &:hover {
              color: $linkHoverColor;
            }

            @media print {
              background-image: none !important;
              color: $black;
              font-size: 0.5rem !important;
              margin-bottom: 0;
            }
          }
        }
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: auto;
      }
    }

    @media print {
      padding: 0;
      border: none;
    }
    @content;
  }
}
