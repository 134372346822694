@import '../../platformui/ArticleFooter/styles';

@include ArticleFooter($background: $white, $padding: 0, $margin:  90px 0 0 0 ) {
  .c-articlefooter {
    &:first-of-type{
      .c-articlefooter__wrapper {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          background-color: #9f9f9f;
          height: 1px;
          width: 43px;
          left: 0;
          top: -30px;

          @include breakpoint(large) {
            content: none;
          }
        }
      }

      @include breakpoint(large) {
        margin: 20px 0 30px 0;
      }
    }

    margin: 20px 0 30px 0;
    @include breakpoint(large) {
      margin: 0 0 30px;
    }
    &__key {
      font-weight: bold;
      display: block;
    }

    @media print {
      padding: 20px 0;
      border-top: 1px solid #9f9f9f;
      margin-top: 30px;
      span {
        font-size: 12pt !important ;
      }
      .medium-4 {
        width: 33.3333%;
        padding-left: 0;
        position: relative;
      }

      .medium-8 {
        padding-left: 0;
        width: 66.6667%;
      }
    }
  }
}
