@import '../../platformui/SearchBlock/styles';
@import './findcare-searchfield-autocomplete.scss';
$query: '.findcare-form__field--q';

.findcare-search {
  position: relative;
  width: 100%;

  @include breakpoint(medium) {
    //display: flex;
  }

  &__free-text {
    position: relative;

    @include breakpoint(medium) {
      width: 100%;
    }

    @include breakpoint(large) {
      margin-right: 10px;
    }
  }
}

@include SearchFieldQuery(
  $className: findcare-search,
  $searchFieldBg: $pale-grey,
  $cta: $denim,
  $muted: $muted,
  $border: solid 1px $denim,
  $borderColor: $cloudy-blue,
  $borderRadius: (
    10px 0 0 10px,
    10px,
  ),
  $searchFieldFocusBorder: 1px solid $denim,
  $searchFieldFocusShadow: inset 0 5px 10px 0 rgba($black, 0.2),
  $paddingLeft: (
    0,
    0,
  )
) {
  .findcare-search__field__query {
    border-radius: 10px;
    border-right: solid 1px $denim;
    font-family: $base-font;
    font-size: rem-calc(16);
    width: 100%;

    input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    input::-ms-clear {
      display: none;
    }

    #{$query} & {
      @include breakpoint(medium only) {
        width: 100%;
      }
    }

    input::placeholder {
      opacity: 1;
      color: $muted;
      font-style: italic;
    }

    &--is-nearby {
      cursor: pointer;
      padding-left: 44px;
      text-align: left;

      @include icon('e85c');
      position: relative;

      &:before {
        color: $denim;
        font-size: 30px;
        left: 10px;
        position: absolute;
        top: 15px;
      }

      @include icon('e84e', $pseudo: after);

      &:after {
        color: $black;
        font-size: 20px;
        right: 15px;
        opacity: 1;
        position: absolute;
        top: 20px;
        transition: opacity 0.2s ease;
      }

      .c-spinner {
        opacity: 0;
        transition: opacity 0.2s ease;
      }
    }

    &--is-loading {
      &:after {
        opacity: 0;
      }
      .c-spinner {
        opacity: 1;
      }
    }
  }
}

.findcare-searchfield {
  input {
    border: none;
    font-size: inherit;
    font-family: inherit;
    padding: 10px 80px 10px 20px;
    background-color: transparent;
    height: 100%;
    width: 100%;
  }
}
.findcare-searchfield__unit-wrapper {
  input {
    padding-right: 40px;
  }
}
@media screen and #{breakpoint(768)} and #{breakpoint(large down)} {
  .findcare-form__button {
    font-size: rem-calc(20);
    width: 86px;
    align-self: flex-end;
  }
}
