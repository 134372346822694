@import '../../platformui/VideoContainer/styles';

@include VideoContainer(
  $blockBackground: c('secondary-light'),
  $headingColor: c('main'),
  $headerSize: (
    "default": (
      "landscape": 22px,
    ),
    "large": (
      "landscape": 24px,
      "standing": 36px,
    ),
  ),
  $descriptionColor: c('black'),
  $linkColor: c('cta'),
  $iconColor: c('cta'),
  $iconContent: '\f105',
);
