@import '../../platformui/ArticleList/styles';

@include ArticleList(
  $headingColor: $denim,
  $textColor: $black,
  $borderBottomHover: solid 5px rgba($denim, 0.5),
  $borderBottomFocus: false,
  $imageMarginBottom: (
    15px,
    15px,
    20px,
  ),
  $contentHeight--gt2: 100%,
  $contentHeight--gt3: 100%,
  $contentHeight--nth2: 100%,
  $maxHeightContentSmall: 100%,
  $lineClamp: 4,
  $textHeight: 100%,
  $mobileLineClamp: 4,
  $mobileTextHeight: 100%,
  $linkIconColor: $denim
) {
  padding-bottom: 10px;
  @include focus-handler($color: $muted, $offset: 8px);

  &__heading {
    @include secondary-font-family();
    font-size: 1.375rem;
    font-weight: bold;
    line-height: 1.25;
    margin: 0 0 5px;

    @include breakpoint(large) {
      font-size: 1.5rem;
      line-height: 1.2;
      margin: 0 0 15px;

      .c-article-list--gt1 & {
        line-height: 1.25;
        margin: 0 0 10px;
      }
    }
  }
  
  &__text {
    font-size: 1rem;

    p {
      margin: 0;
    }

    @include breakpoint(large) {
      line-height: 1.44;
      max-height: unset;

      .c-article-list--gt2 & {
        font-size: 1rem;
      }
    }
  }

  &__content-wrapper {
    @include breakpoint(medium) {
      padding-right: 15px;
    }

    &:after {
      background-image: none;
      .c-section--blue & {
        background-image: none;
      }

      .c-section--grey & {
        background-image: none;
      }
    }
  }

  &__content {
    max-height: unset;
  }
}
