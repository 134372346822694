@mixin FindCare-Autocomplete(
  $className: '.findcare-autocomplete',
  $black: #000,
  $white: #fff,
  $muted: #333,
  $lightGrey: #ccc,
  $cta: #000
) {
  #{$className} {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    left: 0;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0 0 10px 0;
    position: absolute;
    right: 0;
    top: 55px;
    z-index: 99999;

    &--region {
      padding: 0 0 35px;
    }

    @include breakpoint(medium) {
      top: auto;
    }

    .findcare-search & {
      top: 89px;

      @include breakpoint(medium) {
        top: 109px;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      &:first-child {
        margin: 0;
      }
    }

    &__heading {
      border-bottom: 1px solid rgba($black, 0.2);
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        padding-left: 13px;
      }
    }

    &__item {
      border-left: none;
      margin: 0;
      height: auto;
      padding: 0;
      width: 100%;
      button {
        cursor: pointer;
      }

      &__action {
        @include focus-handler($muted, -1px);
        background: $white;
        color: $black;
        cursor: pointer;
        display: block;
        font-size: rem-calc(16);
        padding: 12px;
        text-align: left;
        text-decoration: none;
        width: 100%;

        &:hover,
        &:active {
          background: $lightGrey;
        }

        &--nearby {
          padding-left: 40px;

          @include icon('e85c');
          position: relative;

          &:before {
            color: $cta;
            font-size: 30px;
            left: 4px;
            position: absolute;
            top: 6px;
          }
        }
      }

      &--is-selected {
        .findcare-autocomplete__item__action {
          background: $lightGrey;
        }
      }
    }

    &__labelcontainer {
      margin-left: 12px;
      margin-top: 10px;
    }

    &__sublabel {
      color: $muted;
      display: block;
      font-size: rem-calc(14);
      font-style: italic;
    }

    &__wrapper {
      padding: 10px 10px 0 10px;
    }
    &__close-btn {
      background: transparent;
      cursor: pointer;
      @include icon('e84e');

      font-size: 20px;
      margin: 10px 0 14px 0;
      &:before {
        margin-right: 0px;
      }
    }

    @content;
  }
}
