@import '../../Progressbar/styles.scss';
@import '../../TableWrapper/styles';

@mixin Reviews($barColor: $lipstick) {
  .reviews {
    @include Progressbar(black, $barColor) {
      margin-top: 20px;

      svg {
        min-height: 60px;
        min-width: 60px;
      }

      .progress__text {
        font-size: rem-calc(18);
      }
      @include breakpoint(medium) {
        .progress__text {
          font-size: rem-calc(24);
        }
      }
    }

    margin-top: 20px;
    margin-bottom: 30px;
    .medium-offset-4 {
      @include breakpoint(medium) {
        margin-right: 33.333333%;
      }
    }

    .show-medium {
      display: none;
      @include breakpoint(medium) {
        display: flex;
      }
    }
    .show-small-only {
      display: none;
      @include breakpoint(small only) {
        display: flex;
      }
    }

    &__preamble {
      padding-left: 10px;
      margin-bottom: 40px;
      float: none;
    }

    &__ingress {
      padding-left: 10px;
      margin-bottom: 40px;
    }
    &__subheading {
      color: $muted;
      border-top: 1px solid rgba($black, 0.2);
      border-bottom: 1px solid rgba($black, 0.2);
      margin-bottom: 1.25rem;
      margin-top: 1.25rem;

      h3 {
        margin: 10px 0 0.375rem;
        font-size: rem-calc(14);
        color: $muted;
        text-transform: uppercase;
        font-weight: normal;
        padding: 5px 10px;
      }
    }
    .review {
      &__table-heading {
        display: flex;
        align-items: center;
        font-size: rem-calc(14);
        font-family: $secondary-font;
        letter-spacing: 1.4px;
        color: $slate-grey;
        height: 56px;
        border-top: 1px solid rgba($lipstick, 0.5);
        margin-top: 25px;
      }
      &__cardwrapper {
        table th {
          &:first-child {
            text-align: left;
          }
          text-align: center;
        }
        table td {
          &:first-child {
            text-align: left;
          }
          text-align: right;
        }
        &:nth-child(even) {
          background-color: $faded-pink;
          table th {
            background-color: $white;
          }
          table td {
            border-color: #c12143;
            &:first-child {
              text-align: left;
            }
            text-align: right;
          }
        }
        border-radius: 10px;
        padding: 10px;
        margin-left: 0;
        margin-right: 0;
        &:last-child {
          margin-bottom: 30px;
        }

        p {
          margin-bottom: 10px;
        }
      }

      &__inner-description {
        padding-bottom: 20px;
      }
      &__content {
        height: 0;
        max-width: 625px;
        opacity: 0;
        overflow: hidden;
        transition: opacity 0.6s ease-in-out;
        visibility: hidden;
        &__text {
          display: none;

          .table-wrapper {
            margin-bottom: 0px;
          }
        }
        &--is-open {
          height: auto;
          opacity: 1;
          padding: 20px;
          visibility: visible;

          @include breakpoint(medium) {
            padding: 30px;
          }
        }

        &__wrapper {
          margin-bottom: 10px;
          &__content {
            overflow: hidden;
            position: relative;

            p:last-child {
              margin-bottom: 0;
            }
          }

          &--expanded {
            .review__content__text {
              height: auto;
              display: block;
              padding: 20px 0 20px 0;
              margin-top: 10px;
              border-top: 1px dashed rgba($black, 0.3);
            }
            .review-expand-button {
              &:before {
                transform: rotate(90deg);
              }
              &:after {
                transform: rotate(180deg);
              }
            }
          }

          &__button {
            padding-top: 10px;
            background: transparent;
            color: $denim;
            cursor: pointer;
            font-size: rem-calc(16);
            padding: 0 0 0 25px;
            position: relative;

            @include focus-handler();

            &__inner {
              border-bottom: solid 0.5px $denim;
            }
          }
        }
      }
    }
  }

  @include ExpandButton(
    $className: review-expand-button,
    $expandedClassModifier: '.review__content__text--expanded',
    $topPosition: 2px,
    $bgColorBtn: $denim,
    $btnHeight: 17px,
    $btnWidth: 17px,
    $btnBorderRadius: 100%,
    $lineThickness: 1px,
    $lineSize: 9px,
    $marginCentered: -5px
  ) {
    right: unset;
    left: 0px;
  }
  @include TableWrapper($scrollbarColor: $lipstick) {
    @include breakpoint(small only) {
      position: relative;

      table {
        padding: 0 10px;

        @include breakpoint(large) {
          padding: 0 30px;
        }

        @media print {
          padding: 0;
        }
      }

      caption {
        margin: 0 10px;

        @include breakpoint(large) {
          margin: 0 30px;
        }

        @media print {
          margin: 0;
        }
      }

      // class controlling overflow
      .table-wrapper-inner {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        @media print {
          overflow-x: visible !important;
        }

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 14px;
          height: 14px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          border: 3px solid transparent;
          background: $lipstick;
          background-clip: content-box;
        }

        &::-webkit-scrollbar-track {
          margin: 0 10px;

          @include breakpoint(large) {
            margin: 0 30px;
          }
        }
      }

      &:after,
      &:before {
        @media print {
          content: none;
        }
      }

      // Table is both wide and has overflow
      &.table-wrapper--is-wide {
        @include breakpoint(large) {
          &:before {
            right: 100px;
          }

          &:after {
            left: -100px;
          }
        }

        @include breakpoint(1300px) {
          &:before {
            right: 200px;
          }

          &:after {
            left: -200px;
          }
        }
      }
    }
    @content;
  }
}
