@import '../../platformui/Linklist/styles.scss';

// modifier class, .teaser-linklist, in platformui
.c-linklist.teaser-linklist {
  &__list {
    li {
      &[dir='rtl'] {
        text-align: right;
      }
    }
  }

  &__header {
    font-weight: normal;
    text-transform: uppercase;
    color: $muted;
    line-height: 1.2;
    font-size: rem-calc(20);
    position: relative;
    margin-bottom: 30px;

    @media print {
      margin-bottom: 10px;
    }

    &:after {
      content: '';
      position: absolute;
      background-color: $warm-grey;
      height: 1px;
      width: 43px;
      left: 0;
      bottom: -12px;

      [dir='rtl'] & {
        left: auto;
        right: 0;
      }
    }
  }

  @include LinklistLink($icon: '\F105', $printColor: $denim) {
    padding-left: 18px;
    color: $lipstick;

    &[href$="/"],
        &[href*="/#"],
        &[href*="http://"],
        &[href*="https://"]
    {
      &:before {
        font-size: rem-calc(16);
        top: 3px;
      }
    }

    [dir='rtl'] & {
      padding-left: 0;
      padding-right: 18px;
    }

    span {
      border-bottom: none;
      background-image: none;
      color: $berry;

      &:hover {
        border-bottom: 0.5px solid $berry;
        background-image: none;
      }
    }
  }
}
