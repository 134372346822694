$globaltheme:1177;
/* $breakpoints: (
  small: 0,
  // foundation
  medium: 640px,
  // foundation
  large: 1025px,
  // foundation
  xlarge: 1200px,
  // foundation
  xxlarge: 1440px,
  // foundation
  sm: 1px,
  md: 640px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1440px,
);

@import './core'; */

@import './base/color-palette';
@import './base/async-fonts';
@import './base/font-base';
@import '../icons/css/fontello';

$site-base-font: $base-font;
$editor: false;

//TODO: Flytta till foundation import fil
@import './base/foundation-settings.scss';
@import 'foundation-sites/scss/foundation.scss';
@include foundation-grid;

@import './base/rtl.scss';
@import './base/font-book.scss';

@import '../../platformui/Framework/platform-mixins/CookieProPanel.scss';
@include CookieProPanel(
  $focusColor: $muted,
  $acceptBtnHoverColor: c('cta-dark'),
  $acceptBtnTextHoverColor: $white,
  $settingsBtnTextHoverColor: c('cta'),
  $settingsBtnHoverColor: c('sky-background'),
  $settingsBtnBorder: 1px solid c('cta'),
  $fontWeight: bold
);
@import '../../components/header/regions.scss';
@import '../../components/collapsible-panel/styles.scss';
@import '../../components/linklist/styles.scss';
@import '../../components/footer/styles.scss';
@import '../../components/breadcrumb/styles.scss';
@import '../../components/meganav/styles.scss';
@import '../../components/adjustable-link/styles.scss';
@import '../../components/mobilenav/styles.scss';
@import '../../components/metanav/styles.scss';
@import '../../components/article-list/styles.scss';
@import '../../components/section/styles.scss';
@import '../../components/teaser/styles.scss';
@import '../../components/share/styles.scss';
@import '../../components/article-footer/styles.scss';
@import '../../components/image/styles.scss';
@import '../../components/chapter/styles.scss';
@import '../../components/video/styles.scss';
@import '../../components/article-header/styles.scss';
@import '../../components/search/styles.scss';
@import '../../components/contact-card/styles.scss';
@import '../../components/regionalcontainer/styles.scss';
@import '../../components/alert/styles.scss';
@import '../../components/alertlist/styles.scss';
@import '../../components/anchor-nav/styles.scss';
@import '../../components/regionalarticlefooter/styles.scss';
@import '../../components/regionlabel/styles.scss';
@import '../../components/collapsible-text/styles.scss';
@import '../../components/globalsearch/styles.scss';
@import '../../components/faq/styles.scss';
@import '../../components/information-type/styles.scss';
@import '../../components/localnav/styles.scss';
@import '../../components/findcare/styles.scss';
@import '../../components/info-box/styles.scss';
@import '../../components/scrolltop/styles.scss';
@import '../../components/accessibility-nav/styles.scss';
@import '../../components/errorStatic/styles.scss';
@import '../../components/cookie-panel/styles.scss';
@import '../../components/reading-aid/styles.scss';
@import '../../components/spinner/styles.scss';
@import '../../components/pregnancy/styles.scss';
@import '../../components/forms/styles.scss';
@import '../../components/chapter-container/styles.scss';
@import '../../components/image-gallery/styles.scss';
@import '../../components/subject-intro/styles.scss';
@import '../../components/ao-list/styles.scss';
@import '../../components/search-link-list/styles.scss';
@import '../../components/list-container/styles.scss';
@import '../../components/subscription-link/styles.scss';
@import '../../components/comment-form/styles.scss';
@import '../../components/content-history/styles.scss';
@import '../../components/keyword-hints/styles.scss';
@import '../../components/find-care-links/styles.scss';
@import '../../components/error404-linklist/styles.scss';
@import '../../components/facets/styles.scss';
@import '../../components/highlight/styles.scss';
@import '../../components/printsidebar/styles.scss';
@import '../../components/description-list/styles.scss';
@import '../../components/anchornav-horizontal/styles.scss';
@import '../../components/media-gallery/styles.scss';
@import '../../components/typeahead/styles.scss';
@import '../../components/video-container/styles.scss';
@import '../../components/find-aid/styles.scss';
@import '../../components/find-aid/productlist.scss';
@import '../../components/info-portal/styles.scss';
@import './base/tables.scss';
@import './base/utils.scss';
@import '../../platformui/Framework/print';

/*default styles for react-calendar*/
@import '../../../node_modules/react-date-picker/dist/DatePicker.css';

/*Pages*/
@import '../../pages/imports.scss';

.main,
.c-alertlist,
.c-footer,
.c-reading-aid,
.regions {
  opacity: 1;
}

//Global placeholder text color
::placeholder {
  color: c('stone-base');
}

.large-uncentered:last-child:not(:first-child) {
  @include breakpoint(large) {
    position: static;
    float: right;
    margin-right: 0;
    margin-left: 0;
    clear: none;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}
