@mixin CurrentInfo(
  $backgroundColor: transparent,
  $linkColor: inherit,
  $margin: 10px 7px 0
) {
  .current-info {
    background-color: rgba($backgroundColor, 0.1);
    .contact-card__button.contact-card__button {
      height: auto;
      flex-direction: row;
    }
    &__publishdate {
      font-size: 16px;
      font-style: italic;
      display: block;
      margin: 0 0 10px 0;

      @media print {
        display: none;
        font-size: 8px;
        margin: 10px 0 0 0;
      }
    }
    &__publishdate__content {
      padding-bottom: 20px;
    }
    .main-info__container {
      margin-bottom: 16px;
    }
  }
  @content;
}
