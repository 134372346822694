.regionlabel {
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #e9e9e9;
  font-size: rem-calc(14);
  display: inline-block;
  padding: 10px 12px;
  @include breakpoint(large) {
    position: absolute;
    top: rem-calc(30);
  }
}
