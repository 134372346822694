@import './findcare-header.scss';
@import './findcare-form.scss';
@import './findcare-searchfield.scss';
@import './findcare-dropdown.scss';
@import './findcare-search-list-item.scss';
@import './findcare-search-modal.scss';
@import './findcare-totalhits.scss';
@import './findcare-sort.scss';
@import './findcare-map.scss';
@import './findcare-categories-lists.scss';
@import './findcare-region-search-field.scss';

.findcare-zerohits__body {
  margin-bottom: 70px;
}
