@mixin FindCare-Dropdown(
  $className: 'findcare-dropdown',
  $borderColor: #000,
  $lightGrey: #ccc,
  $muted: #000,
  $black: #000,
  $white: #fff,
  $cta: #000
) {
  .#{$className} {
    height: 50px;
    margin-bottom: 10px;
    position: relative;

    @include breakpoint(medium) {
      height: 60px;
      margin-bottom: 0;
      margin-right: 10px;
    }

    &__trigger {
      @include base-font-family();
      background: $lightGrey;
      border-radius: 10px;
      border: solid 1px $borderColor;
      cursor: pointer;
      font-size: rem-calc(16);
      height: 100%;
      padding: 8px 20px;
      position: relative;
      text-align: left;
      white-space: nowrap;
      width: calc(100vw - 40px);
      z-index: 1;

      &:focus {
        outline: none;

        span {
          outline: 1px dotted $muted;
          outline-offset: 4px;
        }
      }

      @include breakpoint(medium) {
        width: 260px;
      }

      &--has-value {
        color: $black;
        font-style: normal;
      }

      &:before {
        content: '';
        position: absolute;
        top: 21px;
        right: 20px;
        height: 9px;
        width: 2px;
        border-radius: 2px;
        background-color: $cta;
        transform: rotate(45deg);
        transition: transform 0.2s ease-in;

        @include breakpoint(medium) {
          top: 26px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 21px;
        right: 25px;
        height: 9px;
        width: 2px;
        border-radius: 2px;
        background: $cta;
        transform: rotate(-45deg);
        transition: transform 0.2s ease-in, left 0.2s ease-in;

        @include breakpoint(medium) {
          top: 26px;
        }
      }

      &--is-expanded {
        &:before {
          transform: rotate(135deg);
        }

        &:after {
          transform: rotate(-135deg);
        }
      }

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 20px;
      }
    }

    &__panel {
      background: $white;
      box-shadow: 0 0 6px 0 rgba($black, 0.3);
      border-radius: 10px;
      display: none;
      overflow: hidden;
      padding: 10px;
      position: absolute;
      top: calc(100% + 3px);
      width: 100%;
      z-index: 20;

      @include breakpoint(medium) {
        top: calc(100% - 5px);
        width: 260px;
      }

      &:focus {
        outline: none;
      }

      &--is-open {
        display: block;
      }

      &__header {
        border-bottom: solid 1px rgba($black, 0.2);
        margin-bottom: 10px;
        padding: 10px;
      }

      &__heading {
        display: block;
        font-size: rem-calc(16);
      }

      &__criteria {
        display: block;
        font-size: rem-calc(16);
        font-weight: bold;
        overflow: hidden;
      }

      &__footer {
        @include base-font-family();
        background: transparent;
        cursor: pointer;
        display: block;
        font-size: rem-calc(16);
        padding: 8px 10px;
        position: relative;
        text-align: left;
        width: 100%;

        @include icon('e820');

        &:before {
          color: $cta;
          line-height: 21px;
          font-size: rem-calc(10);
          position: absolute;
          right: 20px;
        }

        &:hover,
        &:focus {
          background: $lightGrey;
        }

        &:focus {
          outline: 2px solid $muted;
          outline-offset: 2px;
        }
      }
    }

    &__list {
      border-bottom: solid 1px rgba($black, 0.2);
      margin-bottom: 10px;
      padding-bottom: 10px;

      &__item {
        input {
          left: -100px;
          position: absolute;
        }

        label {
          cursor: pointer;
          display: block;
          padding: 8px 10px;

          &:hover {
            background: $lightGrey;
          }
        }

        &--selected-label {
          background: $lightGrey;
        }
      }

      &__all {
        position: absolute;

        input {
          position: absolute;
          left: -100px;
        }
      }
    }
  }
}
