@mixin InfoVisitor() {
  .info-visitor {
    .unit__section:first-of-type {
      .contact-subheading {
        border-top: none;
      }
    }
    .schedulelist {
      &__list {
        &__container {
          padding: 0px 8px 20px;
        }
      }
    }
    p + p {
      margin-top: 1rem;
    }
    @content;
  }
}