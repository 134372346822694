@import '../../platformui/Footer/styles.scss';
@import '../../platformui/Framework/platform-mixins/CookieProSettings.scss';

@import './footer-linklist.scss';

@mixin Curve(
  $color: blue,
  $url: (
    '../../framework/images/footer-small.svg',
    '../../framework/images/footer-large.svg',
  )
) {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    background: transparent;
    background-image: url(nth($url, 1));
    background-size: 100% 20px;
    top: -20px;
    width: 100%;
    height: 20px;
    z-index: 1;

    @include breakpoint(large) {
      background-image: url(nth($url, 2));
      background-size: 100% 30px;
      height: 30px;
      top: -29px;
    }
  }
}

@include Footer(
  $color: $berry,
  $padding: (
    20px 0 25px,
    20px 0 20px,
    40px 0 35px,
  ),
  $boxShadowSite: (
    inset 0 2px 4px 0 rgba($black, 0.2),
    none,
  ),
  $logoSiteFooterRight: false,
  $logoSiteFooterTop: false
) {
  margin-top: 30px;
  box-shadow: none;
  @include Curve($color: $white);

  &__content {
    background: $faded-pink;

    &__editor {
      @include breakpoint(large) {
        margin-bottom: 50px;
      }

      p,
      a {
        font-size: rem-calc(16);
        line-height: 1.5;

        @include breakpoint(large) {
          font-size: rem-calc(18);
        }
      }
    }

    &__inner {
      @include secondary-font-family();
      margin-bottom: rem-calc(20);

      + .row {
        margin-bottom: rem-calc(50);
      }
    }
  }
  &__heading {
    color: $lipstick;
    font-weight: bold;
    padding-right: 5px;
  }

  &__subheading {
    color: $wine-red;
    font-weight: bold;
  }

  &__heading,
  &__subheading {
    font-size: rem-calc(24);
    display: inline;
    line-height: 1.25;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 30px;

    &__text {
      text-align: center;
      color: $white;

      p {
        font-size: 0.875rem;

        a {
          color: $white;
        }
      }

      @include breakpoint(large) {
        text-align: left;
      }
    }

    img {
      height: 24px;
      width: 60px;
    }
  }

  &__site {
    background: $lipstick;
    min-height: rem-calc(100);
    padding-bottom: rem-calc(40);

    @include breakpoint(large) {
      min-height: rem-calc(20);
      padding-bottom: rem-calc(15);
      @include Curve(
        $color: $faded-pink,
        $url: (
          '../../framework/images/footer-small-secondary.svg',
          '../../framework/images/footer-large-secondary.svg',
        )
      );
      display: block;
    }
    @include CookieProSettings($color: $white, $focusColor: $white);

    &__inner {
      &__bottom-links {
        @include breakpoint(medium) {
          text-align: center;
          max-width: none;
          margin-left: auto;
          margin-right: auto;
          padding-left: 1.25rem;
          padding-right: 1.25rem;
          li {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__error-logo {
    height: 56px;
    margin: 40px auto 50px;
    width: 150px;
    display: block;
    object-fit: contain;
  }

  &__collapsible-lists {
    a {
      color: $berry;
      font-size: rem-calc(14);
    }
  }

  .c-collapsible-panel__button {
    margin: 0;
  }
}
