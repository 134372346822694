@import '../../Mobilenav/expand-button/styles';

@mixin ContactCardPanel(
  $contentBorderTop: black,
  $borderColor: black,
  $breakpointColor: blue,
  $focusColor: blue,
  $focusOffset: 0,
  $headingColor: blue,
  $headingBreakpointColor: blue,
  $headingBreakpointFontWeight: 700,
  $contactCardMargin: 15px
) {
  .contact-card {
    border-radius: 10px;
    position: relative;
    margin-bottom: 0;
    height: 70px;
    padding-bottom: 1px;

    @include breakpoint(large) {
      box-shadow: 0 2px 4px 0 rgba($black, 0.3);
      height: 90px;
      margin-bottom: 20px;
    }

    &--open {
      height: auto;
    }

    &__inner {
      border-radius: 10px;

      @include breakpoint(large) {
        overflow: hidden;
      }
    }

    &__content {
      &__heading {
        margin: 0;
        width: 100%;
      }
      @include breakpoint(large) {
        border-top: 5px solid $contentBorderTop;
      }

      @media print {
        border: none;
      }

      &__inner {
        display: none;
        opacity: 0;
        transition: opacity 0.1s ease-in;
        margin: 0;

        .contact-card__item__label {
          font-weight: bold;
        }

        @include breakpoint(large) {
          margin: 0 20px;
        }

        @media print {
          display: block;
          margin: 0;
          opacity: 1;
        }

        .contact-card--open & {
          display: block;
        }

        &.animation {
          opacity: 1;
        }
      }
    }

    &__button-container {
      height: 80px;
      display: flex;
      align-items: center;
      margin: 0;

      .plus--subheading {
        display: block;
        position: absolute;
        right: 0;
      }
      @include breakpoint(large) {
        margin: 0 20px;
      }

      @media print {
        height: unset;
        margin: 0;
      }

      .contact-card--open & {
        margin-top: $contactCardMargin;

        @include breakpoint(large) {
          height: auto;
        }

        @media print {
          margin: 0;
        }
      }
    }

    &__button {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      width: 100%;
      background-color: transparent;
      cursor: pointer;
      position: relative;
      border-bottom: 1px solid rgba($borderColor, 0.7);

      @include breakpoint(large) {
        border: none;
        height: 50px;
      }

      @media print {
        border-bottom: 1px solid rgba(159, 159, 159, 0.7);
        display: block;
        padding: 0;
        text-align: left;
      }

      @include focus-handler($color: $focusColor, $offset: $focusOffset);

      .contact-card--open & {
        @include breakpoint(large) {
          border-bottom: 1px solid rgba($borderColor, 0.7);
        }

        @media print {
          display: block !important;
          text-align: left;
          height: 35px;
        }
      }
    }

    &__heading {
      margin: 0;
      font-size: rem-calc(22);
      color: $headingColor;
      font-weight: bold;
      line-height: 28px;
      text-align: left;

      @include breakpoint(large) {
        font-size: rem-calc(20);
        font-weight: 400;
        line-height: 24px;
        color: $breakpointColor;
        text-transform: uppercase;
      }

      @media print {
        font-size: rem-calc(10);
      }
    }

    &.subheading {
      border-radius: 0;
      box-shadow: none;
      height: auto;
      border-bottom: 1px solid rgba($borderColor, 0.7);
      padding-bottom: 10px;

      @include breakpoint(large) {
        padding-bottom: 0;
        border: none;
        margin-bottom: 0;

        .contact-card__button {
          flex-direction: row;
        }
      }

      @media print {
        .offering & {
          border-top: 1px solid rgba($borderColor, 0.7);
        }
      }

      &.is-last {
        border: none;

        .contact-card {
          &__button-container {
            border-bottom: none;
          }
        }
      }

      &.contact-card--open {
        .contact-card {
          &__button-container {
            border-bottom: none;
          }
        }
      }

      .contact-card {
        &__content {
          border-top: none;

          &__inner {
            display: none;
            opacity: 0;
            margin: 0 7px;

            &.animation {
              opacity: 1;
              display: block;
              border-bottom: none;
              @include breakpoint(large) {
                border-bottom: 1px solid rgba($borderColor, 0.7);
              }
            }

            ul {
              margin: 0 0 20px;
            }

            @media print {
              opacity: 1;
              display: block;
            }
          }
        }

        &__heading {
          font-size: rem-calc(20);
          color: $black;
          text-align: left;
          width: 100%;

          @include breakpoint(large) {
            color: $headingBreakpointColor;
            font-weight: $headingBreakpointFontWeight;
            text-transform: none;
            width: auto;
          }

          @media print {
            color: $black;
            font-size: 10px;
            left: 0;
            position: absolute;
            top: 0;
          }
        }

        &__button-container {
          margin: 20px 0 10px 0;
          height: auto;

          @include breakpoint(large) {
            margin: 0;
            height: 90px;
            border-bottom: 1px solid rgba($borderColor, 0.7);
          }

          @media print {
            border: none;
            height: 20px;
            margin-top: 10px;
          }
        }

        &__button {
          height: 100%;
          padding-right: 0;

          @media print {
            border: none;
            height: 20px;
          }

          .contact-card__icon {
            display: none;

            @include breakpoint(large) {
              display: block;
            }
          }

          .contact-card--open & {
            border-bottom: none;
          }
        }
      }
    }

    @media print {
      box-shadow: none;
      height: auto;
      margin-bottom: 0;
      margin-top: 10px;

      &#eservice,
      &#readmore {
        display: none;
      }

      /*page-break-inside: avoid;*/
    }
    @content;
  }
}
