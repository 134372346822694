@mixin FindCare-Header($className: 'findcare-header') {
  .#{$className} {
    display: block;
    text-align: center;

    h1 {
      margin-bottom: 10px;
    }

    &__preamble {
      font-size: rem-calc(24);
      font-weight: 300;
    }
  }
  @content;
}
