@import '../../platformui/CollapsiblePanel/styles.scss';
@import '../../platformui/Framework/platform-mixins/Chevron.scss';

@include CollapsiblePanel(
  $btnBoxShadow: 0 -4px 6px 0 rgba($black, 0.3),
  $iconContainerBg: rgba($white, 0.1),
  $iconContainerTop: 0,
  $iconContainerRight: 0,
  $iconContainerWidth: 40px,
  $iconPosition: 50%,
  $outline: 1px dotted $white,
  $outlineOffset: -1px
) {
  &__button {
    background: $lipstick;
    border: none;
    box-shadow: inset 0 2px 4px 0 rgba($black, 0.2);
    @include focus-handler($color: $white, $offset: -2px);

    &__inner {
      align-items: center;
      color: $white;
      display: flex;
      font-size: rem-calc(16);
      font-weight: bold;
      justify-content: space-between;
      line-height: 1.5;
      padding: 7px 20px;
      @include secondary-font-family();
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &__icon-container {
      background: none;
      height: 40px;
      position: relative;
      width: 40px;

      &:focus {
        outline: none;
      }
    }

    @include Chevron(
      $className: c-collapsible-panel__button__icon,
      $chevronColor: $white,
      $height: 40px,
      $top: 13px
    ) {
      background-color: transparent;
      bottom: 0;
      left: 0;
      position: absolute;
      top: 0;

      &:focus {
        outline: none;
      }
    }
  }
}
