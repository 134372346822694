@import '../../platformui/SearchBlock/styles';
@import '../../platformui/Framework/platform-mixins/HeaderToolsPanel';

$open: '.c-globalsearch--is-opened';

.c-globalsearch {
  background: $white;
  display: none;
  height: 0;
  position: relative;
  transition: height 0.2s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 70;

  .icon-search:before {
    @include breakpoint(small only) {
      content: none;
    }
    @include breakpoint(medium only) {
      content: none;
    }
  }

  @include breakpoint(large) {
    box-shadow: 0 0 10px 0 rgba($black, 0.3);
    transition: none;
  }

  &__inner {
    margin: 0 auto;
    max-width: 80rem;
    opacity: 0;
    padding: 25px 10px;
    transition: opacity 0.2s linear 0.1s;

    @include breakpoint(large) {
      padding: 40px 10px;
    }

    #{$open} & {
      opacity: 1;
    }
  }

  &__form-component {
    padding-right: 60px;
    position: relative;

    @include breakpoint(large) {
      padding-right: 87px;
    }
  }

  &__field-container {
    width: 100%;
  }

  &--is-opened {
    height: 90px;
    visibility: visible;

    @include breakpoint(large) {
      height: 140px;
    }
  }

  &--is-mobile {
    display: block;

    @include breakpoint(large) {
      display: none;
    }
  }

  &--is-desktop {
    display: none;

    @include breakpoint(large) {
      display: block;

      left: 0;
      position: absolute;
      right: 0;
      top: -140px;
    }
  }
}

@include SearchField(
  $className: c-globalsearch,
  $searchFieldLabel: $denim,
  $buttonBg: $denim,
  $buttonColor: $white,
  $buttonBorderRadius: (
    0 10px 10px 0,
    10px,
  ),
  $buttonWidthLarge: 130px,
  $buttonHoverBg: $dusk
) {
  .c-globalsearch__field {
    &__button {
      font-size: rem-calc(16);
      font-weight: bold;
      height: 40px;
      letter-spacing: -0.3px;
      text-transform: uppercase;

      &:hover,
      &:focus {
        box-shadow: 0 3px 8px 0 rgba($black, 0.3);
      }

      @include focus-handler();

      &:before {
        border-top: 10px solid $white;
        border-bottom: 10px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: '';
        filter: drop-shadow(-1px 3px 2px rgba($black, 0.15));
        height: 0;
        pointer-events: none;
        position: absolute;
        right: 4px;
        top: 62px;
        width: 0;
      }

      @include breakpoint(large) {
        font-size: rem-calc(24);
        height: 60px;
        letter-spacing: -0.5px;
        width: 194px;

        &:before {
          border-top: 16px solid $white;
          border-bottom: 16px solid transparent;
          border-left: 16px solid transparent;
          border-right: 16px solid transparent;
          right: 7px;
          top: 100px;
        }
      }
    }

    .icon-search {
      color: $denim;
    }
  }
}

@include SearchFieldQuery(
  $className: c-globalsearch,
  $searchFieldBg: $pale-grey,
  $borderRadius: (
    10px 0 0 10px,
    10px,
  ),
  $searchFieldFocusBorder: 1px solid $denim,
  $searchFieldFocusShadow: inset 0 5px 10px 0 rgba(black, 0.2)
) {
  .c-globalsearch__field__query {
    border: 1px solid $denim;
    font-size: rem-calc(18);
    height: 40px;
    padding-left: 20px;

    @include breakpoint(large) {
      height: 60px;
      padding-left: 56px;
      width: 87%;
    }

    @include focus-handler();

    &::placeholder {
      opacity: 1;
      color: $muted;
      font-style: italic;
    }
  }
}
