@import '../../platformui/Chapter/styles';
@import './toggle-chapter.scss';

@include Chapter($h2Color: c(grey-700), $h3h4Color: $black) {
  .c-chapter,
  .c-chapter-container,
  .c-chapter-container-collection {
    @include breakpoint(large) {
      h2 {
        color: $lipstick;
      }
    }
    .c-chapter {
      @include clearfix();
    }

    &__heading {
      &--is-anchornav {
        display: none;

        @include breakpoint(large) {
          display: inline-block;
        }
      }
      display: inline-block;
    }

    a {
      @include focus-handler($color: $muted, $offset: 2px);
    }

    @media print {
      page-break-inside: avoid !important;
      width: 95%;
      h2 {
        font-size: 20pt !important;
      }
      h3 {
        font-size: 18pt !important;
      }
      p,
      a,
      li {
        font-size: 14pt !important ;
      }
    }
  }
}
