@import '../../platformui/Spinner/styles';

.flex-center {
  .c-section__inner {
    display: flex;
    justify-content: center;
    margin-bottom: 96px;
  }
}

.find-aid {
  display: grid;
  gap: 2.5rem;
  max-width: 1048px;
  width: 100%;

  &__heading {
    color: c('main');
    font-size: rem-calc(24);
    font-weight: bold;
    margin-bottom: 2rem;
  }

  &__paragraph {
    margin: 0;
  }

  &__tag-container {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0.75rem;
    width: 100%;
    list-style-type: none;

    @include breakpoint(large) {
      &__tag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0.8438rem 1.875rem;
        border-radius: 1.875rem;
        border: 1px solid c('sky-base');
        background-color: c('sky-background');
        cursor: pointer;
        color: c('sky-base');
        text-transform: uppercase;
        text-align: center;
        font-size: rem-calc(14);
        font-weight: normal;
        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        @include secondary-font-family();
        @include focus-handler();

        &:hover,
        &:active,
        &.active {
          background-color: c('sky-dark');
          color: c('white');
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
        }

        @include breakpoint(medium) {
          width: fit-content;
        }
      }
    }
  }

  @include spinner($bgColor: $dusk, $margin: 0 auto) {
    .c-spinner {
      display: none;
      opacity: 0;
    }

    &--is-loading .c-spinner {
      display: block;
      opacity: 1;
      -webkit-transform: none;
      transform: none;
      width: 50px;

      span {
        background-color: $denim;
        width: 10px;
        height: 10px;
      }
    }
  }
}
