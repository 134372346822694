.find-aid-product-page {
  max-width: rem-calc(1047px);
  margin: 0 auto;
  margin-top: rem-calc(15px);

  h1 {
    padding-inline: rem-calc(20px);
  }

  &__container {
    display: flex;
    flex-direction: column-reverse;
    padding-inline: rem-calc(20px);

    &__produkt-information {
      width: 100%;
      padding-right: 0;
      word-wrap: break-word;
      min-width: 0;

      h2:first-of-type {
        margin-top: 0;
      }

      h2 {
        margin-top: 32px;
      }

      p {
        margin-bottom: 0;
      }

      p:last-of-type {
        margin-bottom: rem-calc(20px);
      }
    }

    &__produkt-image {
      justify-content: center;

      img {
        width: 100%;
        padding-bottom: rem-calc(20px);
      }
    }
  }

  @include breakpoint(large) {
    &__container {
      display: flex;
      flex-direction: row;
      padding-bottom: rem-calc(20px);
      padding-inline: rem-calc(20px);

      &__produkt-information {
        flex: 50%;
        min-width: rem-calc(515px);
        margin-right: rem-calc(60px);

        p:last-of-type {
          padding-bottom: rem-calc(12px);
        }
      }

      &__produkt-image {
        flex: 50%;
        display: flex;
        align-items: center;
        justify-content: left;

        img {
          max-width: rem-calc(432px);
        }
      }
    }
  }
}
