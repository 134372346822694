.unit {
  @media print {
    margin-top: 13px;
  }

  &__section {
    padding-bottom: rem-calc(30);

    @media print {
      display: inline-block;
      margin-bottom: rem-calc(10);
      width: 100%;
    }

    &.subsection {
      margin: rem-calc(10) 0 0 0;
    }

    &__content {
      margin: 0 rem-calc(10);

      @media print {
        margin: 0 0 10px 0;
      }

      .subsection & {
        margin: 0;
      }

      p {
        margin: 0;
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    &__img {
      @include breakpoint(medium) {
        float: right;
        max-width: rem-calc(300);
        padding: 0 0 rem-calc(20) rem-calc(20);
      }

      @media print {
        display: none;
      }
    }
  }
}
