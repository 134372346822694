@mixin FindCare-Region-search-field(
  $className: 'findcare-region-search-field',
  $errorMessageBackground: none
) {
  .#{$className} {
    position: relative;

    &__error {
      background: $white;
      background-image: $errorMessageBackground;
      background-repeat: no-repeat;
      background-size: 30px 30px;
      background-position: 16px 17px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba($berry, 0.5);
      color: $black;
      font-size: rem-calc(16);
      font-style: italic;
      padding: 20px 20px 20px 52px;
      position: absolute;
      top: 110px;
      width: 100%;
      z-index: 999;

      &:before {
        border-left: 16px solid transparent;
        border-bottom: 16px solid $white;
        border-right: 16px solid transparent;
        content: '';
        filter: drop-shadow(0 -4px 2px rgba($berry, 0.2));
        left: 30px;
        position: absolute;
        top: -15px;
      }
    }

    @content;
  }
} // mixin FindCare-Totalhits
