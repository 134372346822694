@import '../../platformui/FindCare/findcare-header/styles.scss';

@include FindCare-Header() {
  .findcare-header {
    @include breakpoint(small only) {
      text-align: left;

      &__preamble {
        font-size: rem-calc(18);
      }
    }
  }
} ;
