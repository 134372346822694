@mixin CardLinkList(
  $itemBgColor: $pale-grey,
  $linkColor: $denim,
  $linkHoverColor: $dusk
) {
  .card-link-list {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px;

    &--is-collapsible {
      .card-link-list__list-item {
        &:nth-child(n + 5) {
          @include breakpoint(small only) {
            display: none;
          }
        }

        &:nth-child(n + 11) {
          @include breakpoint(medium) {
            display: none;
          }
        }
      }

      &.card-link-list--is-open {
        .card-link-list__list-item {
          &:nth-child(n + 5) {
            @include breakpoint(small only) {
              display: flex;
            }
          }

          &:nth-child(n + 11) {
            @include breakpoint(medium) {
              display: flex;
            }
          }

          &.empty {
            @include breakpoint(small only) {
              display: none;
            }
          }
        }
      }
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &__list-item {
      background-color: $itemBgColor;
      display: block;
      float: left;
      padding: 10px;
      margin-bottom: 2px;
      min-height: 48px;
      width: 100%;

      @include breakpoint(medium) {
        margin-right: 2px;
        width: calc(50% - 1px);
        &.full-width {
          width: 100%;
        }

        &:nth-child(even) {
          margin-right: 0;
        }
      }

      @media print {
        background: none;
        list-style-type: none;
        min-height: 0;
        padding-left: 0;
      }

      &.empty {
        display: none;

        @include breakpoint(medium) {
          display: block;
        }
      }
    }

    &__link {
      display: inline-block;
      float: left;
      font-size: rem-calc(18);
      position: relative;
      color: $linkColor;
      text-decoration: none;
      padding-left: 25px;
      z-index: 2;
      max-width: calc(100% - 50px);

      @media print {
        color: $black;
        padding: 0;
      }

      @include icon('f105');
      @include focus-handler();

      &:before {
        position: absolute;
        font-size: rem-calc(19);
        left: 2px;
        top: 4px;

        @media print {
          content: none;
        }
      }

      span {
        float: left;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        width: 100%;
        @include breakpoint(small only) {
          white-space: unset;
          text-decoration: underline;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        &:hover {
          color: $linkHoverColor;
        }

        @media print {
          background: none;
          font-size: 8px;
        }
      }
    }
    @content;
  }
}
