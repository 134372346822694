@import '../../Mobilenav/expand-button/styles';

@mixin Eservices(
  $itemBgColor: $pale-grey,
  $linkColor: $denim,
  $focusHandlerColor: $muted,
  $toggleBtnColor: $denim,
  $toggleBtnHover: $dusk,
  $expandBtnBgColor: $denim
) {
  .e-services {
    border-radius: 10px;
    margin-top: 30px;

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
      columns: 1;

      @include breakpoint(medium) {
        columns: auto 2;
        column-gap: 2px;
      }
    }

    &__list-item {
      background-color: $itemBgColor;
      padding: 0;
      min-height: 48px;
      margin-bottom: 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      -webkit-column-break-inside: avoid;
      break-inside: avoid;
      page-break-inside: avoid;

      &.empty {
        display: none;

        @include breakpoint(medium) {
          display: block;
        }
      }
    }

    &__link {
      position: relative;
      color: $linkColor;
      text-decoration: none;
      padding-left: 35px;
      z-index: 2;
      max-width: calc(100% - 50px);

      @include icon('f105');

      &:before {
        position: absolute;
        font-size: rem-calc(19);
        left: 12px;
        top: 2px;
      }

      span {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__togglebtn-container {
      display: none;
      padding-bottom: 30px;

      &--is-gt-4 {
        @include breakpoint(small only) {
          display: block;
        }
      }

      &--is-gt-10 {
        @include breakpoint(medium) {
          display: block;
        }
      }
    }

    &__toggle-button {
      position: relative;
      z-index: 8;
      background: none;
      @include focus-handler($color: $focusHandlerColor);

      span {
        display: inline-block;
        margin-left: 24px;
        font-size: rem-calc(16);
        color: $toggleBtnColor;
        text-decoration: underline;

        &:hover {
          color: $toggleBtnHover;
        }
      }
    }
    @content;
  }

  @include ExpandButton(
    $className: e-services__toggle-icon,
    $topPosition: 3px,
    $bgColorBtn: $expandBtnBgColor,
    $btnHeight: 17px,
    $btnWidth: 17px,
    $btnBorderRadius: 100%,
    $lineThickness: 1px,
    $lineSize: 9px,
    $marginCentered: -5px
  ) {
    right: unset;
    left: 5px;
  }
}
