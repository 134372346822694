@import '../Framework/platform-mixins/Chevron.scss';

@mixin CommentForm(
  $primaryColor: darkblue,
  $primaryBorderColor: $black,
  $primaryFontColor: $black,
  $secondaryColor: green,
  $secondaryBorderColor: grey,
  $linkColor: blue,
  $linkHoverColor: lightblue,
  $focusColor: red,
  $backgroundColor: $white
) {
  .contact-card-comment {
    border: solid 1px $primaryBorderColor;
    border-radius: 10px;
    clear: both;
    margin-bottom: 0;
    margin-top: 60px;

    &--last {
      margin-bottom: 100px;
    }

    h2 {
      margin: 0;
    }
    &__header {
      background-color: $backgroundColor;
      border-radius: 10px;
      color: $primaryFontColor;
      cursor: pointer;
      font-size: rem-calc(16);
      font-weight: bold;
      margin: 0;
      padding: 13px 50px 13px 20px;
      position: relative;
      text-align: left;
      width: 100%;

      @include focus-handler();

      &--is-open {
        border-bottom: solid 1px $primaryBorderColor;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &__link {
        color: $linkColor;
        font-weight: normal;
        text-decoration: underline;

        &:hover {
          color: $linkColor;
        }
      }

      @include Chevron(
        $className: contact-card-comment__header__chevron,
        $chevronColor: $primaryBorderColor,
        $height: 50px,
        $leftLinePosition: calc(100% - 27px),
        $lineLength: 9px
      ) {
        background-color: transparent;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        @include focus-handler();
      }
    }

    &__content {
      height: 0;
      max-width: 625px;
      opacity: 0;
      overflow: hidden;
      transition: opacity 0.6s ease-in-out;
      visibility: hidden;

      a {
        @include focus-handler($color: $focusColor, $offset: 2px);
      }
      &__footer-text {
        a {
          min-height: 24px;
        }
        margin-top: 35px;
        p {
          color: $primaryFontColor;
          font-size: rem-calc(14);
        }
        p:last-child {
          margin-bottom: 0;
        }
      }
      &--is-open {
        height: auto;
        opacity: 1;
        padding: 20px;
        visibility: visible;

        @include breakpoint(medium) {
          padding: 30px;
        }
      }

      &__footer {
        clear: both;
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: row-reverse;

        @include breakpoint(small only) {
          justify-content: space-between;
        }
        @include breakpoint(medium) {
          text-align: right;
        }
      }

      &__text {
        margin-bottom: 35px;

        &__content {
          height: 97px;
          margin-bottom: 20px;
          overflow: hidden;
          position: relative;

          &:before {
            background-image: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0),
              $white
            );
            bottom: 0;
            content: '';
            display: block;
            height: 34px;
            position: absolute;
            width: 100%;
          }

          p:last-child {
            margin-bottom: 0;
          }
        }

        &--expanded {
          .contact-card-comment__content__text__content {
            height: auto;

            &:before {
              display: none;
            }
          }
        }

        &__button {
          background: transparent;
          color: $primaryColor;
          cursor: pointer;
          font-size: rem-calc(16);
          padding: 0 0 0 30px;
          position: relative;

          @include focus-handler();

          &__inner {
            border-bottom: solid 0.5px $secondaryBorderColor;
          }
        }
      }
    }

    .contact-card-comment-link-list {
      button {
        cursor: pointer;
        &:before {
          top: 0;
        }
      }

      &--is-hidden {
        display: none;
      }

      &__list {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      &__title {
        margin-bottom: 18px;
        @include breakpoint(small only) {
          padding-left: 9px;
        }
      }

      &__list-item {
        background-color: $secondaryColor;
        display: block;
        padding: 10px;
        margin-bottom: 2px;
        min-height: 48px;
        margin-right: 2px;
        width: 100%;
        cursor: pointer;

        &__link {
          display: inline-block;
          font-size: rem-calc(18);
          position: relative;
          color: $linkColor;
          background-color: transparent;
          text-decoration: none;
          padding-left: 25px;
          z-index: 2;
          max-width: calc(100% - 50px);

          @media print {
            color: $black;
            padding: 0;
          }

          @include focus-handler();

          &:before {
            position: absolute;
            font-size: rem-calc(19);
            left: 2px;
            top: 4px;

            @media print {
              content: none;
            }
          }

          span {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-decoration: underline;

            &:hover {
              color: $linkHoverColor;
            }

            @media print {
              background: none;
              font-size: 8px;
            }
          }
        }
      }
    }

    &__disclaimer {
      clear: both;
      text-align: left;

      p {
        color: $primaryFontColor;
        font-size: rem-calc(14);
      }
    }

    .c-forms__input,
    .c-forms__textarea {
      clear: both;
    }

    .c-forms__button {
      position: relative;
      display: inline-block;
      background: $primaryColor;
      border-radius: 100px;
      color: $white;
      cursor: pointer;
      font-size: rem-calc(20);
      font-weight: bold;
      padding: 13px 20px;
      width: 124px;
      height: 50px;
      font-size: rem-calc(18);
      text-transform: uppercase;
      @include breakpoint(medium) {
        margin-left: 12px;
        width: 137px;
        font-size: rem-calc(20);
      }
      &:hover {
        background: $linkHoverColor;
        box-shadow: 0 3px 8px 0 rgba(30, 30, 30, 0.3);
        outline: none;
      }
      &:focus {
        outline: 2px solid $focusColor;
        outline-offset: -2px;

        @include breakpoint(large) {
          outline-offset: 4px;
        }
      }

      .c-forms__button__text {
        opacity: 1;
        transition: opacity 0.2s ease;
      }

      .c-spinner {
        opacity: 0;
        transition: opacity 0.2s ease;
      }

      &--is-loading {
        .c-spinner {
          opacity: 1;
        }
        .c-forms__button__text {
          opacity: 0;
        }
      }

      &--secondary {
        display: inline-block;
        background-color: $white;
        border: 1px solid $secondaryBorderColor;
        border-radius: 30px;
        color: $primaryColor;
        width: 124px;
        padding: 13px 20px;
        font-size: rem-calc(18);
        @include breakpoint(medium) {
          width: 137px;
          font-size: rem-calc(20);
        }
        &:hover {
          background: $secondaryColor;
          box-shadow: 0 3px 8px 0 rgba(30, 30, 30, 0.3);
          outline: none;
        }
      }
    }

    label {
      display: block;
      margin-bottom: 10px;
    }

    &__protected {
      display: none;
    }

    &__success {
      &__close {
        color: $primaryColor;
        font-size: rem-calc(16);
        margin-bottom: 30px;
        padding: 0;
        text-decoration: underline;
      }
    }

    @media print {
      display: none;
    }
    @content;
  }

  @include ExpandButton(
    $className: contact-card-comment__content-expand,
    $expandedClassModifier: '.contact-card-comment__content__text--expanded',
    $topPosition: 3px,
    $bgColorBtn: $primaryColor,
    $btnHeight: 17px,
    $btnWidth: 17px,
    $btnBorderRadius: 100%,
    $lineThickness: 1px,
    $lineSize: 9px,
    $marginCentered: -5px
  ) {
    right: unset;
    left: 5px;
  }
}
