@mixin Progressbar(
  $label-color: black,
  $border-color: red,
  $text-position: unset
) {
  .progressbar {
    display: flex;
    justify-content: center;
    align-items: center;

    .progress {
      transform: rotate(-90deg);

      &__meter,
      &__value {
        fill: none;
      }

      &__meter {
        stroke: #f9e1e3;
      }

      &__text {
        position: absolute;
        font-size: rem-calc(24);
        font-weight: bold;
        font-family: $secondary-font;
      }
    }

    .progress__value {
      stroke: $border-color;
      stroke-linecap: round;
    }
    @content;
  }
}
