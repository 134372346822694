@import '../../platformui/ArticleHeader/styles';
@import '../../platformui/ArticleHeader/subject-icon';

@include ArticleHeader($headingColor: false, $paddingTop: 30px) {
  .c-articleheader {
    @include breakpoint(large) {
      padding-top: 20px;
    }

    &--has-regionlabel {
      @include breakpoint(large) {
        padding-top: 70px;
      }
    }
    &--alert {
      &:before {
        background: url('../../framework/images/alert-red.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content: '';
        display: block;
        height: 100px;
        margin: 0 auto 27px;
        width: 100px;
      }
    }

    &__subject {
      color: $denim;
      display: block;
      text-decoration: none;

      font-size: rem-calc(14);
      @include SubjectIcon($iconColor: $denim);
      @include focus-handler($offset: 2px);

      @include breakpoint(large) {
        display: none;
      }

      &--hidden {
        display: none;
      }
    }

    &__translation {
      color: $black;
      display: block;
      font-size: rem-calc(16);
      font-style: italic;
      margin-bottom: 20px;
      margin-top: -20px;

      [dir='rtl'] & {
        text-align: right;
      }
    }
  }
}
