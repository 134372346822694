// * LIST WITH ALL TYPES, ADD WHEN DESIGN DELIVER ICONS, PAY ATTENTION TO NAMING CONVENTION *

//$informationTypesList: article, treatment, faq, drug, news, story, disease, examination;

$informationTypesList: faq;

.information-type {
  @each $type in $informationTypesList {
    &--#{$type} {
      .information-type__icon {
        &:before {
          background-image: url('../../framework/images/information-types/information-type--#{$type}.svg');
          background-size: contain;

          @media print {
            content: none;
          }
        }
      }
    }
  }

  &__icon {
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc(1380);
    position: relative;
    width: 100%;
    z-index: 2;

    &:before {
      background-repeat: no-repeat;
      content: '';
      display: none;
      height: 110px;
      left: 50px;
      position: absolute;
      top: 29px;
      width: 110px;
      z-index: 0;

      @include breakpoint(large) {
        display: block;
      }
    }
  }
}
