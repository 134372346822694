.reading-aid-block {
  margin: 40px 0;

  @media print {
    display: none;
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;

    button {
      background: $denim;
      border-radius: 25px;
      color: $white;
      cursor: pointer;
      font-family: $secondary-font;
      font-size: rem-calc(20);
      font-weight: 700;
      height: 50px;
      margin: 20px 0;
      max-width: 450px;
      position: relative;
      text-transform: uppercase;
      width: 100%;

      &:hover,
      &:focus {
        background: $dusk;
        outline: none;
      }
    }
  }
}
