@mixin FindCare-Form(
  $className: 'findcare-form',
  $borderColor: #000,
  $buttonBg: $denim,
  $lightGrey: #ccc,
  $muted: #000,
  $white: #fff,
  $buttonRadius: 10px
) {
  .#{$className} {
    & + .c-alert {
      margin-bottom: 40px;
    }

    @include breakpoint(medium) {
      margin: 40px 0 0;
    }

    * {
      @include focus-handler($muted);
    }

    @include breakpoint(medium) {
      border-radius: 10px;
      border-top: 5px solid $borderColor;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
      margin: 40px 0;
    }
    padding: 0 0 10px 0;

    form {
      background: $white;

      @include breakpoint(medium) {
        display: flex;
        padding: 30px 40px 20px 40px;
      }
    }
    &__button {
      @include secondary-font-family();

      background: $buttonBg;
      border-radius: $buttonRadius;
      border: solid 1px transparent;
      color: $white;
      cursor: pointer;
      font-size: rem-calc(24);
      font-weight: 500;
      height: 50px;
      letter-spacing: -0.4px;
      position: relative;
      text-transform: uppercase;
      width: 100%;
      z-index: 10;

      @include breakpoint(medium) {
        width: 120px;
        height: 60px;
      }

      &:hover,
      &:focus {
        box-shadow: 0 3px 8px 0 rgba($black, 0.3);
        background: $borderColor;
        outline: none;
      }

      &--is-disabled {
        background: $white;
        border: solid 1px $lightGrey;
        color: $muted;
        cursor: not-allowed;

        &:hover,
        &:focus {
          background: $white;
          border: solid 1px $lightGrey;
          color: $muted;
        }
      }
    }

    &__field {
      &__content {
        & > div {
          display: block;

          @include breakpoint(medium) {
            display: flex;
          }
        }

        .findcare-region-search-field {
          @include breakpoint(medium) {
            margin-bottom: 0;
            margin-right: 10px;
            width: calc(100% - 20px);
          }
        }
      }

      &--region {
        @include breakpoint(large) {
          width: 37%;
        }
      }

      &--q {
        margin-bottom: 20px;
        @include breakpoint(large) {
          width: 63%;
          margin-bottom: 0;
        }
      }
    }

    &__fieldset {
      width: 100%;

      @include breakpoint(large) {
        margin: 0;
      }
    }

    &__label {
      @include secondary-font-family();

      color: $muted;
      display: block;
      font-size: rem-calc(14);
      letter-spacing: 1.4px;
      line-height: 20px;
      margin: 20px 0 10px 0;
      text-transform: uppercase;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    &__wrapper {
      @include breakpoint(large) {
        display: flex;
        align-items: flex-end;
      }
    }
  }
  @content;
}
