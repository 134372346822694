@import '../../../platformui/Contact-card/phone-hours/styles.scss';
@include PhoneHours(
  $containerBorder: 1px solid c('stone-line'),
  $containerPadding: 20px 6px
) {
  &__numbers {
    &__list {
      &__item {
        &__link {
          font-size: 1.25rem;
          width: fit-content;
          padding: 10px 15px 10px;
          border-radius: 50px;
          border: 1px solid c('cta');
          text-decoration: none;

          &--no-link {
            border: none;
            padding-left: 0px;

            &:before {
              content: none;
            }
          }

          @include breakpoint(large) {
            text-decoration: underline;
            border: none;
            width: 100%;
            padding: 0px;

            &--no-link {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
} ;
