@import '../../platformui/SearchLinkList/styles';
@import '../../platformui/SearchLinkList/item-list.scss';
@import '../../platformui/SearchLinkList/item.scss';
@import '../../platformui/Buttons/button.scss';

@include SearchLinkList($borderColor: $muted, $headingColor: $muted) {
  border-bottom: 1px solid $muted;

  &:before {
    content: '';
    background-color: $white;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 113px;

    @include breakpoint(medium) {
      top: 127px;
    }
  }
  &__btn {
    @include focus-handler();
  }
  .c-pagination {
    .c-spinner {
      top: 11px;
      span {
        width: 14px;
        height: 14px;
      }
    }
  }
}
@include LinkItemList($borderColor: $denim, $spanColor: $denim) {
  @include breakpoint(medium) {
    margin: 20px 0 0;
  }

  &__link {
    @include focus-handler();
  }

  &__ctrls {
    margin: 10px 0 0;
  }
}
@include SearchLinkItem(
  $borderColor: $denim,
  $oddBgColor: $pale-grey,
  $color: $denim
) {
  &__link {
    @include focus-handler();
    @include icon('E820');
  }
}

@include button(
  $bg: $white,
  $border: 1px solid $denim,
  $border-radius: 25px,
  $color: $denim,
  $className: 'link-item-list__btn',
  $hoverBg: $pale-grey,
  $hoverColor: $denim
) {
  display: block;
  font-size: rem-calc(14);
  font-weight: bold;
  margin-bottom: 30px;
  text-decoration: none;

  @include breakpoint(large) {
    display: inline-block;
    margin-bottom: unset;
  }

  @include WithSectionWrapper() {
    height: 40px;
    min-width: 300px;
  }
}
