@import '../../platformui/AnchorNavHorizontal/styles';

@mixin link-arrow(
  $color: $denim,
  $hoverColor: $denim,
  $top: 16px,
  $left: 2px,
  $fontSize: 0.625rem,
  $bgSize: rem-calc(20),
  $bgTop: $top - 4px,
  $paddingLeft: 25px,
  $useHoverCircle: false,
  $iconContent: 'e820',
  $rotate: 0deg,
  $bgColor: transparent
) {
  padding-left: $paddingLeft;
  position: relative;

  [dir='rtl'] & {
    padding-left: 0;
    padding-right: $paddingLeft;
  }

  @include icon($iconContent);

  &:before {
    color: $color;
    left: $left;
    font-size: 0.625rem;
    position: absolute;
    top: 7px;
    z-index: 2;

    [dir='rtl'] & {
      left: auto;
      right: 8px;
      transform: scaleX(-1);
    }
  }
  @if $useHoverCircle == true {
    &:after {
      background: $color;
      border-radius: 100%;
      content: '';
      display: block;
      height: $bgSize;
      left: 0;
      opacity: 0;
      position: absolute;
      top: $bgTop;
      width: $bgSize;
      z-index: 1;

      [dir='rtl'] & {
        left: auto;
        right: 0;
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    &:before {
      color: $hoverColor;
    }
    &:after {
      opacity: 1;
    }
  }
  @content;
}

@include AnchorNavHorizontal(
  $backgroundColor: $white,
  $fontFamily: $base-font,
  $mobileExpandButtonBorderBg: $lipstick,
  $mobileExpandButtonIcon: 'f107',
  $mobileExpandButtonIconColor: $denim,
  $mobileExpandButtonIconBg: $white,
  $mobileHeadingFont: $secondary-font,
  $activeLinkIconBg: $denim,
  $activeLinkIconColor: $white,
  $linkArrowColor: $denim,
  $linkArrowBg: $white,
  $borderColor: $neutral40,
  $borderWidth: 1px,
  $fontColor: $denim,
  $headingFontColor: $black,
  $fontSize: rem-calc(18),
  $fontWeight: 400,
  $mobileExpandButtonBg: $white,
  $borderRadius: 10px,
  $iconFontSize: 30px,
  $iconPaddingRight: 17,
  $fontWeightHeader: bold,
  $marginSmallerScreens: 15px,
  $smallerScreensWidth: auto,
  $smallerScreensBorderWidth: 0.3px solid $neutral40,
  $smallerScreensBorderRadius: 0px,
  $transitionsType: all 0.1s ease,
  $transformOrigin: 0% 50%,
  $paddingLink: 15px 20px 15px 20px,
  $minWidthLink: auto,
  $marginBlock: rem-calc(50),
  $fixedNavBGColor: $white,
  $textFillColor: none
) {
}
