.no-padding {
  .c-section__inner {
    padding: 0;
  }
}

.info-portal-container {
  display: flex;
  flex-direction: column;
  margin-block: rem-calc(32);
  @include breakpoint(large) {
    margin-block: rem-calc(40);
  }

  &__header {
    @include secondary-font-family();
    display: flex;
    font-size: rem-calc(24);
    margin: 0 0 rem-calc(16) 0;
    font-style: normal;
    font-weight: 700;
    line-height: rem-calc(30);
    letter-spacing: -0.4px;
    @include breakpoint(medium) {
      font-size: rem-calc(28);
      margin-bottom: rem-calc(32);
      line-height: rem-calc(36);
      letter-spacing: -0.7px;
    }
  }

  &__list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: rem-calc(16);
    row-gap: rem-calc(16);
    justify-content: center;
    @include breakpoint(medium) {
      column-gap: rem-calc(20);
      row-gap: rem-calc(20);
    }

    &__item {
      display: flex;
      justify-self: flex-start;
      flex: 1 100%;
      overflow: hidden;
      border-radius: rem-calc(10);
      cursor: pointer;
      background: #fff;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
      @include breakpoint(medium) {
        flex: 0 0 48%;
      }
      @include breakpoint(large) {
        flex: 0 0 31.9%;
      }
      @include breakpoint(xlarge) {
        flex: 0 0 32.1%;
      }
      @include breakpoint(xxlarge) {
        flex: 0 0 32.2%;
      }
      @include focus-within-handler($offset: 4px);

      &__button {
        display: flex;
        padding: rem-calc(16);
        gap: rem-calc(16);
        flex: 1;
        flex-wrap: nowrap;
        text-decoration: none;
        align-items: center;
        justify-content: flex-start;
        border: none;

        &__img {
          display: flex;
          max-width: rem-calc(48);
          min-width: rem-calc(48);
        }

        &__text {
          @include secondary-font-family();
          margin: 0;
          color: $denim;
          font-size: rem-calc(20);
          font-weight: 700;
          line-height: rem-calc(24);
          letter-spacing: -0.4px;
          font-style: normal;
          @include breakpoint(medium) {
            letter-spacing: -0.7px;
          }
        }
      }
    }
  }
}
