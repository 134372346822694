@import '../../platformui/CookiePanel/styles';

@include CookiePanel(
  $buttonBorderRadius: 30px,
  $backgroundColor: transparent,
  $backgroundImage: url('../../framework/images/cookie.svg'),
  $panelMaxWidth: 100%,
  $panelBoxShadow: none,
  $panelContentMinHeight: 156px,
  $panelContentPadding: (
    12px 32px 22px,
    22px 32px 32px,
    32px 32px 12px,
  ),
  $panelContentFontSize: (
    rem-calc(16),
    rem-calc(16),
    rem-calc(20),
  ),
  $panelContentFontWeight: 300,
  $panelContentLinkTextDecoration: none,
  $panelAcceptButtonFontSize: (
    rem-calc(14),
    rem-calc(14),
    rem-calc(20),
  ),
  $panelAcceptButtonTextTransform: uppercase,
  $panelAcceptButtonFontWeight: (
    bold,
    bold,
    300,
  ),
  $panelAcceptButtonHeight: (
    40px,
    50px,
    50px,
  ),
  $panelAcceptButtonMinWidth: (
    173px,
    140px,
    140px,
  )
) {
  background-repeat: no-repeat;
  background-size: 100% 100px;
  padding-top: 32px;

  @include breakpoint(large) {
    background-size: 100% 100%;
    padding-top: 0;
  }

  &__content {
    background: $lipstick;

    @include breakpoint(large) {
      background: transparent;
    }
  }

  &__content__text {
    max-width: 675px;
    text-align: center;

    @include breakpoint(large) {
      max-width: 100%;
      text-align: left;
    }
    a {
      border-bottom: solid 0.5px $white;
    }
  }
}
