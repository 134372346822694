@import '../../platformui/ReadingAid/styles.scss';
@import './reading-aid-dynamic.scss';

$open: '.c-reading-aid--is-opened';

@include ReadingAid($activateButtonBg: $denim) {
  box-shadow: 0 0 10px 0 rgba(30, 30, 30, 0.3);
  display: none;
  height: 0;
  max-width: none;
  position: relative;
  transform: none;

  @include breakpoint(large) {
    align-items: center;
    display: flex;
    filter: none;
    transition: height 0.2s ease-in-out;
  }

  & > div {
    flex: 0 0 100%;
  }

  &__inner {
    opacity: 0;
    position: relative;
    transition: opacity 0.2s linear 0.1s;

    #{$open} & {
      opacity: 1;
    }

    &:after {
      border-top: 16px solid $white;
      border-bottom: 16px solid transparent;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      content: '';
      filter: drop-shadow(-1px 3px 2px rgba(30, 30, 30, 0.15));
      height: 0;
      position: absolute;
      right: 194px;
      top: 120px;
      width: 0;
    }
  }

  &--is-opened {
    height: 140px;
  }

  button {
    background: $denim;
    border-radius: 10px;
    color: $white;
    cursor: pointer;
    font-family: $secondary-font;
    font-size: rem-calc(20);
    font-weight: 700;
    height: 50px;
    position: relative;
    text-transform: uppercase;
    width: 346px;

    &:hover {
      background: $dusk;
    }
  }

  p {
    font-size: rem-calc(18);
  }
}
