@import '../../Framework/platform-mixins/Chevron.scss';

@mixin FindCare-map(
  $className: 'findcare-map',
  $cta: #000,
  $lightGrey: #ccc,
  $white: white,
  $black: black,
  $muted: #000,
  $linkHover: #000
) {
  .#{$className} {
    margin-bottom: 30px;

    @include Chevron(
      $className: findcare-map__chevron,
      $chevronColor: $cta,
      $leftLinePosition: calc(100% - 24px),
      $rightLinePosition: calc(100% - 17px),
      $height: 20px,
      $width: 40px,
      $top: 5px
    ) {
      background: transparent;
      position: absolute;
      top: 0px;
      right: 0;
      cursor: pointer;
      z-index: 2;
    }

    @include breakpoint(medium) {
      margin-bottom: 20px;
    }

    &__container {
      display: none;
      background: $lightGrey;
      border-radius: 10px;
      height: 400px;
      overflow: hidden;
      position: relative;

      @include breakpoint(medium) {
        display: block;
      }
    }

    &__map-modal-trigger {
      background: $white;
      border: solid 1px $cta;
      border-radius: 30px;
      color: $cta;
      display: block;
      font-size: rem-calc(14);
      font-weight: bold;
      height: 40px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;

      @include breakpoint(medium) {
        display: none;
      }
    }

    &__gmap {
      height: 100%;
      width: 100%;

      &__map {
        height: 100%;
        opacity: 1;
        transition: opacity 0.3s ease;
        width: 100%;

        .findcare-map__gmap--is-loading &,
        .findcare-map__gmap--is-markers-loading & {
          opacity: 0.4;
        }
      }

      &--modal {
        height: calc(100vh - 62px);
        width: 100%;
        position: relative;

        @include breakpoint(large) {
          height: calc(100vh - 200px);
        }
      }
    }

    &__map {
      &__controls {
        &__boundaries {
          bottom: 20px;
          background: $cta;
          border-radius: 40px;
          color: $white;
          cursor: pointer;
          font-size: rem-calc(14);
          font-weight: bold;
          height: 40px;
          left: 50%;
          padding: 0 25px;
          position: absolute;
          text-transform: uppercase;
          transform: translateX(-50%);
          white-space: nowrap;
          transition: all 0.3s ease-in-out;

          :hover {
            opacity: 1;
          }

          &[disabled] {
            opacity: 0;
          }

          &:focus {
            outline: none;
          }

          &__text {
            opacity: 1;
            .findcare-map__gmap--is-loading &,
            .findcare-map__gmap--is-markers-loading & {
              opacity: 0;
            }
          }

          .c-spinner {
            opacity: 0;

            .findcare-map__gmap--is-loading &,
            .findcare-map__gmap--is-markers-loading & {
              opacity: 1;
            }
          }
        }

        &__expand {
          background: $cta;
          border-radius: 100%;
          cursor: pointer;
          display: inline-block;
          height: 40px;
          width: 40px;
          position: absolute;
          right: 20px;
          top: 20px;

          @include icon('e80e');

          &:focus {
            outline: none;
          }

          &:before {
            color: $white;
            font-size: 20px;
            left: 10px;
            position: absolute;
            top: 10px;
          }
        }

        &__zoom {
          display: none;
          bottom: 20px;
          position: absolute;
          right: 20px;

          @include breakpoint(large) {
            display: block;
          }
        }

        &__zoom-in {
          background: $cta;
          border-radius: 100%;
          cursor: pointer;
          height: 40px;
          position: relative;
          width: 40px;

          &:focus {
            outline: none;
          }

          &:before {
            background: $white;
            border-radius: 2px;
            content: '';
            height: 2px;
            left: 10px;
            position: absolute;
            top: 19px;
            width: 20px;
          }

          &:after {
            background: $white;
            border-radius: 2px;
            content: '';
            height: 20px;
            left: 19px;
            position: absolute;
            top: 10px;
            width: 2px;
          }
        }

        &__zoom-out {
          background: $cta;
          border-radius: 100%;
          cursor: pointer;
          height: 40px;
          margin-right: 10px;
          position: relative;
          width: 40px;

          &:focus {
            outline: none;
          }

          &:before {
            background: $white;
            border-radius: 2px;
            content: '';
            height: 2px;
            left: 10px;
            position: absolute;
            top: 19px;
            width: 20px;
          }
        }

        &__planner-link {
          bottom: 30px;
          display: block;
          background: $white;
          border-radius: 5px;
          font-size: rem-calc(16);
          left: 50%;
          padding: 4px 40px 4px 10px;
          position: absolute;
          text-decoration: none;
          transform: translateX(-50%);
          white-space: nowrap;

          @include breakpoint(medium) {
            padding: 8px 50px 6px 15px;
          }

          @include breakpoint(large) {
            bottom: auto;
            left: 20px;
            top: 10px;
            transform: translateX(0);
          }

          @include icon('e80e');

          &:before {
            color: $white;
            font-size: 11px;
            position: absolute;
            right: 8px;
            top: 11px;
            z-index: 2;
            text-decoration: none;

            @include breakpoint(medium) {
              font-size: 17px;
            }
          }

          &:after {
            background: $cta;
            border-radius: 100%;
            content: '';
            display: block;
            height: 20px;
            position: absolute;
            right: 5px;
            top: 6px;
            width: 20px;

            @include breakpoint(medium) {
              height: 30px;
              width: 30px;
              top: 5px;
            }
          }

          span {
            display: inline-block;
            text-decoration: underline;

            &:hover {
              color: $linkHover;
            }
          }
        }
      }

      &__popup {
        display: none;
        height: 0;
        position: absolute;
        width: 300px;

        &--is-open {
          display: block;
        }

        &__bubble {
          bottom: /* TIP_HEIGHT= */ 60px;
          left: 0;
          position: absolute;
          transition: all 0.3s ease;
          transform: translateY(0);
          width: 100%;

          &--is-cluster {
            transform: translateY(30px);
          }

          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-50%, 0);
            transition: border-width 0.3s ease;
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 12px solid $white;
          }

          &--is-loading {
            transform: translateY(20px);

            &.findcare-map__map__popup__bubble--is-cluster {
              transform: translateY(40px);
            }

            &:after {
              border-width: 0;
            }
          }
        }

        @include Chevron(
          $className: findcare-map__map__popup__scroll,
          $chevronColor: $cta,
          $leftLinePosition: calc(100% - 15.5px),
          $rightLinePosition: calc(100% - 9.5px),
          $lineLength: 8px,
          $top: 8px
        ) {
          left: 50%;
          margin-left: -12.5px;
          width: 25px;
          height: 25px;
          background: white;
          position: absolute;
          bottom: 10px;
          border-radius: 50%;
          border: 1px solid $cta;
          z-index: 3;
          cursor: pointer;

          &[hidden] {
            display: none;
          }
        }

        &__content {
          @include base-font-family();
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-50%, -100%);
          /* Style the info window. */
          background-color: $white;
          border-radius: 10px;
          overflow-y: auto;
          opacity: 1;
          padding: 10px 10px 40px 20px;
          transition: all 0.3s ease;
          /*max-height: 60px;*/
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);

          &--overflow {
            opacity: 1;
          }

          .findcare-map__map__popup__bubble--is-loading & {
            background-color: transparent;
            border-radius: 0;
            box-shadow: none;
            opacity: 0;
          }

          &__inner {
            @include breakpoint(medium) {
              max-height: 150px;
            }
            display: flex;
            flex-direction: column;
            float: left;
            max-height: 220px;
            overflow: auto;
            position: relative;
            margin-top: 10px;
          }

          p {
            margin: 3px 0px 0px 0px;
            color: $muted;
            font-size: rem-calc(14);
          }
        }

        &__heading {
          color: $cta;
          font-size: rem-calc(16);
          float: left;
          margin-top: 30px;
          width: 100%;
          padding-right: 35px;
          position: sticky;
          z-index: 1;
          background: white;
          top: 0;
          padding-bottom: 2px;

          &:first-of-type {
            margin-top: 0;
          }

          a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }

            &:focus {
              @include focus-handler($muted, 2px);
            }
          }
        }

        &__phone {
          &:focus {
            @include focus-handler($color: $muted, $offset: 4px);
          }
        }

        &__wrapper {
          position: relative;
          border-bottom: solid 1px rgba($black, 0.2);
          padding-bottom: 10px;
          padding-top: 10px;

          &[aria-expanded='false'] {
            p {
              display: none;
            }
            ul {
              display: none;
            }
          }

          &[aria-expanded~='true'] {
            p {
              display: block;
            }
            ul {
              display: block;
            }
            button {
              transform: rotate(180deg);
            }
          }
        }

        &__close {
          background: $white;
          cursor: pointer;
          height: 20px;
          padding: 0;
          position: absolute;
          top: 10px;
          right: 10px;
          width: 20px;

          &:focus {
            @include focus-handler($muted, 1px);
          }

          &:before {
            background: $linkHover;
            border-radius: 2px;
            content: '';
            display: block;
            height: 2px;
            left: 1px;
            transform: rotate(45deg);
            position: absolute;
            top: 8px;
            width: 18px;
          }

          &:after {
            background: $linkHover;
            border-radius: 2px;
            content: '';
            display: block;
            height: 2px;
            left: 1px;
            transform: rotate(135deg);
            position: absolute;
            top: 8px;
            width: 18px;
          }
        }
      }
    }
  }
  @content;
}
