@import '../../platformui/Mobilenav/styles';
@import '../../platformui/Framework/platform-mixins/Chevron.scss';

@include MobileNav(
  $className: c-metanav,
  $listBgColor: $faded-pink,
  $listItemColor: $berry,
  $fontSize: rem-calc(16)
) {
  font-weight: 700;
  @include secondary-font-family();
  @include Chevron($className: chevron--mobile, $chevronColor: $berry) {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;

    @include focus-handler($offset: -8px);
  }

  .c-metanav__list__item__container {
    a {
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .c-metanav__list__item {
    box-shadow: inset 0 2px 4px 0 rgba($black, 0.2);
    a {
      @include focus-handler($offset: -2px);
    }
  }
  
}
.care-giver_container{
    display: flex;
}

.care-giver_container a{
    width:100%;

}

.care-giver-icon-mobile {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.0285 12.6274L22 8.65592L18.0285 4.68443C17.7826 4.43852 17.3839 4.43852 17.138 4.68443C16.8921 4.93034 16.8921 5.32903 17.138 5.57494L19.5359 7.97286L10.1969 7.97286C9.84913 7.97286 9.56721 8.25478 9.56721 8.60254C9.56721 8.95031 9.84913 9.23223 10.1969 9.23223L19.6427 9.23223L17.138 11.7369C16.8921 11.9828 16.8921 12.3815 17.138 12.6274C17.3839 12.8733 17.7826 12.8733 18.0285 12.6274Z' fill='%23A00B36'/%3E%3Cpath d='M2 15.7084L5.97149 19.6799C6.2174 19.9258 6.61609 19.9258 6.862 19.6799C7.10791 19.434 7.10791 19.0353 6.862 18.7894L4.35733 16.2847L13.8031 16.2847C14.1509 16.2847 14.4328 16.0028 14.4328 15.655C14.4328 15.3073 14.1509 15.0253 13.8031 15.0253L4.46408 15.0253L6.862 12.6274C7.10791 12.3815 7.10791 11.9828 6.862 11.7369C6.61609 11.491 6.2174 11.491 5.97149 11.7369L2 15.7084Z' fill='%23A00B36'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 30px 30px;
    display: inline-block;
    margin: 10px 0px 10px 20px;
    padding: 15px;
} 

@include MobileNavSubLevel(
  $className: c-metanav,
  $hasBorder: false,
  $fontSize: rem-calc(14),
  $textColor: $berry
) {
  font-weight: normal;

  @include SubLevelIndent($className: c-metanav, $textIndent: 30px) {
    // sublevel 2
    @include SubLevelIndent($className: c-metanav, $textIndent: 60px) {
      // sublevel 3
      // prettier-ignore
      @include SubLevelIndent($className: c-metanav, $textIndent: 80px) // sublevel 4
    }
  }
}
