@mixin BusinessHours($className: 'businesshours', $containerPadding: 20px 0px) {
  .#{$className} {
    &__container {
      display: flex;
      flex-direction: column;
      column-gap: 20px;
      padding: $containerPadding;

      @include breakpoint(large) {
        flex-direction: row;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      &__container {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include breakpoint(large) {
          width: 50%;
        }
      }
    }
    @content;
  }
}
