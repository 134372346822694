@import '../../platformui/AdjustableLinkBlock/styles';

@include AdjustableLinkBlock(
  $minHeight: 0,
  $contentPadding: 0,
  $useIcon: false
) {
  .c-navigationpush {
    position: relative;
    padding-left: 40px;
    padding-top: 10px;

    &__content {
      max-width: 260px;

      a {
        @include focus-handler($offset: 2px);
      }

      &__heading {
        @include secondary-font-family();
        color: $berry;
        display: block;
        font-weight: bold;
        font-size: rem-calc(22);
        line-height: 1.27;
        margin: 0 0 10px;
      }

      &__link {
        color: $berry;
        display: inline-block;
        text-decoration: none;

        @include link-arrow();
      }

      p {
        color: $black;
        font-size: rem-calc(16);
        margin: 0 0 15px;

        a {
          color: inherit;
          font-size: inherit;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
