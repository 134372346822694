@import '../../platformui/Framework/platform-mixins/Chevron.scss';

.subscription-link {
  background-color: $pale-grey;
  border-radius: 10px;
  display: block;
  margin: 60px 0 40px;
  padding: 15px;
  position: relative;
  text-align: left;
  text-decoration: none;
  width: 100%;

  @include focus-handler();

  @media print {
    display: none;
  }

  &:before {
    background-image: url('../../framework/images/email.svg');
    background-size: cover;
    content: '';
    height: 36px;
    left: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
  }

  span {
    color: $black;
    display: block;
    font-size: rem-calc(16);
    font-weight: bold;
    margin: 0 30px 0 50px;
  }

  @include Chevron(
    $className: subscription-link__chevron,
    $chevronColor: $denim,
    $height: 0,
    $leftLinePosition: calc(100% - 27px),
    $lineLength: 9px
  ) {
    background-color: transparent;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: rotate(-90deg) translateY(-50%);
  }
}
// }
