.article-main-content {
  @include clearfix();
  margin-bottom: 70px;

  @media print {
    + .c-section {
      display: none;
    }
  }

  &__inner {
    max-width: rem-calc(1280);
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .u-blocklist {
    margin-bottom: 60px;
  }
}
