@import '../../platformui/FindCare/findcare-categories-lists/styles.scss';

@include FindCare-Categorieslist(
  $cta: $denim,
  $lightGrey: $pale-grey,
  $linkHover: $dusk,
  $black: $black,
  $white: $white,
  $muted: $muted
) {
  .categories-alphabetical-list__item {
    a {
      background-color: $pale-grey;
      color: $denim;
    }
    &--is-active a {
      &:hover,
      &:focus {
        background-color: $dusk;
        color: $white;
      }
      color: $white;
      background-color: $dusk;
    }
  }
}
