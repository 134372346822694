@import '../../../platformui/Contact-card/extended-info/styles.scss';

@include ExtendedInfo(
  $headingColor: $muted,
  $textColor: $denim,
  $buttonTextHover: $dusk,
  $linkHoverColor: $dusk
) {
  border-top: none;
}
