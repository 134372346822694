.units-like {
  &__list {
    padding: 0;
    margin: 0 10px;
    list-style: none;

    @include breakpoint(medium) {
      margin: 0 5px;
    }
    li {
      display: block;
      text-align: center;
      @include breakpoint(medium) {
        display: inline-block;
        text-align: left;
      }
    }
  }

  &__list-item {
    border-radius: rem-calc(30);
    border: solid 1px $cloudy-blue;
    background-color: $pale-grey;
    font-size: rem-calc(14);
    padding: 10px 20px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    width: 100%;

    @include focus-handler();

    @include breakpoint(medium) {
      width: auto;
    }
  }

  .contact-subheading {
    margin-bottom: 30px;
  }

  @media print {
    display: none;
  }
}
