@mixin ScheduleList($containerPadding: 20px 0px) {
  .schedule-list {
    &__container {
      display: flex;
      flex-direction: column;
      column-gap: 20px;
      padding: $containerPadding;

      @include breakpoint(large) {
        flex-direction: row;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      &__container {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include breakpoint(large) {
          width: 50%;
        }
      }

      &__title {
        display: inline-block;
        vertical-align: top;
      }

      &__row {
        display: grid;
        grid-template-columns: 1fr 2fr;

        @include breakpoint(large) {
          grid-template-columns: 1fr 2fr 3fr;
        }
      }

      &__item {
        padding-bottom: 5px;
        font-size: 1rem;

        &__period {
          grid-column-start: 2;
        }

        &__comment {
          font-style: italic;
          grid-column-start: 2;

          @include breakpoint(large) {
            grid-column-start: 3;
          }
        }

        &__period,
        &__comment {
          display: block;
          margin-left: 20px;
        }
      }
    }
    @content;
  }
}
