@import '../../platformui/Meganav/styles.scss';

@include Meganav(
  $width: $global-width,
  $bgColor: $faded-pink,
  $boxShadow: 0 4px 8px 0 rgba($black, 0.2),
  $columnGap: (
    20px,
    30px,
    40px,
  ),
  $itemContainerPadding: 3px 0,
  $itemPadding: 5px 10px 5px 25px
) {
  padding: 40px 0 50px;

  &__list {
    &__item {
      border-bottom: solid 1px rgba($berry, 0.3);

      a {
        color: $berry;
        text-decoration: none;

        @include focus-handler($offset: 1px);

        @include link-arrow(
          $top: 15px,
          $left: 3px,
          $fontSize: rem-calc(6),
          $bgSize: rem-calc(16),
          $bgTop: 10px
        );
      }
    }
  }

  &__container {
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);

    &:before {
      background: $dark-pink;
      border-radius: 100%;
      content: '';
      height: 1070px;
      right: -780px;
      position: absolute;
      top: -800px;
      width: 1210px;
    }
  }
}
