@import '../../platformui/FindCare/findcare-sort/styles.scss';

@include FindCare-Sort(
  $className: 'findcare-sort',
  $errorBackgroundImage: url('../../framework/images/alert-red.svg'),
  $cta: $denim,
  $muted: $muted,
  $white: $white,
  $black: $black,
  $boxShadowColor: $berry
) {
}
