@import '../../platformui/HighLight/styles';

@include HighLight(
  $infoIcon: '../../framework/images/info.svg',
  $warningIcon: '../../framework/images/warning.svg',
  $margin: (
    0 0 30px,
    0 0 30px 0,
  ),
  $padding: (
    0px 20px 20px,
    0px 60px 30px,
  )
) {
  background-color: c('secondary-light');
  border-radius: 5px;
  border-top: solid 5px c('main');
  font-size: rem-calc(16);

  h2 ~ div,
  h3 ~ div {
    margin-top: 0;
  }

  p,
  ol,
  ul {
    font-size: inherit;
  }

  ul {
    padding-left: rem-calc(20);
  }

  ol {
    padding-left: rem-calc(24);
    @media print {
      padding-left: rem-calc(30);
    }
  }
  h2,
  h3 {
    color: c('main');
  }

  &__header {
    font-size: rem-calc(22);
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 16px;
    margin-left: 40px;

    @include breakpoint(large) {
      margin-top: 25px;
      margin-left: 0px;
    }

    @include breakpoint(large) {
      font-size: rem-calc(24);
    }
  }

  &--noicon {
    @include breakpoint(small only, medium only) {
      .c-highlight__header {
        margin-left: inherit;
        max-width: inherit;
      }
      div {
        margin-top: 10px;
      }
    }
  }

  &--noicon div {
    margin-top: 0px;
  }

  &--info,
  &--warning {
    background-repeat: no-repeat;
    background-position: 20px 15px;
    background-size: 30px;
    @include breakpoint(medium) {
      background-position: 60px 15px;
    }
    @include breakpoint(large) {
      background-size: 40px;
      background-position: 60px 25px;

      h2,
      h3 {
        padding: 6px 0 10px 50px;
      }
    }
  }

  &__text-container {
    max-width: rem-calc(720);
  }

  &__text-container > div > p {
    margin: 0;
  }

  &__cta-container {
    padding-top: 30px;

    &__cta-primary-button {
      @include LinkButton(
        $bgColor: c('cta'),
        $hoverBgColor: c('cta-dark'),
        $border: 1px solid c('cta'),
        $color: $white,
        $hoverColor: $white
      );
      text-transform: uppercase;

      a {
        padding: 14px 30px 14px 30px;
        font-family: $secondary-font;
        font-weight: bold;
        font-size: 18px;
      }
    }

    &__cta-secondary-button {
      @include LinkButton(
        $bgColor: $white,
        $hoverBgColor: c('cta-dark'),
        $border: 1px solid c('cta'),
        $color: c('cta'),
        $hoverColor: $white
      );
      text-transform: uppercase;

      a {
        padding: 14px 30px 14px 30px;
        font-family: $secondary-font;
        font-weight: 700;
        font-size: 18px;
      }
    }
  }
}
