@import '../../platformui/Linklist/styles.scss';

@include Linklist($className: 'error404-linklist') {
  .error404-linklist {
    display: block;
    text-align: left;

    &:before {
      background: $warm-grey;
      content: '';
      display: block;
      height: 1px;
      margin-bottom: 20px;
      width: 43px;
    }

    @include breakpoint(medium) {
      display: inline-block;

      &:before {
        display: none;
      }
    }

    li {
      display: list-item;
      margin: 0 0 5px 0;
      padding: 0;

      @include breakpoint(medium) {
        display: inline-block;
        margin: 0 40px 5px 0;

        .u-text-center & {
          margin: 0 20px 5px;
        }
      }
    }

    @include LinklistLink($className: 'error404-linklist', $printColor: $denim);
  }
}
