$buttonBg: $denim;
$buttonBorder: none;
$buttonColor: $white;
$buttonHoverBg: $dusk;
$buttonHoverColor: $white;
$buttonBorderRadius: 25px;

%button {
  display: inline-block;
  @include secondary-font-family();
  background: $buttonBg;
  border: $buttonBorder;
  border-radius: $buttonBorderRadius;
  color: $buttonColor;
  cursor: pointer;
  font-size: rem-calc(20);
  font-weight: 700;
  padding: 10px 25px;
  text-transform: uppercase;
  text-align: center;
  width: auto;
  @include focus-handler();

  &:hover,
  &:focus {
    background: $buttonHoverBg;
    color: $buttonHoverColor;
  }
}

$secondaryButtonBg: $white;
$secondaryButtonBorder: 1px solid $denim;
$secondaryButtonColor: $denim;
$secondaryButtonHoverBg: $pale-grey;
$secondaryButtonHoverColor: $denim;
$secondaryButtonBorderRadius: $buttonBorderRadius;

%secondary-button {
  @extend %button;
  @include secondary-font-family();
  background: $secondaryButtonBg;
  border: $secondaryButtonBorder;
  border-radius: $secondaryButtonBorderRadius;
  color: $secondaryButtonColor;

  &:hover,
  &:focus {
    background: $secondaryButtonHoverBg;
    color: $secondaryButtonHoverColor;
    outline: none;
  }
}

.Form {
  &__MainBody {
    text-align: center;
  }

  &__Title {
    border-top: solid 1px rgba($black, 0.2);
    color: $lipstick;
    margin-top: 40px;
    padding-top: 40px;
  }

  &__Description {
    .EPiServerForms & {
      margin-bottom: 25px;
    }
  }

  &__Element {
    float: left;
    text-align: left;
    width: 100%;

    .EPiServerForms & {
      margin-bottom: 0;
    }

    &.FormStep {
      text-align: center;
    }
    [type='submit'],
    [type='reset'] {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 10px;
      margin-right: 10px;
      float: none;
    }

    [type='submit'] {
      @extend %button;
      &.FormImageSubmitButton {
        background: transparent;
        padding: 0;

        &:hover {
          box-shadow: none;
        }
      }
    }

    [type='reset'] {
      @extend %secondary-button;

      &.FormImageSubmitButton {
        background: transparent;
        padding: 0;

        &:hover {
          box-shadow: none;
        }
      }
      &:focus {
        outline: none;
      }
    }

    /* PARAGRAPH TEXT */
    &.FormParagraphText {
      margin-top: 5px;
      h2,
      h3 {
        margin-top: 10px;
      }
    }

    &.ValidationRequired {
      .Form__Element__Caption {
        &:after {
          content: '*';
          display: inline-block;
          margin: 0 2px;
        }
      }
    }

    &.FormChoice {
      margin-bottom: 20px;

      label {
        color: $black;
        display: block;
        overflow: hidden;
        padding: 6px 0 6px 30px;
        position: relative;

        @include focus-handler();
      }

      input[type='radio'] {
        position: absolute;
        left: -30px;
        top: 0;
        @include focus-handler();

        & + span {
          &:after {
            background: $pale-grey;
            border: solid 1px $denim;
            border-radius: 100px;
            content: '';
            cursor: pointer;
            display: inline-block;
            height: 20px;
            left: 0;
            position: absolute;
            top: 8px;
            width: 20px;
          }
        }

        &:checked + span {
          &:before {
            background: $denim;
            border-radius: 100px;
            content: '';
            display: block;
            height: 14px;
            width: 14px;
            left: 3px;
            position: absolute;
            top: 11px;
            z-index: 2;
          }
        }
      }

      input[type='checkbox'] {
        position: absolute;
        left: -30px;
        top: 0;

        & + span {
          &:after {
            background: $pale-grey;
            border: solid 1px $denim;
            border-radius: 4px;
            content: '';
            cursor: pointer;
            display: inline-block;
            height: 20px;
            left: 0;
            position: absolute;
            top: 8px;
            width: 20px;
          }
        }

        &:checked + span {
          @include icon('f107');

          &:before {
            color: $denim;
            font-size: rem-calc(22);
            left: -1px;
            position: absolute;
            top: 7px;
            z-index: 2;
          }
        }
      }
    }
  }

  /* TEXTFIELD */
  &Textbox {
    @extend .c-forms__input;

    &.ValidationFail {
      @extend .c-forms__input--is-error;
    }
  }

  /* TEXTAREA */
  &Textbox--Textarea {
    &.ValidationFail > .c-forms__textarea {
      @extend .c-forms__textarea--is-error;
    }
  }

  /* SELECTBOX */
  &Selection {
    &.ValidationFail {
      .c-forms__select {
        @extend .c-forms__select--is-error;
      }
    }
  }

  /* LABELS */
  &__Element__Caption {
    @extend .c-forms__label;
  }

  /* ERROR MESSAGES */
  &__Element__ValidationError {
    .FormChoice & {
      margin-top: 12px;
    }
  }
}
.ValidationFail > .Form__Element__ValidationError {
  @extend .c-forms__error;
}

.EPiServerForms .Form__Status .Form__Status__Message {
  @extend .u-content-editable;

  padding: 20px 20px 5px;

  &.Form__Success__Message {
    background-color: transparent;
    padding: 20px 0;

    p {
      font-size: rem-calc(16);
    }
  }

  &.hide {
    display: none;
  }

  p,
  ul,
  ol {
    font-size: rem-calc(14);
  }
  ul {
    li {
      &:before {
        margin-right: 5px;
      }
    }
  }

  ol {
    li {
      padding-left: 6px;
    }
  }

  a {
    background-position: 0 18px;
  }
}
