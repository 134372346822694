@import '../../platformui/FindCare/findcare-searchfield/autocomplete.scss';

@include FindCare-Autocomplete(
  $className: '.findcare-searchfield__autocomplete',
  $black: $black,
  $white: $white,
  $muted: $muted,
  $lightGrey: $pale-grey,
  $cta: $denim
) {
  padding: 0;
  top: 80px;

  .nearby {
    position: relative;
    text-align: left;
    @include icon('e85c');

    button {
      padding-left: 30px;
    }

    &:before {
      position: absolute;
      color: $denim;
      font-size: 28px;
      left: 2px;
      top: 6px;
    }
  }

  &--is-open {
    padding: 15px 10px 10px 10px;
    &:after {
      .findcare-searchfield__toggle-suggestion {
        transform: rotate(180deg);
      }
    }
  }

  & > .caretype-footer {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid rgba($black, 0.2);
  }

  &__title {
    display: inline-block;
    font-size: rem-calc(14);
    text-transform: uppercase;
    border-bottom: 1px solid rgba($black, 0.2);
    margin-bottom: 6px;
    color: $muted;
    padding: 0 10px 12px 10px;
    @include breakpoint(small only) {
      max-width: 60%;
    }
    @include breakpoint(medium) {
      padding: 5px 10px 10px;
    }
  }
  &__item {
    font-size: rem-calc(16);

    &:focus,
    &:hover {
      background-color: $pale-grey;
    }
    & > button {
      background-color: transparent;
      width: 100%;
      display: block;
      font-size: inherit;
      text-align: left;
      min-height: 40px;
      padding: 10px;
    }
  }

  &__close {
    position: absolute;
    top: 17px;
    right: 10px;
    padding: 0px;
    background: transparent;
    font-size: rem-calc(12);
    text-transform: uppercase;
    color: $muted;
    cursor: pointer;

    @include icon('e84e');

    &:before {
      float: right;
      font-size: 17px;
      margin-left: 5px;
    }
    @include breakpoint(medium) {
      top: 22px;
    }
  }

  .highlight {
    border: 1px dashed $warm-grey;
    border-radius: 6px;
    padding: 5px;
    margin-bottom: 15px;
    p {
      margin: 0;
    }
    button {
      width: 100%;
      text-align: left;
      background: none;
    }
  }
}
