.regional-container {
  border-left: 1px solid $warm-grey;
  margin: 10px 0 25px;
  min-height: 60px;
  padding-left: 20px;
  position: relative;
  display: inline-block;
  width: 100%;

  [dir='rtl'] & {
    border-left: none;
    border-right: 1px solid $warm-grey;
    padding-left: 0;
    padding-right: 20px;
  }

  @include breakpoint(large) {
    padding: 1px 0 0 30px;

    [dir='rtl'] & {
      padding-left: 0;
      padding-right: 30px;
      margin-left: 0;
      margin-right: -30px;
    }
  }

  @media print {
    padding: 0;
    margin-left: 0;
    border-left: 5px solid #e9e9e9;
    page-break-inside: avoid;
  }

  &:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 10px;
    opacity: 0.1;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    background-image: linear-gradient(to left, rgba(105, 105, 105, 0), $muted);
    z-index: -1;
    left: 0;

    [dir='rtl'] & {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      background-image: linear-gradient(
        to right,
        rgba(105, 105, 105, 0),
        $muted
      );
      left: auto;
      right: 0;
    }
  }

  &__content {
    z-index: -2;
    padding-top: 60px;
    .c-chapter > p:last-child,
    .c-chapter > div > p:last-child {
      margin-bottom: rem-calc(10);
    }

    @media print {
      padding-left: 15px;
    }
  }

  &__region {
    position: absolute;
    left: 0;
    top: 10px;
    display: flex;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #e9e9e9;
    padding: 10px 22px;

    [dir='rtl'] & {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      left: auto;
      right: 0;
    }

    @media print {
      background-color: #e9e9e9 !important;
      position: static !important;
      border-top: 5px solid #e9e9e9 !important;
      border-radius: 0 10px 10px 0 !important;
      max-width: 300px;
    }

    span {
      font-size: 0.875rem;

      @media print {
        margin-bottom: 0;
      }
    }
  }
}
