html {
  -webkit-text-size-adjust: 100%;
}

body.no-scroll {
  overflow: hidden;
}

.child-overflow-visible > *,
.overflow-visible {
  overflow: visible;
}

div.row.u-columns.u-columns--is-listview > .columns > .c-teaser,
div.row.u-flexwrap.u-flexwrap--is-listview > .columns > .c-teaser {
  min-height: 20px;
  height: auto;
  border-bottom: none;
  border-radius: 25px;
}

.u-flexwrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &--center {
    justify-content: center;
  }

  & > .columns {
    &.medium-6 {
      @include breakpoint(medium) {
        &:nth-child(n + 3) {
          margin-top: 20px;
        }
      }
      @include breakpoint(large) {
        &:nth-child(n + 3) {
          margin-top: 0;
        }
      }
    }

    &.large-3 {
      @include breakpoint(large) {
        &:nth-child(n + 5) {
          margin-top: 20px;
        }
      }
    }
  }

  &--is-listview {
    & > .columns {
      &.large-3 {
        @include breakpoint(medium) {
          width: 50%;
        }

        &:nth-child(n + 3),
        &:nth-child(n + 5) {
          margin-top: 0;
        }
      }
    }
  }

  & > [data-epi-property-name] {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    & > .columns {
      margin-bottom: 10px;

      @include breakpoint(medium) {
        margin-bottom: 20px;
      }
    }
  }
}

.u-article-main {
  display: flex;

  .u-right-col {
    margin-left: auto;
    padding-top: 50px;

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: auto;
    }

    @media print {
      display: none;
    }
  }
}
// specific print style for fucking Firefox
@supports (-moz-appearance: none) {
  @media print {
    .u-article-main {
      display: block !important;
    }
  }
}

//Printfix for ordered lists in firefox & edge
@media print {
  .u-article-main {
    ol {
      counter-reset: revert;
    }
  }
}

.u-100-height {
  display: none;
  flex-flow: column;
  height: 100%;
  position: relative;
  width: 100%;

  @include breakpoint(large) {
    display: flex;
  }
}

.u-search-page {
  margin-top: 0px;
  text-align: left;
  h1,
  h2,
  p,
  .c-searchlist__heading {
    text-align: left;
  }
  @include breakpoint(medium) {
    h1,
    header p {
      text-align: center;
    }
  }
}

.u-columns {
  column-gap: 0;
  float: none !important;
  text-align: left;

  & > .columns {
    display: inline-block;
    float: none !important;
    page-break-inside: avoid;
    vertical-align: top;
    width: 100%;

    & > .c-teaser {
      margin-bottom: 20px;
      margin-top: 5px;
      width: 100%;

      @include breakpoint(large) {
        min-height: 200px;
      }
    }
  }

  &--is-listview {
    @include breakpoint(medium) {
      column-count: 2 !important;
    }
    & > .columns {
      & > .c-teaser {
        border-radius: 25px;
        @include breakpoint(large) {
          min-height: unset;
        }
      }
    }
  }

  @include breakpoint(medium) {
    & > .columns {
      width: 33.33333%;
    }
    &--gt-3 {
      column-count: 3;
      column-fill: balance;
      & > .columns {
        width: 100%;
      }
    }
  }

  @include breakpoint(large) {
    & > .columns {
      display: inline-block;
      width: 25%;
    }
    &--gt-3 {
      column-count: unset;
      column-fill: unset;

      // IE10+ specific style for columns to render correctly
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        column-count: auto;
        column-fill: auto;
      }

      & > .columns {
        display: inline-block;
        width: 25%;
      }
      & > .c-teaser {
        min-height: unset;
      }
    }

    &--gt-4 {
      column-count: 4;
      & > .columns {
        width: 100%;
      }
    }
  }

  //Is in editmode?
  .epi-editContainer &,
  .is-inedit & {
    column-count: auto;
    column-gap: normal;
  }

  & > [data-epi-property-name] {
    column-count: 1;
    column-fill: balance;
    column-gap: 0;

    float: none !important;

    @include breakpoint(medium) {
      column-count: 3;
    }

    @include breakpoint(large) {
      column-count: 4;
    }

    & > .columns {
      display: block;
      float: none !important;
      margin-bottom: 15px;
      //margin-top:5px;
      page-break-inside: avoid;
      width: 100%;

      & > .c-teaser {
        margin-bottom: 5px;
        margin-top: 5px;
        width: 100%;
      }
    }
  }
}

//Fix css columns ie
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .u-columns {
    & > .columns {
      display: inline-block;
    }

    //Fix editmode
    & > [data-epi-property-name] {
      & > .columns {
        display: inline-block;
      }
    }
  }
}

@supports (-ms-ime-align: auto) {
  .u-columns {
    & > .columns {
      display: inline-block;
    }

    //Fix editmode
    & > [data-epi-property-name] {
      & > .columns {
        display: inline-block;
      }
    }
  }
}

.u-text-center {
  text-align: center;
}

.main-wrapper {
  position: relative;
  min-height: 80vh;
  @media print {
    margin: 40px auto 0;
    width: 52rem;
  }
}

.row {
  @media print {
    margin: 0 !important;
  }
}

@media print {
  @page :first {
    margin: 1cm 17% 2cm 17%;
  }

  @page {
    margin: 2cm 17%;
  }
}

//Hide the chatbot "Liv" in print mode
@media print {
  .\31 177-bai-chat, #chat-container { 
    display: none;
  }
}
