@import '../../platformui/AnchorNav/styles';

@include AnchorNav($denim, $muted) {
  .anchor-nav {
    &__list {
      &__link {
        @include link-arrow(
          $color: $denim,
          $hoverColor: $white,
          $paddingLeft: 32px,
          $top: 6px,
          $left: 5px
        ) {
          &:before {
            top: 9px;
          }
        }
        &--is-selected {
          &:before {
            color: $white;
          }
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}
