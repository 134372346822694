@mixin Faq($color: #000) {
  .c-faq {
    margin-bottom: 30px;

    &__meta {
      margin-top: 35px;
      color: $color;
    }

    &__responder {
      font-style: italic;
    }

    & + .c-categories {
      margin-bottom: 50px;
      margin-top: -20px;
    }

    @content;
  }
}
