@import '../../platformui/SearchBlock/styles';
@import '../../platformui/Pagination/styles';
@import '../../platformui/Spinner/styles';
@import './list/styles';
@import '../facets/styles.scss';
@import '../revised-and-published/styles.scss';

@include spinner($bgColor: $dusk, $margin: 0 auto) {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

@include SearchList() {
  &__footer {
    display: flex;
  }
}

@include SearchField(
  $searchFieldLabel: $denim,
  $buttonBg: $denim,
  $buttonColor: $white,
  $buttonBorderRadius: (
    0 10px 10px 0,
    10px,
  ),
  $buttonWidthLarge: 95px,
  $buttonHoverBg: $dusk,
  $searchFieldMarginBottom: (
    10px,
    20px,
  )
) {
  .c-search__field {
    &__button {
      letter-spacing: -0.3px;
      font-size: rem-calc(18);
      font-weight: bold;
      text-transform: uppercase;

      &:hover,
      &:focus {
        box-shadow: 0 3px 8px 0 rgba($black, 0.3);
      }

      @include focus-handler();

      @include breakpoint(large) {
        font-size: rem-calc(24);
        letter-spacing: -0.5px;
      }
    }

    .icon-search {
      color: $denim;
    }
  }

  @include SearchFieldQuery(
    $searchFieldBg: c('sky-background'),
    $border: solid 1px c('cta'),
    $borderRadius: (
      10px 0 0 10px,
      10px,
    ),
    $searchFieldFocusBorder: 1px solid c('cta'),
    $searchFieldFocusShadow: inset 0 5px 10px 0 rgba(c('cta'), 0.2),
    $paddingLeft: (
      20px,
      56px,
    )
  ) {
    .c-search__field__query {
      border-right: solid 1px $denim;
      font-family: $base-font;
      font-size: rem-calc(18);
      width: 100%;

      @include breakpoint(large) {
        width: calc(100% - 20px);
      }

      @include focus-handler();

      &::placeholder {
        opacity: 1;
        color: $muted;
        font-style: italic;
      }
    }
  }

  @include SearchAutocomplete(
    $panelBg: $white,
    $panelPadding: 0,
    $itemHoverBg: $pale-grey,
    $bottomBorderRadius: 10px,
    $topBorderRadius: 10px,
    $itemFontStyle: normal,
    $itemPadding: 12px 20px
  ) {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    margin-top: 4px;
    min-width: 100%;
    left: 0;
    right: auto;

    @include breakpoint(medium) {
      left: 0;
      right: auto;
    }

    @include breakpoint(large) {
      left: 10px;
      margin-top: -5px;
      max-width: calc(100% - 166px);
      min-width: 503px;
      right: auto;
    }

    &__item {
      a {
        color: $black;
        font-size: rem-calc(18);
        line-height: 1.44;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        @include breakpoint(large) {
          font-size: rem-calc(18);
        }
      }
    }
  }
}

@include pagination() {
  font-size: rem-calc(20);
  border: solid 1px $denim;
  border-radius: 30px;
  color: $denim;
  margin: 0 10px;
  width: 100%;
  padding: 12px 40px;
  text-transform: uppercase;

  &:hover,
  &:focus {
    box-shadow: 0 3px 8px 0 rgba($black, 0.3);
    background-color: $pale-grey;
    outline: none;
  }

  @include breakpoint(large) {
    margin: 0 auto;
    padding: 12px 50px;
    width: unset;
  }
}

@include SearchTools() {
  .c-search__tools {
    margin-top: 20px;
  }
  .c-searchlist__heading {
    @include base-font-family();
    font-size: rem-calc(16);
  }
}
