@mixin ContactHeader(
  $linkColor: hotpink,
  $muted: hotpink,
  $black: black,
  $hoverColor: hotpink
) {
  .contact-header {
    position: relative;
    margin-bottom: 36px;
    min-height: 85px;
    padding-top: 20px;

    &--no-links {
      margin-bottom: 0px;
      min-height: 0px;
    }

    @media print {
      margin: 0;
      min-height: 0;
    }

    @include breakpoint(large) {
      padding: 0;
    }
    &__logo {
      display: block;
      height: auto;
      width: 100%;

      @include breakpoint(medium) {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100px;
        width: 200px;
        max-width: unset;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__heading {
      margin: 0 0 10px;
      font-size: rem-calc(26);

      @include breakpoint(large) {
        font-size: rem-calc(36);
      }
    }

    &__address {
      margin-bottom: rem-calc(6);
    }

    &__address,
    &__link {
      font-size: rem-calc(20);
      line-height: 1.6;
      font-weight: 300;

      @include focus-handler($color: $muted);

      @include breakpoint(large) {
        font-size: rem-calc(24);
        line-height: 1.5;
      }
    }

    &__link {
      color: $linkColor;
      text-decoration: none;
      margin-right: rem-calc(25);

      @media print {
        &:before {
          font-size: 14px !important;
        }
      }

      span {
        &:hover {
          color: $hoverColor;
        }

        @media print {
          color: $black;
          font-size: 10px;
        }
      }
    }

    @content;
  }
}
