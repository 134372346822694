//Fix Foundation for rtl
.columns {
  [dir='rtl'] & {
    float: right;
  }
}

.column:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
  [dir='rtl'] & {
    float: left;
  }
}

.row {
  margin-right: rem-calc(13);
  margin-left: rem-calc(13);

  @include breakpoint(large) {
    margin-right: auto;
    margin-left: auto;
  }
}

@include breakpoint(medium) {
  .medium-offset-2 {
    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 16.66667%;
    }
  }
}
@include breakpoint(large) {
  .large-push-0 {
    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .large-push-1 {
    [dir='rtl'] & {
      left: auto;
      right: 8.33333%;
    }
  }

  .large-offset-0 {
    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .large-offset-1 {
    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 8.33333%;
    }
  }
}
