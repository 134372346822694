@import './units-like.scss';

@mixin RelatedUnits() {
  .related-units {
    margin: 30px 0 20px;

    .card-link-list {
      margin-bottom: 40px;
    }

    @media print {
      margin: 0;
    }
  }
  @content;
}
