@mixin ScheduleTable() {
  .schedule-table {
    margin-top: 16px;
  
    &__body {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }
  
    &__row {
      display: grid;
      grid-template-columns: 1fr 2fr;
  
      @include breakpoint(large) {
        grid-template-columns: 1fr 2fr 3fr;
      }
    }
  
    &__data, &__item {
      background-color: transparent;
      vertical-align: top;
      text-align: left;
      border: none;
      padding: 0px;
      padding-bottom: 5px;
    }
  
    &__item {
      &__period {
        grid-column-start: 2;
      }
  
      &__comment {
        font-style: italic;
        grid-column-start: 2;
  
        @include breakpoint(large) {
          grid-column-start: 3;
        }
      }
      &__period,
      &__comment {
        margin-left: 20px;
      }
    }
    @content;
  }
}
