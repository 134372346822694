@import '../../platformui/PrintSidebar/styles';

@include PrintSidebar($checkboxIconCode: 'e85a');

.c-printsidebar {
    &__selection-container {
        &__checkbox-container {
            div {
                button {
                    &.checked {
                        display: flex;
                        font-size: 27px;
                        justify-content: center;
                        align-items: center;

                        &::before {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}