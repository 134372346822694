@mixin FindCare-Sort(
  $className: 'findcare-sort',
  $errorBackgroundImage: '',
  $cta: #000,
  $muted: #000,
  $white: white,
  $black: black,
  $boxShadowColor: #000,
  $fontSize: rem-calc(14),
  $margin: 0 0 20px 0
) {
  .#{$className} {
    font-size: $fontSize;
    margin: 0 0 20px 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    @include breakpoint(medium) {
      margin: $margin;
    }

    li {
      color: $muted;
      display: inline-block;
      margin-left: 5px;
      position: relative;
      z-index: 1;

      &:after {
        content: '\007C'/ '';
        display: inline-block;
        margin-left: 5px;
      }

      &:first-child {
        margin-right: auto;

        @include breakpoint(medium) {
          margin-right: 0;
        }

        &:after {
          content: '';
        }
      }

      &:last-child {
        &:after {
          content: '';
        }
      }

      a {
        @include focus-handler($muted);

        display: inline-block;
        height: 24px;
      }
    }

    &__link {
      @include focus-handler($muted);
      @include base-font-family();
      background: none;
      border: none;
      color: $cta;
      cursor: pointer;
      font-size: rem-calc(14);

      text-decoration: underline;
      text-rendering: unset;
      padding: 0;
    }

    &__error {
      background: $white;
      background-image: $errorBackgroundImage;
      background-repeat: no-repeat;
      background-size: 30px 30px;
      background-position: 16px 17px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba($boxShadowColor, 0.5);
      color: $black;
      display: none;
      font-size: rem-calc(16);
      font-style: italic;
      padding: 20px 20px 20px 52px;
      position: absolute;
      right: 0;
      top: 41px;
      width: 290px;

      @include breakpoint(medium) {
        width: 390px;
      }

      &--is-visible {
        display: block;
      }

      &:before {
        border-left: 16px solid transparent;
        border-bottom: 16px solid $white;
        border-right: 16px solid transparent;
        content: '';
        filter: drop-shadow(0 -4px 2px rgba($boxShadowColor, 0.2));
        right: 20px;
        position: absolute;
        top: -15px;
      }
    }
  }
  @content;
}
