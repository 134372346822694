@mixin DescriptionList(
  $background: '',
  $numberBackground: '',
  $numberColor: '',
  $blockHeaderColor: ''
) {
  .description-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    p {
      max-width: rem-calc(720);
    }

    ul {
      max-width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    h2,
    h3 {
      @include secondary-font-family();
      color: $blockHeaderColor;
      font-size: rem-calc(22);
      line-height: rem-calc(28);

      @include breakpoint(medium) {
        font-size: rem-calc(24);
        line-height: rem-calc(30);
      }

      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.5px;
      margin: 0;
      margin-bottom: 10px;
    }

    &__preamble {
      margin: 0;
      margin-bottom: 30px;
    }

    &__item {
      position: relative;
      margin-bottom: 40px;
      background: $background;
      border: 1px solid;
      border-color: #dadbdc;
      border-radius: 10px;
      border-width: 1px;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);

      &__content {
        padding-top: 25px;
        padding-bottom: 35px;
        padding-inline: 20px;

        .list-item-header-container {
          display: flex;
          align-items: center;
          gap: 15px;
          margin-bottom: 10px;

          h3,
          h4 {
            @include secondary-font-family();
            margin: 0;
            color: $black;
            font-size: rem-calc(16);
            line-height: rem-calc(20);

            @include breakpoint(medium) {
              font-size: rem-calc(20);
              line-height: rem-calc(24);
            }

            font-style: normal;
            font-weight: 700;
            letter-spacing: -0.3px;
          }

          span {
            display: flex;
            width: 44px;
            padding: 15px 0px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 100px;
            background: $numberBackground;

            p {
              color: $white;
              @include secondary-font-family();
              text-align: center;
              font-size: rem-calc(20);
              font-style: normal;
              font-weight: 400;
              line-height: rem-calc(14);
            }
          }
        }

        p {
          @include base-font-family();

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    @content;
  }
}
