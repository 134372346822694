@import '../../platformui/Typeahead/styles.scss';

@include Typeahead(
  $focusColor: $muted,
  $itemHoverColor: $almost-white,
  $dropdownBgColor: white,
  $dropdownBorderColor: #dadbdc,
  $inputOutlineColor: $denim,
  $inputBgColor: $pale-grey,
  $categoryFontFamily: $secondary-font,
  $categoryUnderlineColor: #d9d9d9,
  $dropdownMaxHeight: rem-calc(600),
  $scrollbarColor: $denim,
  $scrollbarBg: $pale-grey,
  $chevronColor: $denim,
  $tooltipCloseButtonClr: $denim,
  $tooltipOpenButtonClr: $denim,
  $tooltipLinkColor: $denim,
  $tooltipLinkFontFamily: $base-font,
  $tooltipHeadingFontFamily: $secondary-font
) {
}
