@import '../../platformui/ScrollToTop/styles';
@include ScrollToTop(
  $bgImage: url('../../framework/images/arrow-up.svg'),
  $height: 60px,
  $width: 60px,
  $focusColor: c('grey-900')
) {
  @include breakpoint(large) {
    bottom: 5px;
  }

  body.no-scroll & {
    display: none;
  }
}
