@import '../../../platformui/TableWrapper/styles';

table {
  border-collapse: separate;
  border-spacing: 0;
  clear: both;
  margin-bottom: rem-calc(30);
  min-width: 100%;

  @media print {
    border-top: 3px solid $black;
  }

  .table-wrapper & {
    margin-bottom: rem-calc(10);
  }

  caption {
    color: c('stone-base');
    font-size: rem-calc(18);
    letter-spacing: 2px;
    padding: rem-calc(14) 0;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    border-top: 1px solid rgba($lipstick, 0.5);
    background: $white;

    [dir='rtl'] & {
      text-align: right;
    }

    @media print {
      border-top: 2px solid $black;
    }
  }

  thead,
  tbody {
    td,
    th {
      border-bottom: 1px solid rgba($lipstick, 0.5);
      border-right: 1px solid rgba($lipstick, 0.1);

      @media print {
        border-bottom: 2px solid $black;
        border-right: 2px solid $black;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  thead th {
    @include secondary-font-family();
    background: $light-pink;
    font-weight: bold;
    white-space: nowrap;

    @media print {
      background-color: transparent;
      border-bottom: 3px solid $black;
    }

    &:first-child {
      border-top-left-radius: 10px;

      [dir='rtl'] & {
        border-top-left-radius: 0;
        border-top-right-radius: 10px;
      }
    }

    &:last-child {
      border-top-right-radius: 10px;

      [dir='rtl'] & {
        border-top-right-radius: 0;
        border-top-left-radius: 10px;
      }
    }
  }

  tbody {
    tr {
      &:last-of-type {
        th {
          @media print {
            border-bottom: 3px solid $black;
          }
        }
        td {
          @media print {
            border-bottom: 3px solid $black;
          }
        }
      }

      td {
        padding: rem-calc(7) rem-calc(20);
      }
    }
  }

  tfoot {
    @media print {
      &:after {
        background-color: $black;
        content: '';
        height: 1px;
        position: absolute;
        width: 50px;
      }
    }
    td {
      font-size: rem-calc(14);
      padding: rem-calc(7) rem-calc(0) rem-calc(10);
      position: relative;
      color: rgba($black, 0.6);

      p {
        font-size: inherit;
      }
    }
  }

  th {
    font-weight: normal;
    padding: rem-calc(10) rem-calc(20);
    text-align: left;

    [dir='rtl'] & {
      text-align: right;
    }
  }

  &.inline {
    min-width: 0;
  }
  &.wide {
    background: $white;

    @if $editor == 'true' {
      editmenuname: Utfallande tabell;
    }
  }
}

@include TableWrapper($scrollbarColor: $lipstick);
