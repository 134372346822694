@import './unit-section.scss';
@import './managements.scss';

@mixin Unit() {
  .unit {
    padding-top: 30px;
    &__agreement-list {
      display: inline-block;
      margin: 0;
      padding: 0;

      li {
        display: inline;
        list-style: none;

        @media print {
          font-size: 8px;
        }

        &:after {
          content: ', ';
        }

        &:last-child:after {
          content: '';
        }
      }
    }
    @content;
  }
}
