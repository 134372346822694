@mixin iconbase($pseudo) {
  &:#{$pseudo} {
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;

    @if $pseudo == before {
      margin-right: 0.2em;
    }

    @if $pseudo == after {
      margin-left: 0.2em;
    }

    text-align: center;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */

    [dir='rtl'] & {
      margin-left: 0.2em;
      margin-right: 0;
    }

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
}

@function ununicodeIcon($character) {
  @return unquote('"') + unquote(str-insert($character, '\\', 1)) + unquote('"');
}
@mixin icon(
  $content,
  $rtl-support: true,
  $include-base: true,
  $pseudo: before
) {
  @if ($include-base == true) {
    @include iconbase($pseudo);
  }

  @supports (content: 'x' / 'y') {
    &:#{$pseudo} {
      content: ununicodeIcon($content) / '';

      @if ($content == '\e808'or $content == '\e809'or $content == '\e80e') {
        [dir='rtl'] & {
          transform: scaleX(-1);
          filter: FlipH;
        }
      }

      @if $rtl-support == false {
        [dir='rtl'] & {
          transform: scaleX(1);
          filter: none;
        }
      }
    }
  }

  @supports not (content: 'x' / 'y') {
    &:#{$pseudo} {
      content: ununicodeIcon($content);

      @if ($content == '\e808'or $content == '\e809'or $content == '\e80e') {
        [dir='rtl'] & {
          transform: scaleX(-1);
          filter: FlipH;
        }
      }

      @if $rtl-support == false {
        [dir='rtl'] & {
          transform: scaleX(1);
          filter: none;
        }
      }
    }
  }
}
