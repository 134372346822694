.c-searchlist__heading__container {
  border-bottom: solid 1px $warm-grey;
  border-top: solid 1px $warm-grey;
  @include breakpoint(large) {
    margin-bottom: 40px;
  }
  @include breakpoint(medium) {
    margin-bottom: 30px;
  }
  margin-bottom: 20px;

  .c-searchlist__heading {
    margin-left: 0px;
    @include breakpoint(large) {
      margin-left: 20px;
    }
  }
}
