@import './core-functions.scss';

$white: #fff;
$black: #353535;
$berry: #a00b36;
$lipstick: #c12143;
$faded-pink: #faeef0;
$light-pink: #faeef0;
$denim: #396291;
$muted: #636466;
$warm-grey: #808285;
$almost-white: #f1f2f2;
$sky-base: #396291;
$pale-grey: #f1f1f5;
$cloudy-blue: #c6d2df;
$wine-red: #6a0032;
$off-white: #fff8e0;
$dusk: #3b4266;
$bright-red: #e7000e;
$very-light-pink: #dadbdc;
$dark-grey: #484848;
$slate-grey: #636466;
$sky-dark: #3b4266;
$dark-pink: #f9e1e3;
$neutral40: #727373;

// Named
// ctaColor is used in platform for e-services tooltip mdoal.
$ctaColor: $denim;

$colors: ('black': #353535,
  'white': #ffffff,
  'grey-100': #f1f2f2,
  'grey-200': #9f9f9f,
  'grey-300': #808285,
  'grey-400': #696969,
  'grey-500': #636466,
  'grey-600': #484848,
  'grey-700': #353535,
  'grey-800': #1e1e1e,
  'grey-900': #000000,
  // $slate-grey $muted
  'muted': #636466,
  // $liptstick
  'main': #c12143,
  // $wine-red
  'secondary-dark': #6a0032,
  // $light-pink & $faded-pink
  'secondary-light': #faeef0,
  // $denim (sky-base)
  'cta': #396291,
  // $dusk (sky-dark)
  'cta-dark': #3b4266,
  // * not-used
  'cta-light': tint-color(#396291, 1),
  // $berry
  'nav': #a00b36,
  // * not-used
  'nav-dark': shade-color(#a00b36, 1),
  // * not-used
  'nav-light': tint-color(#a00b36, 1),
  // $denim, accent-40
  'sky-base': #396291,
  // $sky-dark, $dusk, accent-30
  'sky-dark': #3b4266,
  // * not-used
  'sky-clear': #0097e3,
  // $cloudy-blue
  'sky-line': #c6d2df,
  // $pale-grey
  'sky-background': #f1f1f5,
  // * not-used
  'grass-base': #419002,
  // * not-used
  'grass-dark': #395428,
  // $grass
  'grass-clear': #6fbb2b,
  // * not-used
  'grass-line': #cfdebf,
  // * not-used
  'grass-background': #eef8ee,
  // * not-used
  'plum-base': #a9428b,
  // * not-used
  'plum-dark': #59244c,
  // * not-used
  'plum-clear': #f4739f,
  // * not-used
  'plum-line': #f5c9d8,
  // * not-used
  'plum-background': #f6eef6,
  // * not-used
  'sun-base': #fa8100,
  // * not-used
  'sun-dark': #75260c,
  // * not-used
  'sun-clear': #ffc100,
  // * not-used
  'sun-line': #ffe699,
  // $off-white
  'sun-background': #fff8e0,
  // $slate-grey
  'stone-base': #636466,
  // $black
  'stone-dark': #353535,
  // $warm-grey, neutral-50
  'stone-clear': #808285,
  // $very-light-pink, neutral-90
  'stone-line': #dadbdc,
  // $almost-white
  'stone-background': #f1f2f2,
);
