@import '../../platformui/Video/styles';

@include Video(
  $captionMargin: 11px 0 36px,
  $captionUnderlineColor: $berry,
  $captionUnderlineHeight: 1px,
  $h2Color: $lipstick
) {
  margin-bottom: 40px;
  margin-top: 30px;

  .toggle-chapter & {
    margin-top: unset;
  }

  @include breakpoint(large) {
    margin-top: unset;
  }

  &__caption {
    color: $muted;
    font-size: rem-calc(14);
    line-height: 1.43;
  }
}
