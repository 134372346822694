@mixin UnitQualityIndicators(
  $className: 'listing-information',
  $itemBgColor: $pale-grey,
  $linkColor: $denim
) {
  .#{$className} {
    max-width: rem-calc(620px);
    padding-top: rem-calc(30px);

    &__healthselection-link{
      margin-bottom: 20px;
      display: block;
    }

    &__link__container {
      border-radius: rem-calc(10px);
      margin-bottom: rem-calc(30px);
      max-width: rem-calc(430px);
      background-color: $itemBgColor;
      padding: rem-calc(10px);
      min-height: rem-calc(48px);
    }

    &__link {
      color: $linkColor;
      font-size: rem-calc(18);
      position: relative;
      text-decoration: none;
      padding-left: rem-calc(25px);

      @include icon('f105');
      @include focus-handler();

      &:before {
        position: absolute;
        font-size: rem-calc(19);
        left: rem-calc(2px);
        top: rem-calc(4px);

        @media print {
          content: none;
        }
      }
    }
    @content;
  }
}
