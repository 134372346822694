@import '../../../platformui/Contact-card/contact-card-panel/styles.scss';
@include ContactCardPanel(
  $contentBorderTop: $lipstick,
  $borderColor: $warm-grey,
  $breakpointColor: $muted,
  $focusColor: $muted,
  $headingColor: $denim,
  $headingBreakpointColor: $denim
) {
  &__button-container {
    .contact-card--open & {
      @include breakpoint(large) {
        margin-top: 15px;
      }
    }

    .c-expandable-button {
      .contact-card__heading {
        color: $denim;
      }
    }
  }
}

@include Chevron(
  $className: chevron--contact-panel,
  $chevronColor: $denim,
  $lineWidth: 3px,
  $lineLength: 14px,
  $borderRadius: 0 0 20px 20px,
  $leftLinePosition: calc(100% - 29px),
  $top: 18px,
  $height: 50px
) {
  left: 14px;
  @media print {
    opacity: 0;
  }
}
