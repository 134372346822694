@mixin Facets($itemBorderColor: black, $itemBgColor: white, $itemColor: black) {
  .c-facets {
    margin-bottom: 40px;
    padding-bottom: 0;
    text-align: center;

    &__inner {
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
      max-width: rem-calc(746);
    }

    &__items {
      font-size: 0;
      margin-bottom: rem-calc(15);

      @include breakpoint(medium) {
        margin-bottom: rem-calc(30);

        &:last-child {
          margin-bottom: rem-calc(25);
        }
      }
    }

    &__item {
      border-radius: 1.875rem;
      border: 1px solid $itemBorderColor;
      background-color: $itemBgColor;
      color: $itemColor;
      cursor: pointer;
      display: inline-block;
      font-size: 0.875rem;
      padding: 10px 40px;
      margin: 0 10px 10px 0;
      text-decoration: none;
      text-transform: uppercase;
      width: 100%;

      @include breakpoint(medium) {
        margin-right: 10px;
        width: auto;
      }

      @include breakpoint(large) {
      }

      &--selected,
      &:hover,
      &:focus {
        color: $white;
        text-decoration: none;
        outline: none;
      }

      &--selected {
        color: $white;
      }

      &__checkbox {
        position: absolute;
        left: -9999px;
      }

      &__label {
        display: block;
      }
    }
    @content;
  }
}
