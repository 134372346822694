@import '../../platformui/Spinner/styles';

@include spinner($bgColor: $white, $margin: 0) {
  &--search {
    span {
      background-color: $denim;
    }
  }

  &--map {
    top: 11px;
  }

  .findcare-map__map__popup__bubble & {
    left: 8px;
    opacity: 0;
    position: absolute;
    top: -28px;
    transition: opacity 0.2s ease;
    pointer-events: none;
  }

  .findcare-region-search-field & {
    display: none;
    left: auto;
    opacity: 0;
    right: -1000px;
  }

  .findcare-map__map__popup__bubble--is-loading &,
  .findcare-search__field__query--is-loading &,
  .findcare-region-search-field--is-loading & {
    display: block;
    opacity: 1;

    span {
      background-color: $denim;
    }
  }

  .findcare-search__field__query--is-loading &,
  .findcare-region-search-field--is-loading & {
    top: 19px;
    left: auto;
    right: 0px;
    transform: none;
    width: 50px;
    span {
      height: 10px;
      width: 10px;
    }
  }
}
