@import '../../platformui/SubjectIntro/styles';
@import '../../platformui/Buttons/LinkButton/link-button.scss';

$heightSubjectIntro: 100%;

@include SubjectIntro($backgroundColor: $pale-grey,
  $preambleHoverColor: $dusk,
  $subjectColor: $lipstick,
  $subjectTextColor: $wine-red,
  $linkListColor: $denim,
  $linkListHoverColor: $dusk,
  $backgroundImage: url('../../framework/images/rectangle.svg'),
  $imageMaxWith: 960px,
  $height: $heightSubjectIntro) {
  .subject-intro {
    height: $heightSubjectIntro;
    top: 0;
    margin-top: 1px;

    &:after {
      display: none;
    }

    .row.expanded {
      @include breakpoint(small only, medium only) {
        width: 100%;
      }
    }

    .container-flex {
      display: flex;
      align-items: stretch;
      flex-wrap: nowrap;

      @include breakpoint(small only, medium only) {
        flex-wrap: wrap;
        width: 100%;
      }
    }

    &__image-container {
      position: relative;

      &__wrapper {
        width: 100%;
      }

      &--overlay {
        @include breakpoint(large) {
          position: absolute;
          top: 0;
          right: 0;
          width: 100px;
          height: 100.1%;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MTAiIGhlaWdodD0iNDk1IiB2aWV3Qm94PSIwIDAgNzEwIDQ5NSI+CiAgICA8cGF0aCBmaWxsPSIjRjFGMUY1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uNTc1IDBoNzA5LjQ1djQ5NUgwYzIyLjgyOS03OS4xMSAzNS4wMjUtMTYyLjQ0MiAzNS4wMjUtMjQ4LjVDMzUuMDI1IDE2MS4xNjcgMjMuMDMzIDc4LjUxNC41NzUgMHoiLz4KPC9zdmc+Cg==');
          background-repeat: no-repeat;
          background-size: cover;
          pointer-events: none;
          z-index: 2;
        }
      }

      @include breakpoint(medium) {
        height: auto;
      }

      @include breakpoint(large) {
        float: none;
        height: $heightSubjectIntro;
        margin-left: auto;

        &:after {
          height: $heightSubjectIntro;
        }
      }

      >div {
        .c-image {
          max-height: 495px;

          @include breakpoint(large) {
            max-height: 100%;
          }

          img {
            @include breakpoint(large) {
              display: none;
            }
          }
        }
      }

      &__wrapper {
        height: auto;

        @include breakpoint(large) {
          flex: 1;
        }
      }
    }

    &__text {
      height: $heightSubjectIntro;
      padding-bottom: 0px;
      position: relative;
      top: -35px;
      width: 100%;

      &:after {
        display: none;
      }

      @include breakpoint(large) {
        background-color: $pale-grey;
        height: $heightSubjectIntro;
        padding-bottom: 0;
        top: 0;
        flex: 1;
      }

      &__content {
        margin: 0 auto;
        padding: 2rem 1.25rem;
        z-index: 1;
        max-width: 720px;

        @include breakpoint(medium) {
          margin-block: 1.25rem;
        }

        @include breakpoint(large) {
          margin-block: 3rem;
          margin-left: 1.875rem;
          padding: 0;
          position: relative;
          max-width: 500px;
        }

        .subject-intro-linklist {
          margin-top: 2rem;

          @include breakpoint(large) {
            margin-top: 1.5rem;
          }
        }
      }
    }

    &__subject-heading {
      margin: 1.5625rem 0 0 0;

      @include breakpoint(large) {
        margin: 0;
      }
    }

    &__preamble {
      margin-top: 0.625rem;

      @include breakpoint(large) {
        margin-top: 1rem;
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    &__button-container {
      margin-top: 2rem;
      display: flex;
      flex-flow: row wrap;
      gap: 0.625rem;

      @include breakpoint(large) {
        margin-top: 1.5rem;
      }

      &__button-cta-primary-button {

        @include LinkButton($bgColor: c('cta'),
          $hoverBgColor: c('cta-dark'),
          $border: 1px solid c('cta'),
          $color: $white,
          $hoverColor: $white,
          $fontSize: rem-calc(14),
          $padding: rem-calc(12) rem-calc(18)) {
          display: flex;
          flex-flow: row wrap;
          text-transform: uppercase;
          width: fit-content;
          @include secondary-font-family();
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          line-height: normal;
        }
      }

      &__button-cta-secondary-button {

        @include LinkButton($bgColor: $white,
          $hoverBgColor: c('cta-dark'),
          $border: 1px solid c('cta'),
          $color: c('cta'),
          $hoverColor: $white,
          $fontSize: rem-calc(14),
          $padding: rem-calc(12) rem-calc(18)) {
          text-transform: uppercase;
          width: fit-content;
          display: flex;
          flex-flow: row wrap;
          @include secondary-font-family();

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            line-height: normal;
          }
        }
      }
    }
  }
}
